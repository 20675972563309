import React from "react";
import "./RadioButton.scss";

function RadioButton ({label, id, value, onChange, checked}) {

    return (
        <label className={"radio-button"} htmlFor={id}>
            <input type="radio" id={id} value={value} checked={checked} onChange={onChange} onKeyPress={(e)=>{if(e.code === "Enter") {onChange()}}} />
            {label}
        </label>
    )
}

export default RadioButton;