import React, {Suspense} from "react";
import { Instances, useGLTF} from "@react-three/drei";
import * as THREE from 'three';
import NodeIcon from "../NodeIcon";
import useMapStore from "../../data/map_store";
import ConnectingLine from "../ConnectingLine";

function MapInstances() {

    const mutation = useMapStore((state) => state.mutation);

    //const comps_l_model = useGLTF('/models/table003-transformed.glb');
    const table_models = useGLTF('/models/tables.glb');
    const director_models = useGLTF('/models/directors.glb');

    //console.log("map instances rerender director_models", director_models);
    //console.log("map instances rerender table_models", table_models);

    const models = {
        comps_s: table_models.nodes.TableSmall.geometry,
        comps_m: table_models.nodes.TableMedium.geometry,
        comps_l: table_models.nodes.TableLarge.geometry,
        dirs_ms: director_models.nodes.DirectorMS.geometry,
        dirs_mm: director_models.nodes.DirectorMM.geometry,
        dirs_ml: director_models.nodes.DirectorML.geometry,
        dirs_mxl: director_models.nodes.DirectorMXL.geometry,
        dirs_fs: director_models.nodes.DirectorFS.geometry,
        dirs_fm: director_models.nodes.DirectorFM.geometry,
        dirs_fl: director_models.nodes.DirectorFL.geometry,
        dirs_fxl: director_models.nodes.DirectorFXL.geometry
    }

    const material = director_models.materials.TR;
    // material.flatShading = true;
    // material.alphaTest = 0.5;

    return (
            <>

                <Instances
                    range={mutation.links.length}
                    material={new THREE.MeshBasicMaterial({color:"#EFEFEF"})}
                    geometry={new THREE.PlaneGeometry(1, 1)}
                    frustumCulled={false}
                >
                {Object.entries(mutation.links).map(([key, link], i) => {
                    return (
                        <group key={key}>
                            <ConnectingLine source={link.source} target={link.target} i={i} />
                        </group>
                    )
                    }
                )}
                </Instances>


                {Object.entries(models).map(([instance_key, instance]) => {
                    return (
                        <group key={instance_key}>
                            <Suspense fallback={null}>
                                <Instances
                                    range={mutation[instance_key].length}
                                    material={material}
                                    geometry={models[instance_key]}
                                    // geometry={director_models.nodes.DirectorFS.geometry}
                                    // geometry={comps_l_model.nodes.Table.geometry}
                                >
                                    {Object.entries(mutation[instance_key]).map(([key, node]) => {
                                        return (
                                            <NodeIcon
                                                key={key}
                                                node={node}
                                                instance_key={instance_key}
                                            />
                                        )
                                    })}
                                </Instances>
                            </Suspense>
                        </group>
                    )}
                )}
            </>
    );
}

export default MapInstances;

useGLTF.preload('/models/tables.glb')
useGLTF.preload('/models/directors.glb')
// useGLTF.preload('/models/table003-transformed.glb')