import React, {useRef, useState} from "react";
import "./SearchBar.scss";
import Icon from "../Icons";
import {isMobile} from 'react-device-detect';
import SearchSuggestions from "./SearchSuggestions";
import {strings} from "../../data/strings";
import {Icons} from "../Icons/Icon";
import useViewStore from "../../data/view_store";
import useOutsideAlerter from "../../hooks/useOutsideAlerter";


function SearchBar ({onClose, dataLoaded}) {

    const placeholderText = strings.searchPlaceholder;
    const [isFocused, setIsFocused] = useState(false);
    const onLaunchSearch = useViewStore((state) => state.actions.onLaunchSearch);
    const [query, setQuery] = React.useState("");

    //console.log("searchbar rerender");

    const searchInputRef = useRef(null);
    const focusSearch = () => {
        searchInputRef.current.focus();
    }

    const onSearchFocus = (e) => {
        //console.log("e: ", e);
        setIsFocused(true);
    }

    const handleChange = e => {
        setQuery(e.target.value);
        //console.log("search handle change: " , e);
    };

    const handleKeyUp = e => {
        if (e.key === 'Enter') {
            //console.log('Enter hit, open main screen with search results');
            onLaunchSearch(e.target.value);
        }
    };

    const handleMobileSearch = e => {
            onLaunchSearch("");
    };

    const wrapperRef = useRef(null);
    useOutsideAlerter(wrapperRef, ()=>{
        //console.log("handler");
        setIsFocused(false); });

    if(isMobile) {
        return (
            <div className={"searchBar"  + (dataLoaded? "" : " hidden")} onClick={handleMobileSearch}>
                <Icon icon={Icons.SEARCH} />{placeholderText}
            </div>
        );
    }
    // Desktop
    // Note: when toggling device type in Chrome you need to refresh to trigger the user agent string parsing
    return (<>
            {!!dataLoaded && <div className={"searchBar desktop" + (isFocused? " focused" : "") } ref={wrapperRef} >
            <Icon icon={Icons.SEARCH}/>
            <input type={"text"}
                   className={"searchInput"}
                   placeholder={placeholderText}
                   onFocus={onSearchFocus}
                   value={query}
                   onChange={handleChange}
                   onKeyUp={handleKeyUp}
                   ref={searchInputRef}
            />
            {isFocused && <div className={"divider"} />}
            <div className={"suggested" + (isFocused? " focused" : "") }>
                <SearchSuggestions query={query} showResults={true} onClose={onClose} focusSearch={focusSearch} />
            </div>
        </div>}
        </>
    );
};

export default SearchBar;