import React, {useEffect, useRef, useState} from "react";
import {a, config, useSpring} from "@react-spring/web";
import {useDrag} from "@use-gesture/react";
import "./FlyoutPanel.scss";
import Icon from "../../Icons";
import {Icons} from "../../Icons/Icon";
import {strings} from "../../../data/strings";
import {Side} from "../../../data/constants";
import FocusTrap from "focus-trap-react";

function FlyoutPanel ({   onRestOfPanelClose,
                          onRestOfPanelOpen,
                          isOpen,
                            onClose,
                            width="40em",
                            show_close_arrow = true,
                            open_with_no_animation = false,
                            focusCloseButtonOnOpen = true,
                            openFrom  = Side.RIGHT,
                          useFocusTrap = false,
                          ...props}) {

    const contentPanelRef = useRef();
    //const pixelWidth = isMobile ? window.innerWidth : window.innerWidth * .8;

    //console.log("contentPanelRef.current ", contentPanelRef.current);

    const pixelWidth = !!contentPanelRef.current? contentPanelRef.current.clientWidth : 100;
    const [{x}, api] = useSpring(() => ({x: openFrom===Side.RIGHT? pixelWidth:-pixelWidth}));
    const [localIsVisible, setLocalIsVisible] = useState(false);
    const [isClosing, setIsClosing] = useState(false);
    const [focusCloseButton, setFocusCloseButton] = useState(false);
    const [alreadyClosed, setAlreadyClosed] = useState(true);

    // Adapted code from: https://codesandbox.io/s/github/pmndrs/use-gesture/tree/main/demo/src/sandboxes/action-sheet?file=/src/styles.module.css to handle the animated opening and swipe to close functionality

    const open = ({canceled, immediately}) => {
        // when cancel is true, it means that the user passed the upwards threshold
        // so we change the spring config to create a nice wobbly effect
        api.start({
            x: 0,
            immediate: !!immediately,
            onStart: () => {
                setAlreadyClosed(false);
                setLocalIsVisible(true);
                if(focusCloseButtonOnOpen){
                    setFocusCloseButton(false);
                };
            },
            onRest: () => {
                if(onRestOfPanelOpen !== undefined && onRestOfPanelOpen !== null) {onRestOfPanelOpen()};
                if(focusCloseButtonOnOpen){
                    setFocusCloseButton(true);
                };
            },
            config: canceled ? config.wobbly :  config.stiff
        });
        setIsClosing(false);
    }

    const close = (velocity = 0) => {
        //console.trace("close this panel opens from: ", openFrom);
        api.start({
            x: openFrom===Side.RIGHT? pixelWidth:-pixelWidth,
            immediate: false,
            onRest: () => {
                if(onRestOfPanelClose !== undefined && onRestOfPanelOpen !== null) {
                    setAlreadyClosed(true);
                    onRestOfPanelClose()};
                setLocalIsVisible(false);
            },
            config: {...config.stiff, velocity}
        });
        setIsClosing(true);
    }

    /**
     IS PANEL OPENED OR CLOSED
     Listen for changes in isOpen
     */
    useEffect(() => {
        if(isOpen){
            open({canceled:false, immediately:open_with_no_animation});
            setAlreadyClosed(false);
        } else if (!alreadyClosed) {
            close(.8);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isOpen, open, close]);

    const bind = useDrag(
        (e) => {
            // if the user drags up passed a threshold, then we cancel
            // the drag so that the sheet resets to its open position
            if (openFrom === Side.RIGHT? e.movement[0] < -70: e.movement[0] > 70) {
                e.cancel()
            }

            // when the user releases the sheet, we check whether it passed
            // the threshold for it to close, or if we reset it to its open positino
            if (e.last) {
                let canceled = e.canceled;
                if ((
                        (e.distance[0] * 3) > pixelWidth * 0.3 || (e.velocity[0] > 0.3)
                    )
                    && (openFrom === Side.RIGHT? e.direction[0] > 0: e.direction[0] < 0)
                ) {
                    close(e.velocity[0] > 0.5 ? e.velocity[0] : 0.65)
                    onClose();
                } else {
                    open({canceled})
                }
                ;
            }
                // when the user keeps dragging, we just move the sheet according to
            // the cursor position
            else {
                if(openFrom === Side.RIGHT) {
                    api.start({x: e.movement[0] > 0 ? e.movement[0] * 3 : e.movement[0], immediate: true})
                } else {
                    api.start({x: e.movement[0] < 0 ? e.movement[0] * 3 : e.movement[0], immediate: true})
                }
            }
        },
        {from: () => [x.get(), 0], filterTaps: true, bounds: openFrom === Side.RIGHT?{right: 0}:{left:0}, rubberband: true}
    )

    const styles = x.to((posX) => (posX < pixelWidth ? 'block' : 'none'));


    return (
        <>

            {!!localIsVisible &&
            <FocusTrap active={isOpen && useFocusTrap}>
            <div className="flyout-panel"  aria-modal={"true"}>

                {isOpen && <div className={"close-menu-overlay"} onClick={() => {
                    //console.log("close panel");
                    onClose()
                }} tabIndex={0} role={"button"} aria-label={"Close Panel"}/>}

                <a.div className={['panel-content', openFrom===Side.LEFT?"left":"right", isClosing?"closing":""].join(" ")}
                       ref={contentPanelRef}
                       {...bind()}
                       style={{styles, x, "width":width}}
                >
                    {!!show_close_arrow && <Icon icon={Icons.BACK_RIGHT} onClick={() => onClose()} interactive={true}
                              label={strings.closeInfoPanel} />}


                    {props.children}
                </a.div>
            </div>
            </FocusTrap>
            }
        </>
    );
};

export default FlyoutPanel;