import React from "react";
import {Entities} from "../../data/constants";
import InfoPanelPerson from "../../components/InfoPanel/InfoPanelPerson";
import "../../components/InfoPanel/InfoPanel.scss";
import Chair from "../../components/Chair";
import InfoPanelOrg from "../../components/InfoPanel/InfoPanelOrg";
function InfoPage({wikidataID, entityType}) {
    return (
        <>
            <div className={"info_panel_wrap"} >
                <div className={"info_panel full_info_page"} >
                    <div className={"info_panel_body full_info_page"} >
                        <div className={"info-page-header"}>
                            <a href={"../"}>
                            <div className={"info-page-logo"}>
                                <Chair />
                                <div>They Rule</div>
                            </div>
                            </a>
                        </div>
            {entityType === Entities.DIR && <InfoPanelPerson wikidataID={wikidataID} isStandAlonePage={true}/> }
            {entityType === Entities.ORG && <InfoPanelOrg wikidataID={wikidataID} isStandAlonePage={true}/> }
        </div></div></div>

        </>
    )

}


export default InfoPage;