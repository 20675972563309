/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import React, { useRef } from 'react'
import { useGLTF } from '@react-three/drei'

export default function ChairModel(props) {
    const group = useRef()
    const { nodes, materials } = useGLTF('/models/chair003.glb')
    return (
        <group ref={group} {...props} dispose={null}>
            <mesh geometry={nodes.Cube.geometry} material={materials.GreySeat} />
            <mesh
                geometry={nodes.Cube_1.geometry}
                material={nodes.Cube_1.material}
            />
            <group position={[0, -0.04, 0]}>
                <mesh
                    geometry={nodes.Cube001.geometry}
                    material={nodes.Cube001.material}
                />
                <mesh
                    geometry={nodes.Cube001_1.geometry}
                    material={materials.Black}
                />
            </group>
        </group>
    )
}

useGLTF.preload('/models/chair003.glb')