import React from "react";
import "./Toast.scss";
import {isMobile} from "react-device-detect";
import {animated, config, Transition} from "@react-spring/web";
import UseInterval from "../../../hooks/useIntervalHook";
import {Icons} from "../../Icons/Icon";
import Icon from "../../Icons";



function Toast ({title, body, onClose, show, duration = null}) {

    //console.log("Toast", title, body, onClose, " show: " , show, " dur: ", duration );

    /**
     The is the autoCloseTimer
     Pass a duration in MS to kick it off
     */
    UseInterval(() => {
        //console.log("Toast auto-close, ", onClose);
        onClose();
    }, duration);



    return (
        <Transition
            items={show}
            from={{y:"50px"}}
            enter={{y:"0px"}}
            leave={{y:"50px"}}
            delay={20}
            config={config.default}
        >
            {(styles, item) =>
                item && <div className={"toast_wrap" + (isMobile ? " mobile" : "")} >
                <animated.div className={"toast"} style={styles}>
                    <div className={"close-toast"}><Icon icon={Icons.CLOSE} onClick={onClose} interactive={true} strokeCol={"#FFF"}/></div>
                    {!!title && <p className={"title"}>{title}</p>}
                    <div className={"toast_body"}>{body}</div>
                </animated.div>
            </div>
            }
        </Transition>

        )

}

export default Toast;