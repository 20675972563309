import React, {useEffect, useRef, useState} from "react";
import "./TopSearch.scss";
import Icon from "../../Icons";
import {isMobile} from 'react-device-detect';
import {strings} from "../../../data/strings";
import {Icons} from "../../Icons/Icon";
import useViewStore from "../../../data/view_store";
import {ViewModes} from "../../../data/constants";
import useMapStore from "../../../data/map_store";

function TopSearch ({show, setSearchInputEl, onDesktopSearchIconClicked}) {

    const placeholderText = strings.searchPlaceholder;
    const [isFocused, setIsFocused] = useState(false);
    const onLaunchSearch = useViewStore((state) => state.actions.onLaunchSearch);
    const searchInput = useRef(null);
    const {setViewMode, setQuery, setTimeSearchLastClosed } = useViewStore((state) => state.actions);
    const {stopAutoPlay} = useMapStore((state) => state.actions);

    const onCloseSearch = () => {
        setTimeSearchLastClosed(Date.now());
        setQuery("");
        setViewMode(ViewModes.MAP);
    }

    useEffect(()=>{
        if(isMobile) {
            searchInput.current.focus();
        }
        setSearchInputEl(searchInput.current);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[])

    useEffect(()=>{
        if(show) {
            searchInput.current.focus();
        }
        setSearchInputEl(searchInput.current);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[show])

    const onSearchFocus = (e) => {
        //console.log("onSearchFocus e: ", e);
        if(useViewStore.getState().view_mode !== ViewModes.SEARCH && useViewStore.getState().view_mode !== ViewModes.ACTIVE_SEARCH ) {
            setViewMode(ViewModes.SEARCH);
        }
        setIsFocused(true);
    }

    const onSearchBlur = (e) => {
        //console.log("blur e: ", e);
        setIsFocused(false);
    }

    const onClearSearch = () => {
        onLaunchSearch(); // resets the search
        searchInput.current.focus();
    }

    const handleChange = e => {
        setQuery(e.target.value);
        //console.log("search handle change: " , e);
    };

    const onOpenMobileSearch = () => {
        //console.log("onOpenMobileSearch");
        onLaunchSearch();
        stopAutoPlay();
    }

    const onSearchBarClicked = () => {
        //console.log("onSearchBarClicked");
        onLaunchSearch();
        stopAutoPlay();
    }

    return (
        <section className={"topSearch"} aria-label={strings.searchSection}>

            { isMobile && !show && <div className={"mobileSearchIcon"}><Icon icon={Icons.SEARCH} onClick={onOpenMobileSearch}/></div> }

            <div className={`topSearchBar ${isMobile?"mobile" : "desktop"} ${isFocused ? "focused" : ""} ${show ? "" : "hidden"}`}>
                { !isMobile && <Icon icon={Icons.SEARCH} onClick={onDesktopSearchIconClicked}/> }
                { isMobile && <Icon icon={Icons.BACK_RIGHT} onClick={onCloseSearch} /> }
                <div className={"input-wrap"}>
                    <input type={"text"}
                           className={"searchInput"}
                           placeholder={ isMobile?"":placeholderText}
                           onFocus={onSearchFocus}
                           onBlur={onSearchBlur}
                           value={useViewStore.getState().query}
                           onClick={onSearchBarClicked}
                           onChange={handleChange}
                           ref={searchInput}
                           tabIndex={0}
                           onKeyPress = {(e)=>{
                               //console.log("on key press e: ", e);
                               if(e.key === "Enter"){onSearchBarClicked();}}}
                    />
                </div>
                { useViewStore.getState().query !== ""  &&  <Icon icon={Icons.CLOSE}
                                                          onClick={onClearSearch}
                                                          interactive={true}
                                                          label={strings.closeSearch}
                                                           /> }

            </div>
        </section>
    );
};

export default TopSearch;