
import React from "react";
import "./Beta.scss";
import useMapStore from "../../data/map_store";

function Beta({setShowBeta}) {

    return (
        <main className="beta">
            <input type={"password"}
                   placeholder={"They rule?"}
                   onKeyUp={(e)=>{
                       //console.log("#beta e: ", e, (e.target.value) === useMapStore.getState().betaPassword, useMapStore.getState().betaPassword, e.target.value);
                       if((e.target.value) === useMapStore.getState().betaPassword) {
                           //console.log("#beta: pw match");
                           setShowBeta(false);
                       }
                   }} autoFocus={true}/>
        </main>
    );
}

export default Beta;