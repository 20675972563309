import React, {useCallback, useEffect, useState} from "react";
import {OrbitControls} from "@react-three/drei";
import useMapStore from "../data/map_store";
import {useSpring} from "@react-spring/three";
import * as THREE from "three";
import {AutoPlayStates, ControlModes} from "../data/constants";
import UseInterval from "../hooks/useIntervalHook";

export default function CameraOrbitControls () {

    /*
    This function directly manipulates the orbit controls.

    When a new Camera Target is set the subscription on this page sets a Spring for the new target,
    and onChange it directly changes the target on the OrbitControls.

    I copied some of the patterns from: https://codesandbox.io/s/peaceful-johnson-txtws
    I am not sure if it helps performance in this case or not!
     */
    const floorPlane = useMapStore((state) => state.floor_plane);

    const [orbitRef, setOrbitRef] = useState({current: undefined});
    const [savedMapCameraPositionInterval, setSavedMapCameraPositionInterval] = useState(null);

    //console.log("%c ORBIT CONTROLS RERENDER!!!", "color:green", orbitRef);

    /*
     The timer called after the orbitRef is set
     Used to set the camera and orbit controls in the MapStore
     AND if this is a saved map that is loading - set the zoom and rotation in the Mapstore - which are listened to below to update
     */
    UseInterval(() => {

        if(orbitRef && orbitRef.current && orbitRef.current.object){
            // Set the camera and orbit controls on the MapStore Zustand
            useMapStore.getState().actions.setCamera(orbitRef.current.object);
            useMapStore.setState({orbit_controls: orbitRef.current}); // used in the Menu.jsx to save a map
        } else {
            // console.log("(saved) no camera yet trying to set zoom and rotation");
            setSavedMapCameraPositionInterval(savedMapCameraPositionInterval * 2); // slows down the cycle
            return;
        }

        setSavedMapCameraPositionInterval(null); // only call this once after the camera is set

        // If this is loading a saved map then set the zoom and rotation and position
        let savedMapId = useMapStore.getState().loaded_saved_map_id;
        //console.log("savedMapCameraPosition savedMapId: ", savedMapId, " orbitRef: ", orbitRef);
        if(savedMapId !== undefined) {
            useMapStore.getState().actions.setZoom(useMapStore.getState().camera_zoom_target + 0.001); // the zoom had already been set but doesn't take effect until the camera is finally set - so I call it again to trigger the zoom - by slightly changing the value!
            let savedDS = useMapStore.getState().loaded_saved_map_data_set_info[savedMapId];
            useMapStore.setState({saved_map_camera_rotation: savedDS.camera_rotation + (Math.random() * 0.0000001)}); // Put a slight random spin on the rotation - to ensure that it updates even if this was called earlier.
            useMapStore.getState().actions.forceCameraToJumpToTarget(); // set the position too
        }
    }, savedMapCameraPositionInterval);

    // const onOrbControlsChangeEvent = (e) => {
    //     console.log("onOrbControlsChangeEvent: ", e);
    // }
    //
    // const onOrbControlsStartEvent = (e) => {
    //     console.log("onOrbControlsStartEvent: ", e);
    // }

    const onOrbitRefChange = useCallback( (orbRef) =>
    {
        //console.log("orbRef changed (save)", orbRef);
        if (orbRef !== null) {
            setOrbitRef({current: orbRef} );
            setSavedMapCameraPositionInterval(10);
            // orbRef.addEventListener( 'start', onOrbControlsChangeEvent );
            // orbRef.addEventListener( 'change', onOrbControlsChangeEvent );
        }
    }, [setOrbitRef]);

    // position spring
    const [, api] = useSpring(() => ({
        target: useMapStore.getState().camera_target,
        config: {friction: 120},
        onChange: (props) => {
            if(!orbitRef || !orbitRef.current) return;
            orbitRef.current.target.x = props.value.target[0];
            orbitRef.current.target.y = props.value.target[1];
            orbitRef.current.target.z = props.value.target[2];
        }
    }), [orbitRef]);


    // zoom spring
    const [, z_api] = useSpring(() => ({
        target: useMapStore.getState().camera_zoom_target,
        config: {friction: 50},
        onChange: (props) => {
            // console.log("(saved) z_api orbitRef, ", orbitRef,  "useMapStore.getState().camera: ", useMapStore.getState().camera);
            if(!orbitRef || !orbitRef.current) return;
            orbitRef.current.object.zoom = props.value.target;
            orbitRef.current.object.updateProjectionMatrix();
            orbitRef.current.update();
        }
    }), [orbitRef]);


    const nodeSelectedMouseControls = {
        LEFT: THREE.MOUSE.ROTATE,
        MIDDLE: THREE.MOUSE.DOLLY,
        RIGHT: THREE.MOUSE.PAN
    }

    const nodeUnselectedMouseControls = {
        LEFT: THREE.MOUSE.PAN,
        MIDDLE: THREE.MOUSE.DOLLY,
        RIGHT: THREE.MOUSE.ROTATE
    }

    const nodeSelectedTouchControls = {
        ONE: THREE.TOUCH.ROTATE,
        TWO: THREE.TOUCH.DOLLY_PAN
    }

    const nodeUnselectedTouchControls = {
        ONE: THREE.TOUCH.PAN,
        TWO: THREE.TOUCH.DOLLY_ROTATE
    }

    /**
     ON RERENDER
     Set the right controls
     */
    useEffect(()=> {
            if (orbitRef && orbitRef.current) {
                let mode = useMapStore.getState().control_mode;
                orbitRef.current.mouseButtons = mode === ControlModes.ROTATE ? nodeSelectedMouseControls : nodeUnselectedMouseControls;
                orbitRef.current.touches = mode === ControlModes.ROTATE ? nodeSelectedTouchControls : nodeUnselectedTouchControls;
            }
        }
    );

    /**
        CAMERA TARGET CHANGED
        Listen for changes in the camera target
        - the place that the camera should center the view on and rotate around
    */
    useEffect(()=>{
        const raycaster = new THREE.Raycaster();
        const intersects = new THREE.Vector3();
        const unsubCamTar = useMapStore.subscribe(
            (state) => state.camera_target,
            (target) => {
                // We can't just animate from the current target
                // - because that might still be centered on an object off screen and not on the center
                // - this happens because when someone pans the target does not automatically get updated
                // So we find the spot that is currently on the center of the screen and animate the target from there
                raycaster.setFromCamera( new THREE.Vector2(), orbitRef.current.object );
                raycaster.ray.intersectPlane( floorPlane, intersects );

                //console.trace("Cam target changed: ",target );
                //console.log("Cam target changed intersects: ", intersects );

                if(!!target.camera_target ) {
                    //console.log("This target is wrong" );
                    return;
                }

                api.start({
                    to: {target: target},
                    from: {target: [intersects.x,0,intersects.z]}
                })
            }
        )
        return () => {
            // Clean up the subscription
            unsubCamTar();
        };
    }, [api, floorPlane, orbitRef])


    /*
    FORCE CAMERA TO JUMP TO TARGET
    Listen for changes in the forceCameraJumpTrigger
    */
    useEffect(()=>{
        const unsubTrigger = useMapStore.subscribe(
            (state) => (state.forceCameraJumpTrigger),
            () => {
                let tar = useMapStore.getState().camera_target;
                api.start({
                    to: {target: tar},
                    from: {target: tar}
                })
                //console.log("orbit controls force cam to jump to target: ", tar);
                orbitRef.current.target.x = tar[0];
                orbitRef.current.target.y = tar[1];
                orbitRef.current.target.z = tar[2];
                orbitRef.current.object.updateProjectionMatrix();
                orbitRef.current.update();
            }
        )
        return () => {
            // Clean up the subscription
            unsubTrigger();
        };
    }, [api, orbitRef])


    /*
    FORCE CAMERA TO ROTATE TO SAVED ROTATION
    Listen for changes in the saved_map_camera_rotation - used for saved maps
    */
    useEffect(()=>{
        const updateCameraToSavedMapRotation = (rot) => {
            //console.log("save updateCameraToSavedMapRotation ", rot, orbitRef);
            if(rot !== undefined && orbitRef.current && orbitRef.current.object && orbitRef.current.object.type !== "PerspectiveCamera") {
                //console.log("save change the camera rotation!", orbitRef.current.object);
                orbitRef.current.setAzimuthalAngle(rot);
                orbitRef.current.update();
                // Might have to re-instate a flag here if this fires too much
                    // useMapStore.setState({saved_map_camera_rotation: "done"});
            }
        }
        const unsubSaveCamRot = useMapStore.subscribe(
            (state) => (state.saved_map_camera_rotation),
            () => {
                let rot = useMapStore.getState().saved_map_camera_rotation;
                updateCameraToSavedMapRotation(rot);
            }
        )
        updateCameraToSavedMapRotation(useMapStore.getState().saved_map_camera_rotation);
        return () => {
            // Clean up the subscription
            unsubSaveCamRot();
        };
    }, [api, orbitRef])


    const updateAutoRotate = () => {
        //console.log("updateAutoRotate orbitRef.current: " + orbitRef.current);
        if(orbitRef.current !== undefined) {
            orbitRef.current.autoRotate = useMapStore.getState().auto_play_state === AutoPlayStates.PLAYING;
            orbitRef.current.autoRotateSpeed = .3;
        }
    }

    /*
    CAMERA ZOOM TARGET CHANGED
    Listen for changes in the camera zoom target
    */
    useEffect(()=>{
        const unsubCamZoomTar = useMapStore.subscribe(
            (state) => (state.camera_zoom_target),
            (target) => {
                //console.log("(saved map test) CameraOrbitControls Zoom orbitRef: ", orbitRef );
                if(!orbitRef || !orbitRef.current) return;
                z_api.start({
                    to: {target: target},
                    from: {target: orbitRef.current.object.zoom}
                })
            }
        )
        return () => {
            // Clean up the subscription
            unsubCamZoomTar();
        };
    }, [z_api, orbitRef])


    /*
        IS DRAGGING
        Listen to if a node is being dragged and disable the orbit controls for that time.
     */
    useEffect(() => {
        const unsubDrag = useMapStore.subscribe(
            (state) => (state.is_dragging ),
            (dragging)=>{orbitRef.current.enabled = !dragging}
        )
        return () => {
            // Clean up the subscription
            unsubDrag();
        };
    }, [orbitRef])


    /**
     HAS THE CONTROL MODE CHANGED?
     Listen for changes in PAN or ROTATE modes.
     */
    useEffect(() => {
        //console.log("New Orbit controlsRef!!! #mode")
        const unsubSel = useMapStore.subscribe(
            (state) => (state.control_mode),
            (mode)=>{
                //console.log("Orbit controls detects selected node: ", mode, orbitRef);
                orbitRef.current.mouseButtons = mode === ControlModes.ROTATE? nodeSelectedMouseControls : nodeUnselectedMouseControls;
                orbitRef.current.touches = mode === ControlModes.ROTATE? nodeSelectedTouchControls : nodeUnselectedTouchControls;
            }
        )

        if(orbitRef && orbitRef.current){
            //console.log("%c ORBIT CONTROLS LISTEN for PAN and ROTATE!!!", "color:green", orbitRef.current);
        }

        return () => {
            // Clean up the subscription
            unsubSel();
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [orbitRef])




    /**
    IS IT IN AUTO PLAY?
    When in autoplay slowly rotate
 */
    useEffect(() => {
        const autoPlayChange = (isAutoPlaying) => {
            //console.log("%c CameraOrbitControls autoPlayChange isAutoPlaying: ", "color:red", isAutoPlaying, orbitRef.current);
            updateAutoRotate();
        }

        const unsubAuto = useMapStore.subscribe(
            (state) => (state.auto_play_state),
            (autoPlayState)=>{ autoPlayChange(autoPlayState) },
        )

        // call this the first time if it has a target
        let isAutoPlaying = useMapStore.getState().auto_play_state === AutoPlayStates.PLAYING;
        if(isAutoPlaying) {
            autoPlayChange(AutoPlayStates.PLAYING)
        }

        return () => {
            unsubAuto(); // Clean up the subscription
        };

        // ONLY CALL THIS ON MOUNT
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [orbitRef])

    // const onOrbitControlEnd = (e) => {
    //     console.log("OnOrbitControlsEnd", e, orbitRef);
    //
    // }


    return (
    <OrbitControls
        ref={onOrbitRefChange}
        minZoom={1}
        maxZoom={60}
        target={[0,0,0]}
        maxPolarAngle={Math.PI / 3}  // {Math.PI/2}
        minPolarAngle={Math.PI / 3}   // 0
        enabled={true}
        mouseButtons = {undefined} // the controls get set directly above when the selection state is changed in the map store
        touches = {undefined}
        panSpeed = {1.8}
    //onEnd={onOrbitControlEnd} // onChange also works but fires more often
    />
    )

}