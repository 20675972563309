
import "../IntroScreen/IntroScreen.scss";
import Chair from "../Chair";
import useMapStore from "../../data/map_store";
import {useEffect, useLayoutEffect, useState} from "react";
import {strings} from "../../data/strings";
import {DataSetType, Entities} from "../../data/constants";
import UseInterval from "../../hooks/useIntervalHook";
import TheyRuleButton, {ButtonStyles, ButtonTypes} from "../UI/TheyRuleButton";
import useDialogStore from "../../data/dialog_store";

function MapLoading ({id}) {

    // console.log("render map loading: ", id);

    const {setCurrentDataSet, storeLoadedSavedMapDataSet, includeWikidataSet} = useMapStore((state) => state.actions)

    const introText = [
        strings.introText1,
        strings.introText2,
        strings.theyRule
    ];

    const [introTickCount, setIntroTickCount] = useState(1);
    const [introTickInterval, setIntroTickInterval] = useState(20);
    const [introTextIndex, setIntroTextIndex] = useState(0);
    const introTextTickInterval = 100;
    const maxIndex = introText.length;
    const openMapButtonDuration = introTextTickInterval * maxIndex;

    const {clearDialog, showDialog, setTitle, setBody, setPrimary, setPrimaryAction} = useDialogStore((state) => state.actions);

    const [isLoaded, setIsLoaded] = useState(false);


    // The timer controlling the introText
    UseInterval(() => {
        //console.log("UseInterval introTextIndex: ", introTextIndex);
        let c = introTickCount + 1;
        setIntroTickCount(c);

        if(c%introTextTickInterval === 0) {
            setIntroTextIndex(Math.min(introTextIndex + 1, maxIndex));
        }

        if(introTextIndex === maxIndex){
            setIntroTickInterval(null);
            openLoadedMap();
        };

    }, introTickInterval);


    const onMapStoreDataAddOnsUpdate = () => {
        // check to see if all the data has been updated
        let addOnsToLoad = useMapStore.getState().loaded_saved_map_data_set_info[id].dataAddOns;
        let addOnShouldLoad, addOnHasLoaded;
        let addOnsLoaded = true;
        Object.entries(addOnsToLoad).forEach(([additionalDataSet, additionalDataSetState]) => {

            addOnShouldLoad = additionalDataSetState["loaded"];
            if(addOnShouldLoad) {
                addOnHasLoaded = useMapStore.getState().dataAddOns[additionalDataSet] && useMapStore.getState().dataAddOns[additionalDataSet]["loaded"];
                if(!addOnHasLoaded) {
                    addOnsLoaded = false && addOnsLoaded;
                }
            }
            //console.log("onMapStoreDataAddOnsUpdate ", additionalDataSet, "addOnShouldLoad: ", addOnShouldLoad, " addOnHasLoaded: ", addOnHasLoaded);
        })

        //console.log("onMapStoreDataAddOnsUpdate addOnsLoaded: ", addOnsLoaded);

        if(addOnsLoaded){
            drawLoadedMap();
        }

    }

    // Listen for dataAddOn changes from the mapStore
    useLayoutEffect(()=>{
        const unsub_dataAddOns = useMapStore.subscribe( (state) => (state.dataAddOns), (s) => {onMapStoreDataAddOnsUpdate();})
        return () => {
            // Clean up the subscription
            unsub_dataAddOns();
        };
    });


    const onDataLoaded = () => {

        /// The correct data set for this map has been loaded. Now load add-on data sets and display the map
        // console.log("JJ onDataLoaded now actually set up the map! id: ", id)
        // console.log("JJ onDataLoaded now actually set up the map! useMapStore.getState().loaded_saved_map_data_set_info: ", useMapStore.getState().loaded_saved_map_data_set_info);
        // console.log("JJ onDataLoaded now actually set up the map!", useMapStore.getState().loaded_saved_map_data_set_info[id])

        // USE: http://localhost:3000/map/rddcod - because it has presidential info loaded
        // Make sure that MAMP is running!

        // load any add ons data that has been used
        let addOns = useMapStore.getState().loaded_saved_map_data_set_info[id].dataAddOns;

        let hasAddOnsToLoad = false;

        Object.entries(addOns).forEach(([additionalDataSet, additionalDataSetState]) => {
            //console.log(additionalDataSet, " is loaded: ", additionalDataSetState["loaded"]);
            if(additionalDataSetState["loaded"] === true) {
                // TODO: load this data set;
                let addOnType = DataSetType.ADD_ON;
                if (additionalDataSet === "presidential_admins") {
                    addOnType = DataSetType.US_ADMINS;
                }
                includeWikidataSet(addOnType, true, additionalDataSet);
                hasAddOnsToLoad = true;
            }
        });

        if(!hasAddOnsToLoad) {
            drawLoadedMap();
        }

    }

    /*
        LISTEN FOR DATA LOADED
        Listen for changes in data loaded status
    */
    useEffect(()=>{
        const unsub = useMapStore.subscribe(
            (state) => (state.data_loaded),
            onDataLoaded
        )
        //console.log("JJ Map Loading dataLoaded", useMapStore.getState().data_loaded);
        if(useMapStore.getState().data_loaded){
            onDataLoaded();
        }
        return () => {
            unsub();
        };
        // ONLY CALL THIS ON MOUNT
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])


    // Once the saved map info is loaded it says which datasets are being used - so load those...
    const loadSavedMapDataSets = () => {
        //console.log("fetch loadSavedMapDataSets, ", id, useMapStore.getState().loaded_saved_map_data_set_info[id]);
        setCurrentDataSet(useMapStore.getState().loaded_saved_map_data_set_info[id].dataSetInfo);
    }

    const onRestart = () => {
        //console.log("onRestart");
        window.location.href = "/";
    }

    const onMapLoadError = () => {
        clearDialog();
        setTitle(strings.mapLoadError);
        setBody(strings.mapLoadErrorDetails);
        setPrimary(strings.ok);
        setPrimaryAction(onRestart);
        showDialog(true);
    }

    useEffect(()=>{
    // INIT THE LOADING OF DATA - IT ALL STARTS HERE
    if (!useMapStore.getState().data_loaded){
        //console.log("JJ LOADING MAP SCREEN: LOAD DATA ", id);

        // Check if the dataset info is already loaded - this could be a rerender
        if(useMapStore.getState().loaded_saved_map_data_set_info[id]){
            //console.log("JJ RE-RENDER")
            // data set info already loaded
            loadSavedMapDataSets();
        }

        if(useMapStore.getState().is_fetching_saved_map !== true) {


            useMapStore.setState({is_fetching_saved_map: true});

            let requestData = {
                id: id,
            }

            fetch(useMapStore.getState().config.THEY_RULE_API_PATH + 'loadmap.php',

                {
                    method: 'POST', // *GET, POST, PUT, DELETE, etc.
                    mode: 'cors', // no-cors, *cors, same-origin
                    cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
                    credentials: 'same-origin', // include, *same-origin, omit
                    headers: {
                        'Content-Type': 'application/json'
                        // 'Content-Type': 'application/x-www-form-urlencoded',
                    },
                    redirect: 'follow', // manual, *follow, error
                    referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
                    body: JSON.stringify({"requestdata": requestData}) // body data type must match "Content-Type" header
                }
            )
                .then(response => response.json())
                .then(data => {
                    //console.log("JJ fetched: ", data);
                    // Add to the map store
                    storeLoadedSavedMapDataSet(id, data);
                    // load the data sets from the data set info
                    loadSavedMapDataSets();
                }).catch(error => {
                //console.error('There was an error!', error);
                onMapLoadError();
            });
        }

    }        // ONLY CALL THIS ON MOUNT
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const drawLoadedMap = () => {
        //console.log("Draw loaded map loaded_saved_map_data_set_info: ", useMapStore.getState().loaded_saved_map_data_set_info[id]);
        //console.log("Draw loaded map getState: ", useMapStore.getState());

        let savedDS = useMapStore.getState().loaded_saved_map_data_set_info[id];
        // Reconstruct map store data from node positions
        let mutation = {
            comps_s: {},
            comps_m: {},
            comps_l: {},
            dirs_ms: {},
            dirs_mm: {},
            dirs_ml: {},
            dirs_mxl: {},
            dirs_fs: {},
            dirs_fm: {},
            dirs_fl: {},
            dirs_fxl: {},
            links: {}
        };
            // e.g. mutation: {
            //     comps_l: {
            //         Q3884: {
            //                 dragParent: undefined,
            //                 id:"Q3884",
            //                 nType:"organization",
            //                 qid:"Q3884",
            //                 startX:0,
            //                 startZ:0
            //         }
            //     },
            //     links: {
            //          Q3884_Q264913: {
            //                 id: 'Q3884Q264913',
            //                 source: 'Q3884',
            //                 target: 'Q264913'
            //            },
            //      }
            // }
        let node_selected_states = {};
            // e.g node_selected_states:
            // {Q3884: true}
        let node_target_positions = {};
            // e.g. node_target_positions:
            //  Q3884: {
            //      remove_on_rest: false,
            //      x: 0,
            //      z: 0
            //  }
            // }
        let nodeType, mutationType;
        let links = {};
        let link = {};
        let name = "";

        Object.entries(savedDS.node_positions).forEach(([id, pos]) => {
            // console.log("saved node pos: ", id, pos);
            // is the node an org or a dir?
            nodeType = useMapStore.getState().dataset.Dirs[id]? Entities.DIR : Entities.ORG;
            if(nodeType === Entities.DIR) {
                mutationType = useMapStore.getState().actions.getDirectorType(id);
            } else {
                mutationType = useMapStore.getState().actions.getTableSize(id);
            }
            // Add node to mutation object
            mutation[mutationType][id] = {
                dragParent: undefined,
                id: id,
                nType: nodeType,
                qid: id,
                startX: 0,
                startZ: 0
            }
            // add node to selected states
            node_selected_states[id] = false;
            node_target_positions[id] = {
                remove_on_rest: false,
                x: pos[0] + 1,
                z: pos[2] + 1
            }
            // add links

            switch (nodeType) {
                case Entities.DIR:

                    useMapStore.getState().dataset.Dirs[id].c.forEach((c) => {
                            name = c + "_" + id
                            if (savedDS.node_positions[c] && !links[name]) {
                                link = {id: c + id, source: c, target: id}
                                links = {...links, [name]: link}
                            }
                        }
                    )
                    break;
                default:
                case Entities.ORG:
                    useMapStore.getState().dataset.Orgs[id].d.forEach((d) => {
                            name = id + "_" + d
                            if (savedDS.node_positions[d] && !links[name]) {
                                link = {id: id + d, source: id, target: d}
                                links = {...links, [name]: link}
                            }
                        }
                    )
                    break;
            }

        });

        mutation["links"] = links;

        // Set the node positions & camera
        useMapStore.setState({
                mutation: mutation,
                node_selected_states: node_selected_states,
                node_target_positions: node_target_positions,
                node_positions: savedDS.node_positions,
                camera_zoom_target: savedDS.camera_zoom_target,
                camera_target: savedDS.camera_target,
            }
        );

        setIsLoaded(true);

    }

    const openLoadedMap = () => {
        //console.log("openLoadedMap");
        useMapStore.setState({
                saved_map_loaded: true, // this is what switches the view
            }
        );
    }

    //console.log("key introTickCount: ", introTickCount, "openMapButtonDuration",  openMapButtonDuration, " percent: ", introTickCount/openMapButtonDuration);

    return (
        <>
        <div className="IntroScreen">
            <Chair screen={"intro"} />
            <div className={"introTextWrap"}>
                {introText.map((txt, i) =>{
                    return(<div key={"introtext_" + i} className={["introText", Math.min(introTextIndex, introText.length-1) === i ? "introTextVisible" : ""].join(" ")}>{txt}</div>)
                })}
            </div>
        </div>
        <div className={"saved-map-controls"}>
            { !isLoaded && <div className={'loading-text'}>{strings.loadingSavedMap}</div> }
            { isLoaded &&                         <div className={'loading-text'}><TheyRuleButton
                label={"Open map"}
                action={() => {
                    openLoadedMap();
                }}
                mode={ButtonStyles.LIGHT}
                btnType={ButtonTypes.PROGRESS}
                percent={Math.min(100, introTickCount/openMapButtonDuration*100)}
                duration={openMapButtonDuration}
            />
            </div>}
        </div>
        </>
    );
}

export default MapLoading;