import React from "react";
import "./Dialog.scss";
import {isMobile} from "react-device-detect";
import TheyRuleButton, {ButtonTypes} from "../TheyRuleButton";
import useDialogStore from "../../../data/dialog_store";
import Icon from "../../Icons";
import {Icons} from "../../Icons/Icon";
import FocusTrap from "focus-trap-react";

function Dialog () {


    const {title, body, primary, secondary, tertiary, primary_action, secondary_action, tertiary_action} = useDialogStore((state) => state)
    const {onCloseDialog} = useDialogStore((state) => state.actions)

    //console.log("Dialog title: ", title, " tertiary", tertiary, "primary: ", primary, "primaryAction", primary_action, "tertiary_action", tertiary_action );

    return <div className={"dialog-wrap" + (isMobile?" mobile":"")} aria-modal={"true"} >
        <div className={"dialog-bg"} onClick={(e)=>{e.stopPropagation();}}></div>
        <FocusTrap>
        <div className={"dialog" + (isMobile?" mobile":"")} >
            <Icon icon={Icons.CLOSE} onClick={onCloseDialog} interactive={true} />
            <div className={"dialog-title"}>{title}</div>
            <div className={"dialog-body"}>{body}</div>
            <div className={"dialog-buttons"}>
                {tertiary && tertiary_action && <TheyRuleButton label={tertiary} btnType={ButtonTypes.TERTIARY} action={tertiary_action} tabIndex={0} /> }
                {secondary && secondary_action && <TheyRuleButton label={secondary} btnType={ButtonTypes.SECONDARY} action={secondary_action} tabIndex={0} /> }
                {primary && primary_action && <TheyRuleButton label={primary} btnType={ButtonTypes.PRIMARY} action={primary_action} tabIndex={0} focusOnLoad={true} /> }
            </div>
        </div>
        </FocusTrap>
    </div>
}

export default Dialog;