
import React, {useEffect, useState} from "react";
import "./Home.scss";
import IntroScreen from '../../components/IntroScreen';
import Map from "../../components/Map/Map";
import useViewStore from "../../data/view_store";
import MapOverlays from "../../components/Map/MapOverlays";
import Dialog from "../../components/UI/Dialog";
import useDialogStore from "../../data/dialog_store";
import { useParams } from 'react-router-dom';
import {Entities, ViewModes} from "../../data/constants";
import MapLoading from "../../components/Map/MapLoading";
import useMapStore from "../../data/map_store";
import InfoPage from "../InfoPage";
import {strings} from "../../data/strings";
import Beta from "../Beta";

function Home() {

    const {id, personInfoId, orgInfoId, datasetInfo} = useParams();
    // const [showBeta, setShowBeta] = useState(useMapStore.getState().betaPassword !== undefined && !useMapStore.getState().passwordEntered);
    const [showBeta, setShowBeta] = useState(false);

    //console.log("home rerender id: ", id, " personInfoId: ", personInfoId);

    const setup = () => {
        if (!!id) {
            // has a saved map
            //console.log("home has id - saved map");
            if (!useMapStore.getState().saved_map_loaded) {
                useMapStore.setState({data_loaded: false}); // ensures that the data loads correctly - important for when someone uses back button to go to a saved map
                useViewStore.getState().actions.setViewMode(ViewModes.MAP_LOADING);
            } else {
                useViewStore.getState().actions.setViewMode(ViewModes.MAP);
            }
        }
        if (!!personInfoId) {
            useViewStore.getState().actions.setViewMode(ViewModes.PERSON_INFO_PAGE);
        }
        if (!!orgInfoId) {
            useViewStore.getState().actions.setViewMode(ViewModes.ORG_INFO_PAGE);
        }

    }

    /**
    Listen for changes in map store saved_map_loaded
    */
    useEffect(() => {

        const unsubTrigger = useMapStore.subscribe(
            (state) => (state.saved_map_loaded),
            () => {
                setup();
            }
        )

        //console.log("MapOverlays setInfo");
        setup();

        return () => {
            // Clean up the subscription
            unsubTrigger();
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const show_map_loading = useViewStore((state) => state.view.show_map_loading);
    const show_intro = useViewStore((state) => state.view.show_intro);
    const show_dialog = useDialogStore((state) => state.show_dialog);
    const show_person_info_page = useViewStore((state) => state.view.show_person_info_page);
    const show_org_info_page = useViewStore((state) => state.view.show_org_info_page);
    setup();


    let content;
    if (showBeta){
        content = <Beta setShowBeta={setShowBeta} />
    } else
    if (show_map_loading) {
        content = <MapLoading id={id} />
    } else if (show_intro){
        content = <IntroScreen />
    } else if (show_person_info_page) {
        content = <InfoPage wikidataID={personInfoId} entityType={Entities.DIR} />
    } else if (show_org_info_page) {
        content = <InfoPage wikidataID={orgInfoId} entityType={Entities.ORG} />
    }
    else {
        content =  (
            <>
                <h1 className={"screen-reader-only"} aria-label={strings.theyRuleMap} ></h1>
                <Map/>
                <MapOverlays/>
                {/*<Stats className={"stats"} />*/}
            </>
        )
    }

    return (
        <main className="Home">
            {content}
            {show_dialog  && <Dialog />}
        </main>
    );
}

export default Home;