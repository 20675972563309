export const Entities = {
    DIR: "director",
    ORG: "organization"
}

export const ViewModes = {
    INTRO: "intro",
    SEARCH: "search",
    ACTIVE_SEARCH: "active_search",
    MENU: "menu",
    FILTERS: "filters",
    MAP: "map",
    MAP_LOADING: "map_loading",
    INFO: "info",
    PERSON_INFO_PAGE: "person_info_page",
    ORG_INFO_PAGE: "org_info_page",
    MENU_CLOSING: "menu_closing",
}

export const AutoPlayStates = {
    PLAYING: "playing",
    PAUSED: "paused",
    OFF: "off"
}

export const Regions = {
    US: "us"
}

export const DataSetType = {
    COMPANIES: "companies",
    US_ADMINS: "us_administrations",
    ADD_ON: "add_on"
}

export const OrgType = {
    COMPANY: "company",
    US_PRES_ADMIN: "us_administration",
    THINK_TANK: "think_tank",
    CHARITY: "charity"
}

export const DataSetSource = {
    THEY_RULE: "they_rule",
    WIKIDATA: "wikidata"
}

export const Criteria = {
    REVENUE: "revenue",
    INFLUENCE: "influence" // used for think tanks
}

export const DataSetStatus = {
    ACTIVE: "active",
    ARCHIVED: "archived",
    DEFAULT: "default"
}

export const dataPeriods = {
    RECENT: "recent",
    HISTORICAL: "historical"
}

export const AddOns = {
    THINKTANKS: "thinktanks"
}

export const ControlModes = {
    PAN: "pan",
    ROTATE: "rotate"
}

export const Side = {
    LEFT: "left",
    RIGHT: "right",
}