import React, {useEffect, useRef, useState} from "react";
import "./InfoPanel.scss";
import {isMobile} from "react-device-detect";
import {a, useSpring, config} from "@react-spring/web";
import useViewStore from "../../data/view_store";
import Icon from "../Icons";
import {Icons} from "../Icons/Icon";
import InfoPanelPerson from "./InfoPanelPerson";
import {Entities, ViewModes} from "../../data/constants";
import InfoPanelOrg from "./InfoPanelOrg";
import useMapStore from "../../data/map_store";
import {strings} from "../../data/strings";
import {useDrag} from "@use-gesture/react";
import FocusTrap from "focus-trap-react";

function InfoPanel() {

    const {show_info} = useViewStore((state) => state.view);
    const {setViewMode} = useViewStore((state) => state.actions);
    const {Orgs, Dirs} = useMapStore((state) => state.dataset);
    const [wikiDataId, setWikiDataId] = useState(useViewStore.getState().info_panel_node);
    const [entityType, setEntityType] = useState(useViewStore.getState().info_panel_node_type);
    const [focusCloseButton, setFocusCloseButton] = useState(false);
    const ref = useRef();
    const em = !!ref.current ? parseFloat(getComputedStyle(ref.current).fontSize) : 16;
    const width = isMobile ? window.innerWidth : 40 * em;
    const [{x}, api] = useSpring(() => ({x: width}));

    // Adapted code from: https://codesandbox.io/s/github/pmndrs/use-gesture/tree/main/demo/src/sandboxes/action-sheet?file=/src/styles.module.css to handle the animated opening and swipe to close functionality

    const open = ({canceled}) => {
        // when cancel is true, it means that the user passed the upwards threshold
        // so we change the spring config to create a nice wobbly effect
        api.start({
            x: 0,
            immediate: false,
            onStart: () => {
                setFocusCloseButton(false)
            },
            onRest: () => {
                setFocusCloseButton(true)
            },
            config: canceled ? config.wobbly : config.stiff
        })
    }
    const close = (velocity = 0) => {
        api.start({
            x: width,
            immediate: false,
            onRest: () => {
                setViewMode(ViewModes.MAP)
            },
            config: {...config.stiff, velocity}
        })
    }

    const bind = useDrag(
        (e) => {
            // if the user drags up passed a threshold, then we cancel
            // the drag so that the sheet resets to its open position
            if (e.movement[0] < -70) {
                e.cancel()
            }

            // when the user releases the sheet, we check whether it passed
            // the threshold for it to close, or if we reset it to its open positino
            if (e.last) {
                let canceled = e.canceled;
                if ((
                        (e.distance[0] * 3) > width * 0.3 || (e.velocity[0] > 0.3)
                    )
                    && e.direction[0] > 0
                ) {
                    onClose(e.velocity[0] > 0.5 ? e.velocity[0] : 0.65)
                } else {
                    open({canceled})
                }
                ;
            }
                // when the user keeps dragging, we just move the sheet according to
            // the cursor position
            else {
                api.start({x: e.movement[0] > 0 ? e.movement[0] * 3 : e.movement[0], immediate: true})
            }
        },
        {from: () => [x.get(), 0], filterTaps: true, bounds: {right: 0}, rubberband: true}
    )

    const display = x.to((posX) => (posX < width ? 'block' : 'none'));


    const onClose = (velocity) => {
        close(velocity);
    }

    /**
     INFO PANEL NODE CHANGED
     Listen for changes in the info_panel_node
     */
    useEffect(() => {
        const unsubInfoPanelNode = useViewStore.subscribe(
            (state) => (state.info_panel_node),
            (node) => {
                //console.log("On infoPanel Node changed: ", node, useViewStore.getState().info_panel_node_type);
                setWikiDataId(node);
                setEntityType(useViewStore.getState().info_panel_node_type);
            }
        )
        return () => {
            // Clean up the subscription
            unsubInfoPanelNode();
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])


    /**
     INFO PANEL OPENED OR CLOSED
     Listen for changes in the info_panel_node
     */
    useEffect(() => {
        const unsubInfoPanelView = useViewStore.subscribe(
            (state) => (state.view.show_info),
            (arg) => {
                //console.log("Info View arg: ", arg);
                let showInfo = useViewStore.getState().view.show_info;
                //console.log("Info View changed: ", showInfo);
                if (showInfo) {
                    open(false);
                } else {
                    //  close(); handled elsewhere
                }
            }
        )
        return () => {
            // Clean up the subscription
            unsubInfoPanelView();
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])


    return (<>
            {!!show_info && <FocusTrap><div ref={ref} className="flex" style={{overflow: 'hidden'}} aria-modal={"true"}>
                <div className={"close-menu-overlay"} onClick={() => {
                    onClose(.8)
                }} tabIndex={0} role={"button"} aria-label={"Close Info Panel"}/>

                    <section {...(!!wikiDataId && entityType ? {"aria-label": "Information about " + ((entityType === Entities.DIR) ? Dirs[wikiDataId].n : Orgs[wikiDataId].n)} : {})}
                    >
                        <div className={"info_panel_wrap" + (isMobile ? " mobile" : "")}>
                            <a.div className={"info_panel"} {...bind()}
                                   style={{display, x}}>
                                <div className={"close_info_panel"} >
                                    <Icon icon={Icons.BACK_RIGHT} onClick={() => onClose(.8)} interactive={true}
                                          label={strings.closeInfoPanel} autofocus={focusCloseButton}/>
                                </div>
                                <div className={"info_panel_body"}>
                                    {!!wikiDataId && entityType === Entities.DIR &&
                                    <InfoPanelPerson wikidataID={wikiDataId} isStandAlonePage={false}/>}
                                    {!!wikiDataId && entityType === Entities.ORG &&
                                    <InfoPanelOrg wikidataID={wikiDataId} isStandAlonePage={false}/>}
                                </div>
                            </a.div>
                        </div>
                    </section>
            </div></FocusTrap>}
        </>

    )

}

export default InfoPanel;