import React, {useEffect, useLayoutEffect, useReducer, useState} from "react";
import "./SearchSuggestions.scss";
import {isMobile} from 'react-device-detect';
import SearchItem from "../SearchItem/SearchItem";
import {Entities, ViewModes} from "../../../data/constants";
import useMapStore from "../../../data/map_store";
import {strings} from "../../../data/strings";
import useViewStore from "../../../data/view_store";
import useKeyPress from "../../../hooks/useKeyPress";

export const SearchResultsLayouts = {
    VERTICAL: "v",
    HORIZONTAL: "h"
}


function SearchSuggestions ({query, onClose, focusSearch, ...props}) {

    const [display,setDisplay]=useState(false);
    const [layout,setLayout]=useState(SearchResultsLayouts.VERTICAL);
    const {Orgs, Dirs, TopComs, TopDirs, TopConnectedOrgs, OrgsWomenMost, OrgsWomenLeast, Admins, AddOns} = useMapStore((state) => state.dataset);
    const viewMode = useViewStore((state) => state.view_mode);
    const [ renderNum,triggerRerender] = useState(1);
    const arrowUpPressed = useKeyPress('ArrowUp');
    const arrowDownPressed = useKeyPress('ArrowDown');

    const [searchResultsTitle, setSearchResultsTitle] = useState("");
    const [searchSuggestionsCols, setSearchSuggestionsCols] = useState([]);

    const selectedStateInitializer = initialState => {
        //console.log("selectedStateInitializer, initialState: ", initialState);
        return {
            selectedIndex: initialState,
        };
    };

    const reducer = (selectedState, action) => {

        let numSuggestions = useMapStore.getState().num_search_suggestions;

        //console.log("reducer action.type: ", action.type);
        //console.log("numSuggestions: ", numSuggestions);
        //console.log("selectedState.selectedIndex: ", selectedState.selectedIndex);
        let result;
        switch (action.type) {
            case 'arrowUp':
                result = {selectedIndex:
                        selectedState.selectedIndex !== 0 ? selectedState.selectedIndex - 1 : numSuggestions,
                };
                break;
            case 'arrowDown':
                result = {
                    selectedIndex:
                        selectedState.selectedIndex !== numSuggestions ? selectedState.selectedIndex + 1 : 0,
                };
                break;
            case 'select':
                result = { selectedIndex: action.payload };

                break;
            default:
                throw new Error();
        }

        if(result.selectedIndex === 0){
            focusSearch();
        }

        //console.log("result: ", result);

        return result;
    };

    const [selectedState, dispatch] = useReducer(reducer, 0, selectedStateInitializer);

    useEffect(() => {
        if (arrowUpPressed) {
            //console.log('arrowUpPressed');
            dispatch({ type: 'arrowUp' });
        }
    }, [arrowUpPressed]);

    useEffect(() => {
        if (arrowDownPressed) {
            //console.log('arrowDownPressed');
            dispatch({ type: 'arrowDown' });

        }
    }, [arrowDownPressed]);

    useEffect(() => {
        //console.log("%c SEARCH SUGGESTIONS SHOW RESULTS", "color:green", props.showResults)
        setDisplay(props.showResults);
    }, [props.showResults])

    useEffect(() => {
        if (props.layout) {
            setLayout(props.layout);
        }
    }, [props.layout])

    useLayoutEffect(()=>{
        const unsub = useMapStore.subscribe((state) => (state.dataset.data_loaded), ()=>{triggerRerender(renderNum + 1);});
        return () => {
            // Clean up the subscription
            unsub();
        };
    })

    const createSuggestions = () => {
        let suggestionsLength = 3;
        if (props.maxColLength) {
            suggestionsLength = props.maxColLength;
        }

        // Set the number of suggestions for both directors and companies
        let comSuggestions = <div></div>;
        let dirSuggestions = <div></div>;
        let resultsTitle = "";
        let suggestionsCols = [];

        if (!useMapStore.getState().data_loaded) {
            return <div></div>
        }

        let suggestionIndex = 0; // used for arrow keys

        if (query === "") {
            // In the case of no query, show the top companies (TopComs) and directors (TopDirs)
            // console.log("TopComs: ", TopComs);

            let dataset = useMapStore.getState().datasetInfo;

            let comTitle = strings.topCompanies;
            comTitle = comTitle.replace("*area*", strings[dataset.region]);
            comTitle = comTitle.replace("*year*", dataset.year);
            comSuggestions = <div className={"results-col"} key={"com suggestions"}>
                <h2 className="search-header">{comTitle}</h2>
                {TopComs.slice(0, suggestionsLength).map((item) => <SearchItem key={item} type={Entities.ORG} id={item}
                                                                               onClose={onClose}
                                                                               selected={selectedState.selectedIndex === ++suggestionIndex}/>)}
            </div>;

            let dirTitle = strings.mostConnectedDirectors;
            dirSuggestions = <div className={"results-col"} key={"dir suggestions"}>
                <h2 className="search-header">{dirTitle}</h2>
                {TopDirs.slice(0, suggestionsLength).map((item) => <SearchItem key={item} type={Entities.DIR} id={item}
                                                                               onClose={onClose}
                                                                               selected={selectedState.selectedIndex === ++suggestionIndex}/>)}
            </div>;

            suggestionsCols.push(comSuggestions, dirSuggestions);

            //TopConnectedOrgs, OrgsWomenMost, OrgsWomenLeast, Admins
            if (viewMode !== ViewModes.INTRO) {

                resultsTitle = <h2 className={"search-header main-title"}>{
                    strings.searchResultsTitleNoQuery
                        .replace("*total_orgs*", dataset.total_orgs)
                        .replace("*region*", dataset.region)
                        .replace("*org_type*", dataset.org_type)
                        .replace("*year*", dataset.year)
                        .replace("*source*", dataset.source)
                }</h2>;


                if (TopConnectedOrgs.length > 1) {
                    let tcoSuggestions = <div className={"results-col"} key={"tco suggestions"}>
                        <h2 className="search-header">{strings.mostConnectedCompanies}</h2>
                        {TopConnectedOrgs.slice(0, suggestionsLength).map((item) => <SearchItem key={"TCO" + item}
                                                                                                type={Entities.ORG}
                                                                                                id={item}
                                                                                                onClose={onClose}
                                                                                                selected={selectedState.selectedIndex === ++suggestionIndex}/>)}
                    </div>;
                    suggestionsCols.push(tcoSuggestions);
                }

                if (OrgsWomenLeast.length > 1) {
                    let results = <div className={"results-col"} key={"lw suggestions"}>
                        <h2 className="search-header">{strings.boardsWithLeastWomen}</h2>
                        {OrgsWomenLeast.slice(0, suggestionsLength).map((item) => <SearchItem key={"OWM" + item.o}
                                                                                              type={Entities.ORG}
                                                                                              id={item.o}
                                                                                              extraInfo={"— " + item.pw + "%"}
                                                                                              onClose={onClose}
                                                                                              selected={selectedState.selectedIndex === ++suggestionIndex}/>)}
                    </div>;
                    suggestionsCols.push(results);
                }

                if (OrgsWomenMost.length > 1) {
                    let results = <div className={"results-col"} key={"mw suggestions"}>
                        <h2 className="search-header">{strings.boardsWithMostWomen}</h2>
                        {OrgsWomenMost.slice(0, suggestionsLength).map((item) => <SearchItem key={"OWM" + item.o}
                                                                                             type={Entities.ORG}
                                                                                             id={item.o}
                                                                                             extraInfo={"— " + item.pw + "%"}
                                                                                             onClose={onClose}
                                                                                             selected={selectedState.selectedIndex === ++suggestionIndex}/>)}
                    </div>;
                    suggestionsCols.push(results);
                }

                if (Admins.length > 1) {
                    //console.log("SS Render admins in search suggestions: ", Admins, useMapStore.getState().dataset.Admins);
                    let tcoSuggestions = <div className={"results-col"} key={"admin suggestions"}>
                        <h2 className="search-header">{strings.presidentialAdministrations}</h2>
                        {Admins.slice(0, suggestionsLength).map((item) => <SearchItem key={"Admin" + item}
                                                                                      type={Entities.ORG} id={item}
                                                                                      onClose={onClose}
                                                                                      selected={selectedState.selectedIndex === ++suggestionIndex}/>)}
                    </div>;
                    suggestionsCols.push(tcoSuggestions);
                }
                if (Object.keys(AddOns).length > 0) {
                    Object.entries(AddOns).forEach((e) => {
                        // Split the ID of the addon by underscore:
                        // "us_thinktanks_revenue_2020_12_31_1"
                        // And build the title from that using strings as necessary
                        let addOnInfoArray = e[0].split("_");
                        let region = addOnInfoArray[0].toUpperCase();
                        let orgtype = strings[addOnInfoArray[1].toLowerCase()];
                        let criteria = strings[addOnInfoArray[2].toLowerCase()];
                        let tcoSuggestions = <div className={"results-col"} key={e[0] + "_suggestions"}>
                            <h2 className="search-header">{strings.searchSuggestionsTopAddOns
                                .replace("*region*", region)
                                .replace("*org_type*", orgtype)
                                .replace("*criteria*", criteria)
                            }</h2>
                            {e[1].slice(0, suggestionsLength).map((item) => <SearchItem key={e[0] + item}
                                                                                        type={Entities.ORG} id={item}
                                                                                        onClose={onClose}
                                                                                        selected={selectedState.selectedIndex === ++suggestionIndex}/>)}
                        </div>;
                        suggestionsCols.push(tcoSuggestions);


                    })

                }


            }

        } else {
            // In the case of a query, use the query to filter possible companies (Coms) and directors (Dirs)
            let q = query.toLowerCase();
            let filteredComs = Object.entries(Orgs).filter(([key, value]) => value.n.toLowerCase().indexOf(q) === 0);
            if ("google".indexOf(q) !== -1) {
                // add alphabet for Google
                if (Orgs["Q20800404"]) {
                    filteredComs.push(["Q20800404", Orgs["Q20800404"]]);
                }
            }
            if ("aig".indexOf(q) !== -1) {
                // add American International Group for AIG
                if (Orgs["Q212235"]) {
                    filteredComs.push(["Q212235", Orgs["Q212235"]]);
                }
            }
            if ("facebook".indexOf(q) !== -1) {
                // add Meta for Facebook
                if (Orgs["Q380"]) {
                    filteredComs.push(["Q380", Orgs["Q380"]]);
                }
            }
            if ("disney".indexOf(q) !== -1 || "walt disney".indexOf(q) !== -1) {
                // add Disney for the walt disney company
                if (Orgs["Q7414"] && filteredComs.indexOf("Q7414") === -1) {
                    filteredComs.push(["Q7414", Orgs["Q7414"]]);
                }
            }

            let totalSuggestionsLength = suggestionsLength * 2;

            // The directors will grow to be a very large object - if that starts hurting performance try these work-arounds:
            // 1. rather than mapping the whole object cycle through it and break when you have enough
            // 2. Break the object into smaller mapping arrays of EG FirstNamesStartingWithA, and LastNamesStartingWithA etc - with names and keys. Just build these once at the beginning...
            let filteredDirs = Object.entries(Dirs).filter(([key, value]) => value.n.toLowerCase().indexOf(q) === 0);
            let moreDirsIDs = [];
            if (filteredDirs.length < totalSuggestionsLength) {
                // Append directors whose names match the query in the start of their last name
                filteredDirs = filteredDirs.concat(Object.entries(Dirs).filter(([key, value]) => value.n.toLowerCase().indexOf(" " + q) > 0));
                if (filteredDirs.length < totalSuggestionsLength) {
                    // Append directors whose names match the query anywhere in their  names
                    let moreDirs = Object.entries(Dirs).filter(([key, value]) => value.n.toLowerCase().indexOf(q) > 0);
                    // create an array of moreDirs with just IDs
                    moreDirsIDs = moreDirs.map((item) => item[0]);
                }
            }
            // create an array from the filteredDirs with just IDs
            let tempIDs = filteredDirs.map((item) => item[0]);

            // combine arrays and remove dupes - remove duplicates
            let filteredDirsIDs = Array.from(new Set(tempIDs.concat(moreDirsIDs)));

            let numComs, numDirs = suggestionsLength;
            if (SearchResultsLayouts.VERTICAL) {
                // The next two lines are meant to ensure that we get the total number of results even if that means getting more directors or more companies
                numComs = totalSuggestionsLength - Math.min(suggestionsLength, filteredDirsIDs.length);
                numDirs = totalSuggestionsLength - Math.min(numComs, filteredComs.length);
            }

            if (filteredComs.length > 0) {
                comSuggestions = <div className={"results-col"}>
                    <h2 className="search-header">{(Object.keys(AddOns).length > 0 || Admins.length > 0) ? strings.organizations : strings.companies}</h2>
                    {filteredComs.slice(0, numComs).map((item) => <SearchItem key={item[0]} type={Entities.ORG}
                                                                              id={item[0]}
                                                                              onClose={onClose}
                                                                              selected={selectedState.selectedIndex === ++suggestionIndex}/>)}
                </div>;
            }

            // console.log("filteredDirsIDs: ", filteredDirsIDs);

            if (filteredDirsIDs.length > 0) {
                dirSuggestions = <div className={"results-col"}>
                    <h2 className="search-header">{strings.directors}</h2>

                    {filteredDirsIDs.slice(0, numDirs).map((item) => <SearchItem key={item} type={Entities.DIR}
                                                                                 id={item}
                                                                                 onClose={onClose}
                                                                                 selected={selectedState.selectedIndex === ++suggestionIndex}/>)}
                </div>;
            }

            suggestionsCols.push(comSuggestions, dirSuggestions);

        }

        //setSuggestionsCols(tempSuggestionsCols);


        useMapStore.setState({num_search_suggestions: suggestionIndex});

        // console.log("suggestionsCols: ", suggestionsCols);
        // console.log("selectedState.selectedIndex: ", selectedState.selectedIndex);

        setSearchResultsTitle(resultsTitle);
        setSearchSuggestionsCols(suggestionsCols);

    }

    useEffect(()=>{
        createSuggestions();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [query, selectedState]);

    return (
        <>
            {isMobile && <div className={`search-results v mobile ${display? "show":"hidden"}`} >{searchResultsTitle}{searchSuggestionsCols.map((c, i)=>{return <div key={"sug" + i}>{c}</div>})}</div>
            }
            {!isMobile && <div className={`search-results ${layout===SearchResultsLayouts.VERTICAL?"v":"h"} ${display? "show":"hidden"}`} >{searchResultsTitle}{searchSuggestionsCols.map((c, i)=>{return <div key={"sug" + i} className={"results-block"}>{c}</div>})}</div>}
        </>
    );
};

export default SearchSuggestions;