import React, {Suspense, useEffect, useState, useRef} from "react";
import "./Map.scss";
import {Canvas} from "@react-three/fiber";
import CameraOrbitControls from "../CameraOrbitControls";
import MapInstances from "./MapInstances";
import useViewStore from "../../data/view_store";
import useMapStore from "../../data/map_store";
import {AutoPlayStates, ControlModes} from "../../data/constants";
import useEventListener from "../../hooks/useEventListener";
import {usePinch} from "@use-gesture/react";

function Map() {

    //console.log("map rerender");
    const show_map = useViewStore((state) => state.view.show_map)
    const {setSelectedNode, pauseAutoPlay } = useMapStore((state) => state.actions);

    // const camRef = useCallback(camera => {
    //     console.log("map camRef changed", camera);
    //     if (camera !== null) {
    //         setCamera(camera);
    //     }
    // }, [setCamera]); //


    const ref = useRef(null);
    usePinch((e) => {
        if(useMapStore.getState().is_pinching !== e.pinching){
            //console.log("#Drag OnPinch, ", e.origin);
            useMapStore.setState({is_pinching: e.pinching})
        }

        // Orbit controls doesn't zoom in and out from the center of the pinch
        // This is a failed attempt at making it do that...

        // if(e.pinching){
        //     let raycaster = new THREE.Raycaster();
        //     let intersects = new THREE.Vector3();
        //     let camera = useMapStore.getState().camera;
        //     let origin = new THREE.Vector2();
        //     origin.x = (e.origin[0] / window.innerWidth) * 2 - 1;
        //     origin.y = (e.origin[1] / window.innerHeight) * 2 + 1;
        //     raycaster.setFromCamera(origin, camera);
        //     raycaster.ray.intersectPlane(useMapStore.getState().floor_plane, intersects);
        //     useMapStore.getState().actions.setCameraTarget([intersects.x, 0, intersects.z]);
        // }
    }, {
        target: ref,
        eventOptions: { passive: false }
    })


    const [isAutoPlaying, setIsAutoPlaying] = useState(false);

    const onMapClicked = () => {
        //console.log("on map clicked");
        setSelectedNode(null);
    }

    const onMapWrapperClicked = () => {
        //console.log("on map wrapper clicked");
        if(isAutoPlaying) {
            pauseAutoPlay();
        }
    }

    // Listen for delete key
    const keyDownHandler = ({ key }) => {
        //console.log("key pressed: ", key);
        switch (key){
            case "Backspace":
            case "Delete":
                //console.log("delete selected: ", useMapStore.getState().selected_node);
                useMapStore.getState().actions.deleteSelectedNode();
                break;
            case "-":
            case "_":
                useMapStore.getState().actions.setZoom(Math.max(5, useMapStore.getState().camera_zoom_target - 5));
                //console.log("minus key: zoom out: ", useMapStore.getState().camera_zoom_target);
                break;
            case "=":
            case "+":
                useMapStore.getState().actions.setZoom(useMapStore.getState().camera_zoom_target + 5);
                //console.log("minus key: zoom out: ", useMapStore.getState().camera_zoom_target);
                break;
            case "p":
            case "P":
                useMapStore.getState().actions.setControlMode(ControlModes.PAN);
                useMapStore.getState().actions.setAutoControlMode(false);
                break;
            case "r":
            case "R":
                useMapStore.getState().actions.setControlMode(ControlModes.ROTATE);
                useMapStore.getState().actions.setAutoControlMode(false);
                break;
            case "a":
            case "A":
                useMapStore.getState().actions.setAutoControlMode(true);
                break;
            case "[":
                useMapStore.getState().actions.setMoveCameraAfterDragMode(false);
                break
            case "]":
                useMapStore.getState().actions.setMoveCameraAfterDragMode(true);
                break;
            default:
                break;
        }
    }
    useEventListener('keydown', keyDownHandler);


    /*
        IS IT IN AUTO PLAY?
        No mouse events on the canvas when it is in AutoPlay
    */
    useEffect(() => {
        const autoPlayChange = (AutoPlayState) => {
            //console.log("%c Map autoPlayChange isAutoPlaying: ", "color:red", AutoPlayState);
            setIsAutoPlaying(AutoPlayState !== AutoPlayStates.OFF);
        }

        const unsubAuto = useMapStore.subscribe(
            (state) => (state.auto_play_state),
            (autoPlayState)=>{ autoPlayChange(autoPlayState) }
        )

        // call this the first time if it has a target
        let isAutoPlaying = useMapStore.getState().auto_play_state !== AutoPlayStates.OFF;
        if(isAutoPlaying) {
            autoPlayChange(useMapStore.getState().auto_play_state)
        }

        return () => {
            unsubAuto(); // Clean up the subscription
        };

        // ONLY CALL THIS ON MOUNT
        // eslint-disable-next-line react-hooks/exhaustive-deps
    })

    return (
        <section className={`map-wrapper ${show_map ? "" : "hidden"}`} onClick={onMapWrapperClicked} aria-label={"map"}>
            <div className={"map"}>
                <Canvas
                    style={{background: "white", pointerEvents: isAutoPlaying?"none":"inherit"}}
                    dpr={[1, 2]}
                    onPointerMissed={onMapClicked}
                    orthographic
                    camera={{ zoom: 30, position: [10, 160, 2000], far:200000 }}
                    ref={ref}
                >
                    <Suspense fallback={null}>
                        <ambientLight intensity={.8}/>
                        <directionalLight
                            intensity={0.2}
                        />

                        <MapInstances />
                        <CameraOrbitControls />
                        {/*<OrthographicCamera*/}
                        {/*    ref={camRef}*/}
                        {/*    makeDefault*/}
                        {/*    zoom={30}*/}
                        {/*    position={[90, 60, 2000]}*/}
                        {/*    far = {200000}*/}
                        {/*/>*/}

                    </Suspense>

                </Canvas>
                <div id={"selectedLabel"} />
            </div>
        </section>
    );
}

export default Map;
