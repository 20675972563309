import React, {useEffect, useState} from "react";
import "./TogglePanRotate.scss";
import {Icons} from "../../Icons/Icon";
import Icon from "../../Icons";
import {strings} from "../../../data/strings";
import useMapStore from "../../../data/map_store";
import {ControlModes} from "../../../data/constants";

const TogglePanRotate = () => {

    const {setControlMode, setAutoControlMode } = useMapStore((state) => state.actions);
    const [mode, setMode] = useState(ControlModes.PAN);
    const [locked, setLocked] = useState(false);
    const handleClick = () => {
        setControlMode(useMapStore.getState().control_mode === ControlModes.PAN?ControlModes.ROTATE:ControlModes.PAN);
        setAutoControlMode(false);
    }

    const onLockClick = () => {
        setAutoControlMode(true);
    }

    /**
     HAS THE CONTROL MODE CHANGED?
     Listen for changes in PAN or ROTATE modes.
     */
    useEffect(() => {
        const unsubSel = useMapStore.subscribe(
            (state) => (state.control_mode),
            (mode)=>{
                //console.log("Pan Rotate Toggle detects selected node: ", mode);
                setMode(mode);
            }
        )
        return () => {
            // Clean up the subscription
            unsubSel();
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [setMode])

    /**
     HAS THE AUTO CONTROL MODE CHANGED?
     Listen for changes that have the controls in auto or locked mode
     */
    useEffect(() => {
        const unsubSel = useMapStore.subscribe(
            (state) => (state.is_auto_control_mode),
            (isAuto)=>{
                setLocked(!isAuto);
            }
        )
        return () => {
            // Clean up the subscription
            unsubSel();
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [setLocked])



    return (
        <div className={"toggle-pr-and-lock-wrap"}>
            <div className={"toggle-pr-wrap " + mode}
                 onClick={handleClick}
                 role = {"button"}
                 aria-label = {strings.togglePanRotate}
                 tabIndex = {0}
                 onKeyPress = {(e)=>{if(e.code === "Enter" || e.charCode===13){handleClick();} }}
                aria-pressed = {mode===ControlModes.ROTATE}
            >
                <div className={"toggle-pr-circle"}/>
                <Icon icon={Icons.PAN_ARROWS} strokeCol={mode === ControlModes.PAN? "#FFF": "#888"}/>
                <Icon icon={Icons.ROTATE_ARROWS} strokeCol={mode === ControlModes.ROTATE? "#FFF": "#888"}/>
            </div>
            {locked && <Icon
                icon={Icons.LOCK}
                strokeCol={"#888"}
                onClick={onLockClick}
                label = {strings.unlockPanRotateControls}
                interactive={true}
            />}
        </div>
    );
};

export default TogglePanRotate;
