
import React from "react";
import "./Placeholder.scss";
import Chair from "../../components/Chair";

function Placeholder() {


    return (
        <main className="placeholder">

            <Chair screen={"placeholder"}/>
            <h1>They Rule</h1>

            <p>They Rule was an interactive visualization of the interlocking directories of the largest US companies. It was built in Adobe Flash which has been discontinued. <a href={"https://www.youtube.com/watch?v=DPLD8uHxdtg"}>You can see a video of it here.</a></p>

    <p>A new version is in the works. If you would like to help contribute you can <a href={"https://docs.google.com/spreadsheets/d/1CdfZAgHGv9pn0aSSJK2udVeMGuLO-UUI8ldJq0t6ew8/edit?usp=sharing"}>add and update data on Wikidata</a>. Thank you.</p>

            <p><i>josh at theyrule.net</i></p>
        </main>
    );
}

export default Placeholder;