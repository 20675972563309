import create from 'zustand';
import { subscribeWithSelector } from "zustand/middleware";
import { immer } from "zustand/middleware/immer";
import {ViewModes} from "./constants";


const viewStore = (set, get) => ({

    query: "",
    time_search_last_closed: 0,
    view_mode: ViewModes.INTRO,
    view: {
        show_intro: true,
        show_top_search: false,
        show_search_results: false,
        show_menu: false,
        show_map: false,
        show_map_loading: false,
        filters: false,
        show_info: false,
        show_person_info_page:false,
        show_org_info_page:false,
        menu_closing: false,
        is_intro_search:false,
    },
    info_panel_node: undefined,
    info_panel_node_type: undefined,
    actions: {
        onLaunchSearch (q) {
            //console.log("onLaunchSearch: ", q);
            if (!q) {
                q = "";
            }
            get().actions.setQuery(q);
            get().actions.setViewMode(ViewModes.ACTIVE_SEARCH);
        },
        setQuery(q){
            set(state => {state.query = q});
        },
        setTimeSearchLastClosed(t){
            set(state => {state.time_search_last_closed = t});
        },
        setViewMode(new_view_mode) {

            //console.trace("setViewMode to: ", new_view_mode, "was:", get().view_mode  );

            if(new_view_mode === get().view_mode) {
                return;
            }
            let last_view_mode = get().view_mode;
            set(state => {state.view_mode = new_view_mode});

            let temp = {
                show_intro: false,
                show_top_search: false,
                show_search_results: false,
                show_menu: false,
                show_map: false,
                show_map_loading:false,
                filters: false,
                show_info:false,
                show_person_info_page:false,
                show_org_info_page:false,
                menu_closing:false,
                is_intro_search:false,
            }

            switch (new_view_mode) {
                case ViewModes.SEARCH:
                    // the search at the top of the main map screen
                    temp.show_top_search = true;
                    temp.show_map = true;
                    break;
                case ViewModes.ACTIVE_SEARCH:
                    // the search at the top of the main map screen with results
                    temp.show_top_search = true;
                    temp.show_map = true;
                    temp.show_search_results = true;
                    if(last_view_mode === ViewModes.INTRO){
                     temp.is_intro_search = true;
                    }
                    break;
                case ViewModes.MENU:
                    // the chair show_menu over the map
                    temp.show_map = true;
                    temp.show_menu = true;
                    break;
                case ViewModes.MENU_CLOSING:
                    // the chair show_menu over the map
                    temp.show_map = true;
                    temp.show_menu = false;
                    temp.menu_closing = true;
                    break;
                case ViewModes.MAP:
                    // exploring the show_map
                    temp.show_map = true;
                    break;
                case ViewModes.MAP_LOADING:
                    // load a saved map
                    temp.show_map_loading = true;
                    break;
                case ViewModes.FILTERS:
                    // opened the filters controls
                    temp.show_map = true;
                    temp.filters = true;
                    break;
                case ViewModes.INFO:
                    // the information panel about a node
                    temp.show_map = true;
                    temp.show_info = true;
                    break;
                case ViewModes.PERSON_INFO_PAGE:
                    // the information panel about a person rendered as a stand-alone page
                    temp.show_person_info_page = true;
                    break;
                case ViewModes.ORG_INFO_PAGE:
                    // the information panel about an org rendered as a stand-alone page
                    temp.show_org_info_page = true;
                    break;
                case ViewModes.INTRO:
                default:
                    // The show_intro screen - no show_map - has its own chair and search
                    temp.show_intro = true;
                    break;
            }
            Object.entries(temp).forEach( ([view_name, value]) => {
                if(get().view[view_name] !== value){
                    //console.log("changing ", view_name);
                    set((state)=>{state.view[view_name]= value});
                }
            })
        }
    }
});

const useViewStore = create(
    subscribeWithSelector(
        immer(viewStore),
    )
)

export default useViewStore;