import React from "react";
import "./Toggle.scss";

/*
Toggle Switch Component

Adapted - (almost wholesale) - from: https://codesandbox.io/s/react-toggle-component-de4uy?from-embed=&file=/src/components/ToggleSwitch.js:0-2041
Thanks: https://codesandbox.io/u/jameshibbard

Note: id, checked and onChange are required for ToggleSwitch component to function. The props name, small, disabled
and optionLabels are optional.
Usage: <ToggleSwitch id="id" checked={value} onChange={checked => setValue(checked)}} />
*/

const Toggle = ({
                          id,
                          name,
                          checked,
                          onChange,
                          small,
                          disabled,
                          labelText
                      }) => {
    function handleKeyPress(e) {
        if (e.keyCode !== 32 && e.code !== "Enter") return;
        e.preventDefault();
        onChange(!checked);
    }

    return (
        <div className={"toggle-wrap"}>
            <label htmlFor={id}>
        <div className={"toggle-switch" + (small ? " small-switch" : "")}>
            <input
                type="checkbox"
                name={name}
                className="toggle-switch-checkbox"
                id={id}
                checked={checked}
                onChange={(e) => onChange(e.target.checked)}
                onKeyPress={(e)=>{if(e.code === "Enter") {onChange(e.target.checked)}}}
                disabled={disabled}
            />
            {id ? (
                <label
                    className={"toggle-switch-label" + (disabled ? " disabled" : "")}
                    tabIndex={disabled ? -1 : 1}
                    onKeyDown={(e) => handleKeyPress(e)}
                    htmlFor={id}
                >
          <span
              className={
                  disabled
                      ? "toggle-switch-inner toggle-switch-disabled"
                      : "toggle-switch-inner"
              }
              tabIndex={-1}
          />
                    <span
                        className={
                            disabled
                                ? "toggle-switch-switch toggle-switch-disabled"
                                : "toggle-switch-switch"
                        }
                        tabIndex={-1}
                    />
                </label>
            ) : null}
        </div>
                {labelText}</label>
        </div>
    );
};


export default Toggle;
