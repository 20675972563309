import Chair from "../Chair";
import TopSearch from "../Searchbar/TopSearch";
import SearchSuggestions from "../Searchbar/SearchSuggestions";
import {SearchResultsLayouts} from "../Searchbar/SearchSuggestions/SearchSuggestions";
import {isMobile} from "react-device-detect";
import {ControlModes, Entities, Regions, Side, ViewModes} from "../../data/constants";
import useViewStore from "../../data/view_store";
import React, {useEffect, useState} from "react";
import MapAutoPlay from "./MapAutoPlay";
import Menu from "../Menu";
import useMapStore from "../../data/map_store";
import Toast from "../UI/Toast/Toast";
import {strings} from "../../data/strings";
import InfoPanel from "../InfoPanel/InfoPanel";
import TogglePanRotate from "../UI/TogglePanRotate";
import FlyoutPanel from "../UI/FlyoutPanel";


export default function MapOverlays (){

    const view_mode = useViewStore((state) => state.view_mode);
    const query = useViewStore((state) => state.query);
    const {setViewMode, setQuery, setTimeSearchLastClosed } = useViewStore((state) => state.actions);
    const {show_top_search, menu_closing, show_menu, is_intro_search } = useViewStore((state) => state.view);
    const {stopAutoPlay} = useMapStore((state) => state.actions);
    const [showSavedMapInfoToast, setShowSavedMapInfoToast] = useState(useMapStore.getState().saved_map_loaded);
    const [showUnconnectedDirectorToast, setShowUnconnectedDirectorToast] = useState(useMapStore.getState().unconnected_director);
    const [showMapControlsToast, setShowMapControlsToast] = useState(false);
    const [mapControlsToastBody, setMapControlsToastBody] = useState("");
    const [unconnectedDirectorName, setUnconnectedDirectorName] = useState(useMapStore.getState().unconnected_director);
    const [savedMapToastBody, setSavedMapToastBody ] = useState("");
    const [showFirstNodeToast, setShowFirstNodeToast] = useState(useMapStore.getState().first_node_added);
    //console.log("rerender MapOverlays: ", useMapStore.getState().saved_map_loaded);
    //

    /**
     * Show toast after adding the first node
     */
    useEffect(()=>{
        const unsub = useMapStore.subscribe(
            (state) => (state.first_node_added),
            () => {
                //console.log("MapOverlays first node added: ", useMapStore.getState().first_node_added);
                setShowFirstNodeToast(useMapStore.getState().first_node_added !== "");
            }
        )
        return () => {
            // Clean up the subscription
            unsub();
        };
    }, [])


    /**
     * Show toast when a user tries to expand an unconnected dir
     * Listen for changes in map store for unconnected_director
     * Then show the Toast
     */
    useEffect(()=>{
        const unsub = useMapStore.subscribe(
            (state) => (state.unconnected_director),
            () => {
                //console.log("MapOverlays unconnected_director: ", useMapStore.getState().unconnected_director);
                setShowUnconnectedDirectorToast(useMapStore.getState().unconnected_director !== "");
                if(useMapStore.getState().unconnected_director !== "") {
                    setUnconnectedDirectorName(useMapStore.getState().unconnected_director);
                }
            }
        )
        return () => {
            // Clean up the subscription
            unsub();
        };
    }, [setUnconnectedDirectorName])


    /**
     * Show toast when a user toggles from manual to auto controls for pan and rotate
     */
    useEffect(()=>{
        const unsub = useMapStore.subscribe(
            (state) => (state.is_auto_control_mode),
            () => {
                setShowMapControlsToast(true);
                setMapControlsToastBody(
                useMapStore.getState().is_auto_control_mode?strings.PanRotateAutoModeEnabled:
                    strings.PanRotateAutoModeDisabled.replace("*mode*", useMapStore.getState().control_mode === ControlModes.PAN?strings.pan:strings.rotate));
            }
        )
        return () => {
            // Clean up the subscription
            unsub();
        };
    }, [setShowMapControlsToast])


    /**
    SHOW SAVED MAP TOAST
    Listen for changes in map store saved_map_loaded
    **/
    useEffect(()=>{
        const setInfo = () => {
            let savedMapId = useMapStore.getState().loaded_saved_map_id;

            //console.log("JJ MapOverlays setInfo savedMapId: ", savedMapId);

            if(savedMapId !== undefined) {
                let loadedMapData = useMapStore.getState().loaded_saved_map_data_set_info[useMapStore.getState().loaded_saved_map_id];
                let dsInfo = loadedMapData.dataSetInfo;
                let dsAddOnInfo = Object.entries(loadedMapData.dataAddOns);
                // console.log("ds loadedMapData: ", loadedMapData);
                // console.log("dsAddOnInfo:", dsAddOnInfo);
                // console.log("dsInfo: ", dsInfo);
                let createdArr = loadedMapData.date_created.split("/");
                let createdString = strings["month" + createdArr[0] ]+ ", " + createdArr[2];
                let summaryText = strings.savedMapDataIntro.replace("*created*", createdString)
                    .replace("*total_orgs*", dsInfo.total_orgs)
                    .replace("*region*", dsInfo.region.toUpperCase())
                    .replace("*org_type*", dsInfo.org_type)
                    .replace("*year*", dsInfo.year)
                    .replace("*criteria*", dsInfo.criteria);

                let sourceArray = [dsInfo.source[0].toUpperCase() + dsInfo.source.slice(1)];

                let addOnBulletsArray = [];
                let addOnBullets = "";

                //console.log("dsAddOnInfo.length:", dsAddOnInfo.length);

                if(dsAddOnInfo.length > 0){
                    // Show Recent Admins?
                    let hasRecentAdmins = dsAddOnInfo.findIndex( (e) => { return (e[0] === "presidential_admins" && e[1].include === true)} ) !== -1;

                    //console.log("hasRecentAdmins: ", hasRecentAdmins);

                    if(hasRecentAdmins) {
                        addOnBulletsArray.push (strings.savedMapAdminBulletPoint);
                    }
                    // Other
                    dsInfo.add_ons.forEach((addOn) => {
                        // console.log("ds addOn: ", addOn);
                        let includeThisAddOn = dsAddOnInfo.findIndex( (e) => { return (e[0] === addOn.id && e[1].include === true)} ) !== -1;
                         if(includeThisAddOn) {
                             addOnBulletsArray.push(strings.savedMapAddOnBulletPoint.replace("*total_orgs*", addOn.total_orgs)
                                 .replace("*region*", addOn.region.toUpperCase())
                                 .replace("*type*", strings[addOn.type])
                                 .replace("*criteria*", strings[addOn.criteria])
                                 .replace("*criteria_year*", addOn.criteria_year)
                                 .replace("*source*", (addOn.source[0][0].toUpperCase() + addOn.source[0].slice(1)))
                             );
                         }
                    });

                    //console.log("ds addOnBulletsArray: ", addOnBulletsArray);

                    addOnBullets = "";
                    if(addOnBulletsArray.length === 1){
                        addOnBullets = strings.andSeparator + addOnBulletsArray[0] + ".";
                    } else if (addOnBulletsArray.length > 1) {
                        addOnBullets = strings.commaSeparator + addOnBulletsArray.slice(0,-1).join(strings.commaSeparator) + strings.andSeparator + addOnBulletsArray.slice(-1) + "."
                    };
                }

                let sourceStr = "";
                if(sourceArray.length === 1){
                    sourceStr = strings.savedMapSource.replace("*sources*", sourceArray[0]);
                } else {
                    sourceStr = strings.savedMapSources.replace("*sources*", sourceArray.split(", "));
                }

                setSavedMapToastBody(<>{summaryText}{addOnBullets} {sourceStr}</>);
            }
        }

        const unsubTrigger = useMapStore.subscribe(
            (state) => (state.saved_map_loaded),
            () => {
                setInfo();
                setShowSavedMapInfoToast(useMapStore.getState().saved_map_loaded);
            }
        )

        //console.log("MapOverlays setInfo");
        setInfo();

        return () => {
            // Clean up the subscription
            unsubTrigger();
        };
    }, [setShowSavedMapInfoToast])

    // const onMouseOverTopbar = () => {
    //     console.log("mapOverlays onMouseOverTopbar view mode: ", view_mode);
    //     if (isMobile) {
    //         return;
    //     }
    //     if (Date.now() - time_search_last_closed > 200
    //         && view_mode !== ViewModes.ACTIVE_SEARCH
    //         && view_mode !== ViewModes.SEARCH
    //         && view_mode !== ViewModes.MENU
    //         && view_mode !== ViewModes.INFO
    //     ) {
    //         console.log("mapOverlays onMouseOverTopbar setViewMode(ViewModes.SEARCH)");
    //         setViewMode(ViewModes.SEARCH);
    //     }
    // }


    const onDesktopSearchIconClicked = () => {
        setViewMode(ViewModes.SEARCH);
    }

    const onMouseOutOverlays = () => {
        if (view_mode !== ViewModes.ACTIVE_SEARCH
            && view_mode !== ViewModes.MENU
            && view_mode !== ViewModes.INFO
        ) {
            setTimeSearchLastClosed(Date.now());
            setViewMode(ViewModes.MAP);
            setQuery("");
        }
    }

    const onChairClicked = (e) => {
        // console.log("onChairClicked", e);
        setShowSavedMapInfoToast(false);
        setViewMode(view_mode === ViewModes.MENU?ViewModes.MAP:ViewModes.MENU);
        stopAutoPlay();
    }


    useEffect(() => {
        if (query !== "") {
            setViewMode(ViewModes.ACTIVE_SEARCH);
        }
    }, [query, setViewMode])


    let overlaysProps = {
        className: "overlays desktop",
        // onMouseOver: onMouseOverTopbar,
        // onMouseLeave: onMouseOutOverlays
    };

    if (isMobile) {
        overlaysProps = {className: "overlays mobile"};
    }

    const [searchInputEl, setSearchInputEl] = useState(null);

    const focusSearch = () => {
        //console.log("searchInputEl: ", searchInputEl);
        searchInputEl.focus();
    }

    const onCloseSearchSuggestions = () => {
        setViewMode(ViewModes.MAP);
        setQuery("");
    }

    const onCloseMenu = () => {
        //console.log("onCloseMenu");
        setViewMode(ViewModes.MENU_CLOSING)
    }



    return (
    <div{...overlaysProps} >
        <div className={"overlay-content"}>

            <header>


                <FlyoutPanel isOpen={show_menu}
                             onClose={onCloseMenu}
                             onRestOfPanelClose={()=>{setViewMode(ViewModes.MAP)}}
                             width={isMobile?"100vw":"40em"}
                             show_close_arrow={false}
                             openFrom={Side.LEFT}
                             useFocusTrap={true}
                >
                    <Menu onClose={onCloseMenu} />
                </FlyoutPanel>

                <FlyoutPanel isOpen={show_top_search}
                             onClose={onCloseSearchSuggestions}
                             width={isMobile?"100vw":"80vw"}
                             show_close_arrow={isMobile?false:true}
                             open_with_no_animation={isMobile?(is_intro_search?true:false):false}
                             focusCloseButtonOnOpen = {false}
                >
                    <div>
                        <SearchSuggestions
                            query={query}
                              setViewMode = {setViewMode}
                              // showResults={show_search_results}
                              showResults={true}
                              onClose = {onCloseSearchSuggestions}
                              layout={SearchResultsLayouts.HORIZONTAL}
                              maxColLength={5} focusSearch={focusSearch}
                        />
                    </div>
                </FlyoutPanel>


                <Chair screen={show_menu &! menu_closing ?"menu":"top-search"} onChairClicked={onChairClicked}/>

                {!show_menu &&<section className={["top-rhs-wrap", isMobile?"mobile":""].join(" ")}>
                    <div className={["spacer-left", (show_top_search?"search-expanded":"")].join(" ")} />
                     <TopSearch show={show_top_search} setSearchInputEl={setSearchInputEl} onDesktopSearchIconClicked={onDesktopSearchIconClicked} />
                    <div className={["spacer-right", (show_top_search?"search-expanded":""), (isMobile?"mobile":"")].join(" ")} />
                    {!show_top_search && <TogglePanRotate /> }
                </section>}



            </header>

            <InfoPanel />
            <MapAutoPlay />
            <section className={"toasts"}>

                {/*TODO: build a toasts widget and Zustand controller for it*/}

                <Toast
                    show={showSavedMapInfoToast}
                    onClose={()=>{setShowSavedMapInfoToast(false)}}
                    title={strings.savedMapToastTitle}
                    body={savedMapToastBody}
                    duration={showSavedMapInfoToast?12000:null}
                />

                <Toast
                    show={showMapControlsToast}
                    onClose={()=>{setShowMapControlsToast(false)}}
                    body={mapControlsToastBody}
                    duration={showMapControlsToast?6000+mapControlsToastBody.length:null}
                />

                <Toast
                    show={showUnconnectedDirectorToast}
                    onClose={()=>{useMapStore.setState({unconnected_director: ""})}}
                    body={strings.unconnectedDirector.replace("*director name*", unconnectedDirectorName)}
                    duration={showUnconnectedDirectorToast?6000+unconnectedDirectorName.length:null}
                />

                <Toast
                    show={showFirstNodeToast}
                    onClose={()=>{useMapStore.setState({first_node_added: ""})}}
                    body={strings.firstNodeToastCopy.replace("*node_type*", useMapStore.getState().first_node_added === Entities.ORG? "company":"director")
                        .replace("*num_comps*", useMapStore.getState().datasetInfo.total_orgs)
                        .replace("*region*", useMapStore.getState().datasetInfo.region.toUpperCase())}
                    duration={showFirstNodeToast?30000:null}
                />

            </section>

        </div>
    </div>
    )
}