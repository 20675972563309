import React, {useEffect, useState} from "react";
import "./InfoPanel.scss";
import {isMobile} from "react-device-detect";
import {strings} from "../../data/strings";

function InfoPanelPerson({wikidataID, isStandAlonePage}) {

    const [personName, setPersonName] = useState(undefined);
    const [imageURL, setImageURL] = useState(undefined);
    const [alias, setAlias] = useState(undefined);
    const [itemDesc, setItemDesc] = useState(undefined);
    const [gender, setGender] = useState(undefined);
    const [age, setAge] = useState(undefined);
    const [birthYear, setBirthYear] = useState(undefined);
    const [deathYear, setDeathYear] = useState(undefined);
    const [birthPlace, setBirthPlace] = useState(undefined);
    const [website, setWebsite] = useState(undefined);
    const [education, setEducation] = useState(undefined);
    const [cspan, setCspan] = useState(undefined);
    const [facebook, setFacebook] = useState(undefined);
    const [freebase, setFreebase] = useState(undefined);
    const [googleNews, setGoogleNews] = useState(undefined);
    const [IMDB, setIMDB] = useState(undefined);
    const [instagram, setInstagram] = useState(undefined);
    const [libraryThing, setLibraryThing] = useState(undefined);
    const [linkedIn, setLinkedIn] = useState(undefined);
    const [littleSis, setLittleSis] = useState(undefined);
    const [NYT, setNYT] = useState(undefined);
    const [reddit, setReddit] = useState(undefined);
    const [TED, setTED] = useState(undefined);
    const [twitter, setTwitter] = useState(undefined);
    const [youTube, setYouTube] = useState(undefined);
    const [wikipedia, setWikipedia] = useState(undefined);
    const [wikipediaSummary, setWikipediaSummary] = useState(undefined);
    const [useWikipediaSummary, setUseWikipediaSummary] = useState(true);
    const [LOC, setLOC] = useState(undefined);
    const [hasInfoSite, setHasInfoSite] = useState(false);
    const [pageLink] = useState(window.location.origin + "/person/" + wikidataID );

    const infoSites = {
        P10660: {g: cspan, s: setCspan, str: "cspan"},
        P2013: {g: facebook, s: setFacebook, str: "facebook"},
        P646: {g: freebase, s: setFreebase, str: "freebase"},
        P5337: {g: googleNews, s: setGoogleNews, str: "googleNews"},
        P345: {g: IMDB, s: setIMDB, str: "IMDB"},
        P2003: {g: instagram, s: setInstagram, str: "instagram"},
        P244: {g: LOC, s: setLOC, str: "libraryOfCongress"},
        P7400: {g: libraryThing, s: setLibraryThing, str: "libraryThing"},
        P6634: {g: linkedIn, s: setLinkedIn, str: "linkedIn"},
        P3388: {g: littleSis, s: setLittleSis, str: "littleSis"},
        P3221: {g: NYT, s: setNYT, str: "NYT"},
        P4265: {g: reddit, s: setReddit, str: "reddit"},
        P2611: {g: TED, s: setTED, str: "TED"},
        P2002: {g: twitter, s: setTwitter, str: "twitter"},
        P2397: {g: youTube, s: setYouTube, str: "youTube"},
    }

    const searchableSites = [

        {
            site: "Common Dreams",
            searchURL: 'https://www.commondreams.org/search?keywords="*id*"',
        },
        {
            site: "Democracy Now!",
            searchURL: 'https://www.democracynow.org/search?query="*id*"&commit=Search',
        },
        {
            site: "Eyes on the Ties",
            searchURL: "https://news.littlesis.org/?s='*id*'&submit=Search"
        },
        {
            site: "The Guardian",
            searchURL: 'https://www.google.com/search?q=site%3Ahttps%3A%2F%2Fwww.theguardian.com+"*id*"'
        },
        {
            site: "Jacobin",
            searchURL: "https://jacobin.com/search?query=*id*",
        },
        {
            site: "LittleSis",
            searchURL: 'https://littlesis.org/search?q="*id*"',
        },
        {
            site: "The Nation",
            searchURL: "https://www.thenation.com/?s='*id*'"
        },
        {
            site: "ProPublica",
            searchURL: 'https://www.propublica.org/search?qss="*id*"',
        },
        {
            site: "Reveal",
            searchURL: 'https://revealnews.org/?s="*id*"',
        }

    ];

    class SPARQLQueryDispatcher {
        constructor(endpoint) {
            this.endpoint = endpoint;
        }

        query(sparqlQuery) {
            const fullUrl = this.endpoint + '?query=' + encodeURIComponent(sparqlQuery);
            const headers = {'Accept': 'application/sparql-results+json'};

            return fetch(fullUrl, {headers}).then(body => body.json());
        }
    }

    // TODO: use education query, update basic query
    // Edu: https://w.wiki/5TYN
    // Basic details: https://w.wiki/5TrV
    // More info: https://w.wiki/5TYS

    const endpointUrl = 'https://query.wikidata.org/sparql';

    // wikidataID = "Q5284"; // force it to be Bill Gates for testing
    // wikidataID = "Q5402996" ; // force it to be someone with a lot of edu info for testing
    // wikidataID = "Q19837"; // force to Steve Jobs to test handling deceased people

    const sparqlQueryPersonData = `PREFIX schema: <http://schema.org/>
PREFIX wikibase: <http://wikiba.se/ontology#>
PREFIX wd: <http://www.wikidata.org/entity/>
PREFIX wdt: <http://www.wikidata.org/prop/direct/>

SELECT ?itemLabel 
(GROUP_CONCAT(DISTINCT ?altNames; SEPARATOR = ";") AS ?aliases) 
?itemDesc 
?genderLabel 
?birthday 
?dateOfDeath
?placeOfBirthLabel 
?website 
?image 
?wikipedia

WHERE {
  VALUES ?item {
    wd:`+wikidataID+`
  }
  OPTIONAL {
    ?item skos:altLabel ?altNames.
    FILTER((LANG(?altNames)) = "en")
  }
  {
    OPTIONAL { ?item wdt:P21 ?gender. }
    OPTIONAL { ?item wdt:P569 ?birthday. }
    OPTIONAL { ?item wdt:P570 ?dateOfDeath. }
    OPTIONAL { ?item wdt:P19 ?placeOfBirth. }
    OPTIONAL { ?item wdt:P18 ?image. }
    OPTIONAL { ?item wdt:P856 ?website. }
    SERVICE wikibase:label { bd:serviceParam wikibase:language "en". }
  }
  SERVICE wikibase:label {
    bd:serviceParam wikibase:language "en".
    ?item schema:description ?itemDesc.
  }
  OPTIONAL {
      ?wikipedia schema:about ?item .
      ?wikipedia schema:inLanguage "en" .
      ?wikipedia schema:isPartOf <https://en.wikipedia.org/> .
  }
}
GROUP BY ?itemLabel 
?itemDesc 
?genderLabel 
?birthday
?dateOfDeath
?image 
?placeOfBirthLabel 
?website 
?wikipedia`;

    const sparqlQueryPersonMoreInfo  = `SELECT ?property ?propertyLabel ?value WHERE {    ?property wikibase:propertyType wikibase:ExternalId .    ?property wikibase:directClaim ?propertyclaim .   OPTIONAL {?property wdt:P1630 ?formatterURL .}   wd:`+wikidataID+` ?propertyclaim ?_value .    BIND(IF(BOUND(?formatterURL), IRI(REPLACE(?formatterURL, "\\\\\$1", ?_value)) , ?_value) AS ?value) SERVICE wikibase:label { bd:serviceParam wikibase:language "en". }                                     }`;

    const sparqlQueryPersonEducation = `PREFIX schema: <http://schema.org/>
PREFIX wikibase: <http://wikiba.se/ontology#>
PREFIX wd: <http://www.wikidata.org/entity/>
PREFIX wdt: <http://www.wikidata.org/prop/direct/>

SELECT ?itemLabel ?eduLabel ?edStart ?edEnd 
(GROUP_CONCAT(DISTINCT ?edDegrees; SEPARATOR = "|") AS ?degrees) 
(GROUP_CONCAT(DISTINCT ?edMajors; SEPARATOR = "|") AS ?majors) 
WHERE {
  VALUES ?item {
    wd:`+wikidataID+`
  }
  SERVICE wikibase:label { bd:serviceParam wikibase:language "en". }
  {
    OPTIONAL {
      ?item p:P69 ?statement.
      ?statement (ps:P69/rdfs:label) ?eduLabel.
      FILTER((LANG(?eduLabel)) = "en")
      OPTIONAL { ?statement pq:P580 ?edStart. }
      OPTIONAL { ?statement pq:P582 ?edEnd. }
      OPTIONAL {
        ?statement (pq:P512/rdfs:label) ?edDegrees.
        FILTER((LANG(?edDegrees)) = "en")
      }
      OPTIONAL {
        ?statement (pq:P812/rdfs:label) ?edMajors.
        FILTER((LANG(?edMajors)) = "en")
      }
    }
  }
}
GROUP BY ?itemLabel ?eduLabel ?edStart ?edEnd `;

   const getWikipediaSummary =(wikipediaURL) => {
       //console.log("Wikipedia value", wikipediaURL);
       let pageTitle = wikipediaURL.split("/").pop();
       let fullUrl = "https://en.wikipedia.org/w/api.php?format=json&action=query&origin=*&prop=extracts&exintro&redirects=1&titles=" + pageTitle;
       // let headers = {'Accept': 'application/sparql-results+json'};
       return fetch(fullUrl).then(body => body.json());
   }


    const onPersonDataLoaded = (result) => {
        //console.log("sparqlQueryPersonData: ", sparqlQueryPersonData);
        //console.log("result: ", result);
        let data = result.results.bindings[0];
        if(!data) return;
       // console.log("data: ", data);
        setPersonName(data["itemLabel"].value);
        //console.log("data[\"itemLabel\"].value: ", data["itemLabel"].value);

        if(data.image && data.image.value){
            setImageURL(data.image.value);
        }
        if(!!data.aliases && !!data.aliases.value){
            setAlias(data.aliases.value.split(";").reduce(
                function (a, b) {
                    return a.length > b.length ? a : b;
                }));
        }

        if(data.genderLabel && data.genderLabel.value){
            setGender(data.genderLabel.value);
        }

        if(data.birthday && data.birthday.value){
            setAge(Math.floor((Date.now() - new Date(data.birthday.value))/31556926000));
            setBirthYear(new Date(data.birthday.value).getFullYear());
        }

        if(data.dateOfDeath && data.dateOfDeath.value){
            setDeathYear(new Date(data.dateOfDeath.value).getFullYear());
        }

        if(data.itemDesc && data.itemDesc.value){
            setItemDesc(data.itemDesc.value);
        }

        if(data.website && data.website.value){
            setWebsite(data.website.value);
        }

        if(data.wikipedia && data.wikipedia.value){
            getWikipediaSummary(data.wikipedia.value).then(onWikipediaSummaryLoaded)
            setWikipedia(data.wikipedia.value);
        } else {
            setUseWikipediaSummary(false); // will use the wikidata summary
        }

        if(data.placeOfBirthLabel && data.placeOfBirthLabel.value){
            setBirthPlace(data.placeOfBirthLabel.value);
        }

    }

    const onWikipediaSummaryLoaded=(result)=>{
       //console.log("onWikipediaSummaryLoaded: ", result);
       if(result && result.query && result.query.pages){
           let pages = Object.values(result.query.pages);
           if(pages && pages[0] && pages[0].extract){
               setWikipediaSummary(pages[0].extract);
           } else {
               setUseWikipediaSummary(false);
           }
       } else {
           setUseWikipediaSummary(false);
       }
    }


    const onPersonEducationLoaded = (result) => {
        let data = result.results.bindings;
        //console.log("eee ed data: ", data, data.length);
        if (!data) return;
        let eduObj = {};
        let hasEducationInfo = false;
        let key, startDate, endDate;
        data.forEach((s)=>{
            if(!!s.eduLabel && !!s.eduLabel.value) {
                hasEducationInfo = true;
                if (!!s.edStart && !!s.edStart.value) startDate = new Date(s.edStart.value).getFullYear()
                if (!!s.edEnd && !!s.edEnd.value) endDate = new Date(s.edEnd.value).getFullYear()
                key = s.eduLabel.value.split(" ").join("");
                if (!!endDate) {
                    key += "_" + endDate;
                }
                if (!!!eduObj[key]) {
                    eduObj[key] = {};
                }
                if (!!startDate) eduObj[key].start = startDate;
                if (!!endDate) eduObj[key].end = endDate;
                if (!!s.eduLabel) eduObj[key].school = s.eduLabel.value;
                if (!!s.majors && !!s.majors.value) eduObj[key].majors = s.majors.value.split("|").map((m) => {
                    return (m[0].toUpperCase() + m.slice(1))
                });
                if (!!s.degrees && !!s.degrees.value) eduObj[key].degrees = s.degrees.value.split("|");
            }
        })

        //console.log("eee eduObj: ", eduObj);

        if(hasEducationInfo) { setEducation(eduObj); };

    }


    const onPersonMoreInfoLoaded = (result) => {
        //console.log("mi sparqlQueryPersonMoreInfo: ", sparqlQueryPersonMoreInfo);
        //console.log("mi result: ", result);
        let data = result.results.bindings;
        if (!data) return;
        //console.log("mi data: ", data);
        let miId, miLink;
        data.forEach((mi)=>{
            miId = mi.property.value.split("http://www.wikidata.org/entity/")[1];
            miLink = mi.value.value;
            //console.log("mi " , miId, miLink);
            if(infoSites[miId]){
                //console.log("mi FOUND ", );
                infoSites[miId].s(miLink);
                setHasInfoSite(true);
            }
        })
    }


    const queryDispatcher = new SPARQLQueryDispatcher(endpointUrl);

    useEffect(() => {
        queryDispatcher.query(sparqlQueryPersonData).then(onPersonDataLoaded);
        queryDispatcher.query(sparqlQueryPersonMoreInfo).then(onPersonMoreInfoLoaded);
        queryDispatcher.query(sparqlQueryPersonEducation).then(onPersonEducationLoaded);
    },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [wikidataID])


    return <div className={"info-data-person-wrap info-data-content-wrap" + (isMobile ? " mobile" : "")}>
        <div className={"info-header" + (isMobile ? " mobile" : "")}>
            {!!imageURL && <div className={"info-image"} style={{backgroundImage: "url(" + imageURL +")"}} />}
            <div className={"name-gender-age-box"}>
                {!!personName && <div className={"info-title"}>{ personName }</div>}
                {!!alias && <div className={"info-alias"}>{ alias }</div>}
                {!!gender && <span className={"info-gender"}>{ gender }</span>}
                {!!gender && !!age && <span>, </span>}
                {!!deathYear && !!birthYear && <span>{birthYear}-{deathYear}.</span>}
                {!!deathYear && !birthYear && <span>died {deathYear}.</span>}
                {!deathYear && !!age && <span>{age}</span>}
            </div>
        </div>

        <div className={"info-content"}>

            {!useWikipediaSummary && !!itemDesc && <div className={"info-section"}>{ itemDesc }</div>}

            {useWikipediaSummary && !!wikipediaSummary && <div className={"info-section"}><div className="wikipedia-summary" dangerouslySetInnerHTML={{__html:wikipediaSummary}} /> &mdash;  <a href={wikipedia} target={"_blank"} rel="noreferrer">{ strings.wikipedia }</a><br/><br/></div>}

            {(!!website || !!wikipedia) && <div className={"info-section"}>
                {!!website && <a href={website} target={"_blank"} rel="noreferrer">{ strings.officialWebsite }</a>}
                {!!website && !!wikipedia && !useWikipediaSummary && <span> | </span>}
                {!useWikipediaSummary && !!wikipedia && <a href={wikipedia} target={"_blank"} rel="noreferrer">{ strings.wikipediaEnglish }</a>}
            <br/><br/></div>}
            {!!birthPlace && <div className={"info-section name-value"}>
                <div className={"info-name"}>{strings.placeOfBirth}</div>
                <div className={"info-value"}> { birthPlace }</div>
            </div>}
            {!!education && <div className={"info-section name-value"}>
                <div className={"info-name"}>{strings.education}</div>
                <div className={"info-value"}>
                    {Object.values(education).map((v, i)=>{
                        return(<div key={v+i} className={"info-school"}>
                            <div className={"info-school-name"}>{v.school}{!!v.end && <span className={"info-period"}> (
                                {!!v.start && (v.start + " -  ")}
                                {v.end}
                            )</span>}</div>
                            <ul className={"info-edu-info"}>
                                {!!v.degrees && <li>{v.degrees.join(", ")}</li>}
                                {!!v.majors && <li className={"info-majors"}>{v.majors.join(", ")}</li>}
                            </ul>
                            </div>)
                    })}
                </div>
            </div>}

            {!!hasInfoSite &&<div className={"info-section name-value"}>
                <div className={"info-name"}>{strings.moreInfo}</div>
                <div className={"info-value"}>
            {
                Object.values(infoSites).map((info,i) => {
                    return (<div key={"mi" + i} >
                        {!!info.g && <div className={"more-info-link"}><a href={info.g} target={"_blank"} rel="noreferrer">{strings[info.str]}</a></div>}</div>
                    )
                })
            }
                </div>
            </div>
            }

            {!!personName && <div className={"info-section"}>
                <div className={"info-section name-value"}>
                    <div className={"info-name"}>{strings.searchMore}</div>
                    <div className={"info-value"}>
                        {searchableSites.map((s, i) => {
                            let id = personName.replace(" ", "+");
                            return (<div key={"s" + i} className={"more-info-link"}><a href={s.searchURL.replace("*id*", id)} target={"_blank"} rel="noreferrer">{s.site}</a></div>)
                        })}
                    </div>
                </div>

            </div>}

            <br/>
            <br/>

            <div className={"info-section name-value"}>
                <div className={"info-name wikidata-attribution"}>
                    <a className={"wikidata-attribution"} href={"https://www.wikidata.org/wiki/" + wikidataID}
                       target={"_blank"} rel="noreferrer">
                        <div className={"wikidata-image"} alt="Wikidata-logo-en"/>
                    </a>
                </div>
                <div className={"info-value"}>{strings.usingWikidataAndAddInfo}<a href={"https://www.wikidata.org/wiki/" + wikidataID } target={"_blank"} rel="noreferrer">{ personName }</a>.</div>
            </div>

            <div className={"info-section fine-print info-link"} >
                Link to this page: <a href={pageLink} target={"_blank"} rel="noreferrer">{pageLink}</a>
            </div>

        </div>
    </div>
}

export default InfoPanelPerson;