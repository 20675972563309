import React, {useRef, useEffect, useState, Suspense, useLayoutEffect} from "react";
import {DataSetSource, Entities, ViewModes} from "../data/constants";
import * as THREE from "three";
import {useSpring} from "@react-spring/three";
import {useGesture} from "@use-gesture/react";
import {Html, Instance} from "@react-three/drei";
import {useThree} from "@react-three/fiber";
import useMapStore from "../data/map_store";
import {isMobile} from "react-device-detect";
import useViewStore from "../data/view_store";
import {strings} from "../data/strings";
import {Icons} from "./Icons/Icon";
import Icon from "./Icons";


export default function NodeIcon({node, instance_key}) {

    let planeIntersectPoint = new THREE.Vector3();
    const ref = useRef();
    const {setIsDragging, setCameraTarget, nodeClicked, updateNodePosition,updateNodeTargetPosition, setSelectedNode, onRestRemoveIfRequired, clearDragParent } = useMapStore((state) => state.actions);
    const [localIsDragging, setLocalIsDragging] = useState(false);
    const [hovered, setHovered] = useState(false);
    const [isSelected, setIsSelected] = useState(false);
    const [isInfoSelected, setIsInfoSelected] = useState(false);
    const [isDeleteSelected, setIsDeleteSelected] = useState(false);
    const [name, setName] = useState(false);
    const [removeOnRest, ] = useState((false));
    const [lastTar, setLastTar] = useState(undefined);
    const color = new THREE.Color();
    const {Orgs, Dirs} = useMapStore((state) => state.dataset);
    const floorPlane = useMapStore((state) => state.floor_plane);
    const {setViewMode } = useViewStore((state) => state.actions);
    const { invalidate } = useThree()
    const canExpand = useMapStore.getState().actions.isExpandable(instance_key);
    const usingWikidata = useMapStore.getState().datasetInfo.source === DataSetSource.WIKIDATA;
    const htmlFocusElRef= useRef();

    /**
     * Listen for the parent being dragged
     */
    useEffect(()=> {

        let unsub = () => {};
        if (node.nType === Entities.DIR) {
            setName(Dirs[node.qid].n);
            // this is a director - if they have just been opened from a company, sometimes they will get dragged along with the company, so we should remember the offset and listen for  a drag
            if(node.dragParent) {
                let dragParentPos = useMapStore.getState().node_positions[node.dragParent];
                let tar = useMapStore.getState().node_target_positions[node.qid];
                // console.log("The director " + Dirs[node.qid].n + " dragParent: " + node.dragParent, node.tarX, node.tarZ);
                const offset = {
                    x: tar.x - dragParentPos[0],
                    z: tar.z - dragParentPos[2]
                };
                unsub = useMapStore.subscribe(
                    (state) => (state.node_positions[node.dragParent]),
                    (args)=>{parentMoved(args, offset)}
                )
            }
        }
        if (node.nType === Entities.ORG) {
            setName(Orgs[node.qid].n);
        }

        // rotate the org table when added
        r_api.start({
            from: {
                rotation: [0, 3, 0]
            }, to: {
                rotation: [0, 5, 0],
            },
            config: {friction: 200},
        });


        return () => {
            unsub();
        }
    },
        // Because I don't want to include Orgs and Dirs and have this fire each time they change...:
        // eslint-disable-next-line react-hooks/exhaustive-deps
    [node.nType, node.qid, node.dragParent]);

    /**
     * Changes the selection state after it has been changed in the map store
     * @param bool
     */
    const onSelected = (bool) => {
        setIsSelected(bool);
        if (ref.current){
            ref.current.color = color.set(bool ? 0x666666 : canExpand?0xFFFFFF : 0xFFFFFF);
            color.convertLinearToSRGB(); // Hopefully fixes this bug:  https://github.com/pmndrs/react-three-fiber/discussions/1954
        }
        if(!bool){
            setIsDeleteSelected(false);
        }
    }

    /**
     Listen for select and unselect changes from the mapStore
     */
    useLayoutEffect(()=>{
        const unsub = useMapStore.subscribe((state) => (state.node_selected_states[node.qid]), onSelected);
        // call this the first time if it is selected
        if(useMapStore.getState().node_selected_states[node.qid]) {
            onSelected(true);
        } else {
            onSelected(false);
        }
        // console.log("3 ref.current: ", name, ref.current, "color: ", ref.current.color);
        // console.log("3 ref.current: ", name, "ref.current.parent.material.color: ", ref.current.parent.material.color);
        return () => {
            // Clean up the subscription
            unsub();
        };
    }, [])

    /**
     * Position spring animation
     */
    const [spring, api] = useSpring(() => (
        {
        position: [node.startX, 0, node.startZ],
        config: {friction: 15},
        onChange: (props) => {
            updateNodePosition(node, props.value.position);
            ref.current.position.x = props.value.position[0];
            ref.current.position.y = 0;
            ref.current.position.z = props.value.position[2];
        },
        onRest: () => {
            onRestRemoveIfRequired(node.qid, node.nType, instance_key);
        }
    }));

    /**
     * Rotation spring animation
     */
    const [ ,r_api] = useSpring(() => ({
        rotation: [0, 0, 0],
        config: {friction: 50},
        onChange: (props) => {
            ref.current.rotation.y = props.value.rotation[1];
        }
    }));

    /**
     * Listen for target position changes in the mapStore for this node
     */
    useEffect(()=>{

        const targetChanged = (tar) => {
            if(!tar) {
                return;
            }
            if (tar.remove_on_rest) {
                api.start({
                    to: {position: [tar.x, 0, tar.z]},
                    config: {friction: 20, bounce:0}
                });
                setName("");
            } else {
                api.start({
                    to: { position: [Math.round(tar.x), 0, Math.round(tar.z)]}
                });
            }

            if(node.nType === Entities.DIR) {
                let r = getRotation([node.startX, 0, node.startZ],[tar.x, 0, tar.z])
                r_api.start({
                    rotation: [0, r, 0]
                });
            }
        }


        const unsub = useMapStore.subscribe((state) => (state.node_target_positions[node.qid]), targetChanged)

        // if this it not the first node call this the first time if it has a target
        if(Object.keys(useMapStore.getState().node_positions).length > 1) {
            let tar = useMapStore.getState().node_target_positions[node.qid];
            if (tar && tar !== lastTar) {
                setLastTar(tar);
                targetChanged(tar);
            }
        }
        return () => {
            // Clean up the subscription
            unsub();
        };
    }, [node.qid, lastTar, api, node.nType, node.startX, node.startZ, r_api])

    /**
     * Listen for mouse, touch interactions
     * @type {void | ((...args: any[]) => import("@use-gesture/core/types").ReactDOMAttributes)}
     */
    const bind = useGesture({
            onDragStart: ({cancel})=>{
                //console.log("#Drag onDragStart");
                if(!!useMapStore.getState().is_pinching){
                    cancel();
                    return;
                }
            },
            onDrag: ({active, movement: [x, y], timeStamp, event, first, last,cancel, distance, elapsedTime}) => {
                //console.log("#Drag onDrag");
                if(!!useMapStore.getState().is_pinching){
                    cancel();
                    return;
                }

                event.ray.intersectPlane(floorPlane, planeIntersectPoint);
                let pos = [planeIntersectPoint.x, 0, planeIntersectPoint.z];

                if (first) {
                   //console.log("ondrag first")
                    setIsDragging(true);
                   if(useMapStore.getState().selected_node !== node.qid) {
                       // console.log("I would like to deselect the current selected node: ", useMapStore.getState().selected_node);
                       setSelectedNode(null);
                   }
                    api.start({
                        to: {position:pos}
                    });
                    if(node.dragParent && !removeOnRest ) {
                        clearDragParent(node, instance_key); // Don't have this node connected to the parent anymore
                    }
                }

                if(active) {

                    if(node.nType === Entities.DIR) {
                        let r = getRotation(spring.position.animation.toValues, pos)
                        r_api.start({
                            rotation: [0, r, 0]
                        });
                    }

                    api.start({
                        position:pos,
                    });

                }

                setIsDragging(active);
                setLocalIsDragging(active);

                if (last) {
                    //console.log("setCameraTarget() on last drag distance: ", distance, "elapsedTime", elapsedTime);
                    if(useMapStore.getState().moveCameraAfterScroll) {setCameraTarget(pos)};
                    api.stop();
                    updateNodeTargetPosition(node, pos);

                    // handle long press
                    if (distance[0] + distance[1] < 2 && elapsedTime > 600) {
                        //console.log("long press dista - on right click", onRightClick, event);
                        onRightClick();
                        event.stopPropagation();
                    }

                    // setSelectedNode(node.qid);
                }

                return timeStamp;
            },
            onPointerDown: ({event}) => {
                //console.log("onpointerdown")
                event.stopPropagation();
            },
            onClick: ({event}) => {
                //console.log("nodeIcon onClick", event);
                onNodeClicked(node);
                event.stopPropagation(); // required or else this fires twice?!
            },
            onPointerOver: ({event}) => {setHovered(true)},
            onPointerOut: ({event}) => {setHovered(false)}
        },
        {drag: {delay: true, threshold: [5,5]}}
    );

    const onRightClick = () => {
        //console.log("On right click, onNodeClicked: ", onNodeClicked);
        onNodeClicked(node, true);
        setIsDeleteSelected(true);
        window.navigator.vibrate(200);
    }


    const onNodeClicked = (node, isRightClick = false) => {
        //console.log("On node clicked, isRightClick: ", isRightClick, localIsDragging);
        if (!localIsDragging || isRightClick) {
            let pos = spring.position.get();
            nodeClicked(node, pos, isRightClick);
        }
    }

    const parentMoved = (parentPos, offset) => {
        // console.log("parentMoved: ", parentPos, offset);
        if (useMapStore.getState().selected_node === node.dragParent && parentPos !== undefined) {
            // api.start({
            //     to: {position: [parentPos[0] + offset.x, 0, parentPos[2] + offset.z]}
            // });
            updateNodeTargetPosition(node, [parentPos[0] + offset.x, 0, parentPos[2] + offset.z]);
        }

    }

    const getRotation = (v1,v2) => {
        const vector12 = new THREE.Vector3(...v2).sub(new THREE.Vector3(...v1));
        return -Math.atan2(vector12.z, vector12.x);
    }

    useEffect(() => {
        if(isMobile) return;
        document.body.style.cursor = hovered ? 'pointer' : 'auto'
    }, [hovered])


    const onInfoClicked = (e) => {
        //console.log("on info clicked: ", node);
        useViewStore.setState({info_panel_node:node.id, info_panel_node_type:node.nType});
        setViewMode(ViewModes.INFO);
        e.stopPropagation();
    }

    const onDeleteClicked = (e) => {
        //console.log("on deleteclicked e: ", e);
        e.stopPropagation();
        //console.log("on deleteclicked node: ", node);
        useMapStore.getState().actions.deleteNodeFromMap(node.id, node.nType, instance_key);
    }



    return (
        <Suspense fallback={null}>

            <Instance
                key={node.qid}
                ref={ref}
                {...bind()}
                onContextMenu={onRightClick}
            >
                <Html
                    distanceFactor={.075}
                    wrapperClass={"mapLabelOuterWrapper" + (isSelected ? " selected" : "") + (hovered ? " hovered" : "")}
                    className={"mapLabelWrapper"}
                    zIndexRange={[0, 10000]}
                    style={{
                        zIndex: (isSelected || hovered ? 100 : 0)
                    }}
                >

                    {!!canExpand && <div className={"screen-reader-only"}
                         ref={htmlFocusElRef}
                         tabIndex={0}
                         role={"button"}
                         aria-label={strings.expand + " " + name}
                         onKeyPress={(e) => { if (e.key === 'Enter') { onNodeClicked(node); } } }
                         onFocus={ () => {
                             setSelectedNode(node.id);
                             invalidate();
                         }}
                         // onBlur={ () => {setSelectedNode(null)}} this causes bugs :( specifically when you click on a company it won't contract.
                    ></div>}
                    <div className={"screen-reader-only"}
                         tabIndex={0}
                         role={"button"}
                         aria-label={strings.infoAbout + " "  + name}
                         onKeyPress={(e) => { if (e.key === 'Enter') { onInfoClicked(e); } } }
                         onFocus={ () => {setIsInfoSelected(true); invalidate();}}
                         onBlur={ () => {setIsInfoSelected(false)}}
                    ></div>
                    <div className={"screen-reader-only"}
                         tabIndex={0}
                         role={"button"}
                         aria-label={strings.delete + " " + name}
                         onKeyPress={(e) => { if (e.key === 'Enter') { onDeleteClicked(e); } } }
                         onFocus={ () => {setIsDeleteSelected(true); invalidate();}}
                         onBlur={ () => {setIsDeleteSelected(false);}}
                    ></div>
                    {usingWikidata && isSelected && <Icon icon={Icons.INFO_SOLID} onClick={onInfoClicked} />}

                    {!!isDeleteSelected && <Icon icon={Icons.DELETE} onClick={onDeleteClicked} />}

                    <div
                        className={"label" + (isSelected ? " selected" : "") + (node.nType === Entities.ORG ? " company" : "")}>
                        {removeOnRest ? "" : name}
                    </div>

                </Html>
            </Instance>
        </Suspense>

    )
}