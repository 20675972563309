import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

const root = ReactDOM.createRoot(document.getElementById('root'));

    root.render(
        <React.StrictMode>
            <App _config={window.config}/>
        </React.StrictMode>
    );

// window.RenderApp = (config) => { // https://stackoverflow.com/questions/51653931/react-configuration-file-for-post-deployment-settings
//     ReactDOM.render(
//         <React.StrictMode>
//             <App _config={config}/>
//         </React.StrictMode>,
//         document.getElementById('root')
//     );
// }

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
