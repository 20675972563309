import {OrgType} from "../../../constants";

export const administrations = {
    Orgs: {
        Q28464532: {
            n : "George W. Bush Administration",
            t: OrgType.US_PRES_ADMIN,
            d : ["Q47216","Q150851","Q184650","Q202350","Q212979","Q262554","Q263322","Q271717","Q283396","Q292936","Q311025","Q311141","Q324505","Q376530","Q435010","Q448880","Q449689","Q456921","Q469483","Q540275","Q553502","Q559822","Q573825","Q732804","Q733612","Q737493","Q741648","Q879432","Q1240095","Q1276376","Q1285613","Q1288295","Q1534214","Q207"],
            p : "2001 - 2009"

        },

        Q4206667: {
            n : "Barack Obama Administration",
            t: OrgType.US_PRES_ADMIN,
            d : ["Q6279","Q6294","Q22316","Q27677","Q50610","Q172466","Q212979","Q229032","Q244631","Q293365","Q311211","Q312644","Q342586","Q349922","Q350378","Q353826","Q373274","Q379739","Q381670","Q443640","Q467891","Q970720","Q1365901","Q1677579","Q1681148","Q4231614","Q4772543","Q4806029","Q5393576","Q6176439","Q6243110","Q6680965","Q7793121","Q12014326","Q76"],
            p : "2009 - 2017"

        },

        Q27811470: {
            n : "Donald Trump Administration",
            t: OrgType.US_PRES_ADMIN,
            d : ["Q24313","Q215057","Q263322","Q267902","Q331401","Q358443","Q473239","Q525362","Q723917","Q816459","Q1415243","Q4716651","Q4898286","Q5239766","Q6232322","Q7273452","Q7351064","Q7384672","Q8000233","Q16875330","Q24248265","Q29453265","Q29918896","Q33190271","Q38858352","Q22686"],
            p : "2017 - 2021"

        },

        Q101250904: {
            n : "Joe Biden Administration",
            t: OrgType.US_PRES_ADMIN,
            d : ["Q234994","Q263725","Q353826","Q461746","Q941013","Q1855840","Q1922011","Q3702047","Q4714600","Q5562913","Q7173106","Q7821917","Q10853588","Q15126384","Q54860790","Q104244043","Q6279"],
            p : "2021 - present"

        },

    },
    Dirs: {
        Q47216: {n : "Condoleezza Rice", g:"f", c : ["Q28464532"]},

        Q150851: {n : "Colin Powell", g:"m", c : ["Q28464532"]},

        Q184650: {n : "Donald Rumsfeld", g:"m", c : ["Q28464532"]},

        Q202350: {n : "Alberto Gonzales", g:"m", c : ["Q28464532"]},

        Q212979: {n : "Robert Gates", g:"m", c : ["Q28464532","Q4206667"]},

        Q262554: {n : "Margaret Spellings", g:"f", c : ["Q28464532"]},

        Q263322: {n : "Elaine Chao", g:"f", c : ["Q28464532","Q27811470"]},

        Q271717: {n : "Gale Norton", g:"f", c : ["Q28464532"]},

        Q283396: {n : "Rod Paige", g:"m", c : ["Q28464532"]},

        Q292936: {n : "Ann Veneman", g:"f", c : ["Q28464532"]},

        Q311025: {n : "Henry Paulson", g:"m", c : ["Q28464532"]},

        Q311141: {n : "John Ashcroft", g:"m", c : ["Q28464532"]},

        Q324505: {n : "Alphonso Jackson", g:"m", c : ["Q28464532"]},

        Q376530: {n : "Steve Preston", g:"m", c : ["Q28464532"]},

        Q435010: {n : "Mel Martínez", g:"m", c : ["Q28464532"]},

        Q448880: {n : "Mike Johanns", g:"m", c : ["Q28464532"]},

        Q449689: {n : "John W. Snow", g:"m", c : ["Q28464532"]},

        Q456921: {n : "Paul H. O'Neill", g:"m", c : ["Q28464532"]},

        Q469483: {n : "Mary Peters", g:"f", c : ["Q28464532"]},

        Q540275: {n : "Michael Mukasey", g:"m", c : ["Q28464532"]},

        Q553502: {n : "Norman Mineta", g:"m", c : ["Q28464532"]},

        Q559822: {n : "Tom Ridge", g:"m", c : ["Q28464532"]},

        Q573825: {n : "Anthony Principi", g:"m", c : ["Q28464532"]},

        Q732804: {n : "Tommy Thompson", g:"m", c : ["Q28464532"]},

        Q733612: {n : "Michael Chertoff", g:"m", c : ["Q28464532"]},

        Q737493: {n : "Dirk Kempthorne", g:"m", c : ["Q28464532"]},

        Q741648: {n : "Carlos Gutierrez", g:"m", c : ["Q28464532"]},

        Q879432: {n : "Mike Leavitt", g:"m", c : ["Q28464532"]},

        Q1240095: {n : "Donald Evans", g:"m", c : ["Q28464532"]},

        Q1276376: {n : "Samuel Bodman", g:"m", c : ["Q28464532"]},

        Q1285613: {n : "Spencer Abraham", g:"m", c : ["Q28464532"]},

        Q1288295: {n : "Jim Nicholson", g:"m", c : ["Q28464532"]},

        Q1534214: {n : "James Peake", g:"m", c : ["Q28464532"]},

        Q207: {n : "George W. Bush", g:"m", c : ["Q28464532"]},

        Q6279: {n : "Joe Biden", g:"m", c : ["Q4206667","Q101250904"]},

        Q6294: {n : "Hillary Clinton", g:"f", c : ["Q4206667"]},

        Q22316: {n : "John Kerry", g:"m", c : ["Q4206667"]},

        Q27677: {n : "Kathleen Sebelius", g:"f", c : ["Q4206667"]},

        Q50610: {n : "Hilda Solis", g:"f", c : ["Q4206667"]},

        Q172466: {n : "Steven Chu", g:"m", c : ["Q4206667"]},

        Q229032: {n : "Janet Napolitano", g:"f", c : ["Q4206667"]},

        Q244631: {n : "Leon Panetta", g:"m", c : ["Q4206667"]},

        Q293365: {n : "Penny Pritzker", g:"f", c : ["Q4206667"]},

        Q311211: {n : "Timothy Geithner", g:"m", c : ["Q4206667"]},

        Q312644: {n : "Eric Holder", g:"m", c : ["Q4206667"]},

        Q342586: {n : "Ken Salazar", g:"m", c : ["Q4206667"]},

        Q349922: {n : "Arne Duncan", g:"m", c : ["Q4206667"]},

        Q350378: {n : "Gary Locke", g:"m", c : ["Q4206667"]},

        Q353826: {n : "Tom Vilsack", g:"m", c : ["Q4206667","Q101250904"]},

        Q373274: {n : "Eric Shinseki", g:"m", c : ["Q4206667"]},

        Q379739: {n : "Shaun Donovan", g:"m", c : ["Q4206667"]},

        Q381670: {n : "Chuck Hagel", g:"m", c : ["Q4206667"]},

        Q443640: {n : "Tom Daschle", g:"m", c : ["Q4206667"]},

        Q467891: {n : "Ray LaHood", g:"m", c : ["Q4206667"]},

        Q970720: {n : "Julián Castro", g:"m", c : ["Q4206667"]},

        Q1365901: {n : "John Bryson", g:"m", c : ["Q4206667"]},

        Q1677579: {n : "Jack Lew", g:"m", c : ["Q4206667"]},

        Q1681148: {n : "Robert A. McDonald", g:"m", c : ["Q4206667"]},

        Q4231614: {n : "Sally Jewell", g:"f", c : ["Q4206667"]},

        Q4772543: {n : "Anthony Foxx", g:"m", c : ["Q4206667"]},

        Q4806029: {n : "Ashton Carter", g:"m", c : ["Q4206667"]},

        Q5393576: {n : "Ernest Moniz", g:"m", c : ["Q4206667"]},

        Q6176439: {n : "Jeh Johnson", g:"m", c : ["Q4206667"]},

        Q6243110: {n : "John King, Jr.", g:"m", c : ["Q4206667"]},

        Q6680965: {n : "Loretta Lynch", g:"f", c : ["Q4206667"]},

        Q7793121: {n : "Thomas Perez", g:"m", c : ["Q4206667"]},

        Q12014326: {n : "Sylvia Burwell", g:"f", c : ["Q4206667"]},

        Q76: {n : "Barack Obama", g:"m", c : ["Q4206667"]},

        Q24313: {n : "Mike Pence", g:"m", c : ["Q27811470"]},

        Q215057: {n : "Rick Perry", g:"m", c : ["Q27811470"]},

        Q267902: {n : "James Mattis", g:"m", c : ["Q27811470"]},

        Q331401: {n : "Rex Tillerson", g:"m", c : ["Q27811470"]},

        Q358443: {n : "Jeff Sessions", g:"m", c : ["Q27811470"]},

        Q473239: {n : "Mike Pompeo", g:"m", c : ["Q27811470"]},

        Q525362: {n : "Sonny Perdue", g:"m", c : ["Q27811470"]},

        Q723917: {n : "William Barr", g:"m", c : ["Q27811470"]},

        Q816459: {n : "Ben Carson", g:"m", c : ["Q27811470"]},

        Q1415243: {n : "Tom Price", g:"m", c : ["Q27811470"]},

        Q4716651: {n : "Alex Azar", g:"m", c : ["Q27811470"]},

        Q4898286: {n : "Betsy DeVos", g:"f", c : ["Q27811470"]},

        Q5239766: {n : "David Shulkin", g:"m", c : ["Q27811470"]},

        Q6232322: {n : "John F. Kelly", g:"m", c : ["Q27811470"]},

        Q7273452: {n : "Alexander Acosta", g:"m", c : ["Q27811470"]},

        Q7351064: {n : "Robert Wilkie", g:"m", c : ["Q27811470"]},

        Q7384672: {n : "Ryan Zinke", g:"m", c : ["Q27811470"]},

        Q8000233: {n : "Wilbur Ross", g:"m", c : ["Q27811470"]},

        Q16875330: {n : "Eugene Scalia", g:"m", c : ["Q27811470"]},

        Q24248265: {n : "Steven Mnuchin", g:"m", c : ["Q27811470"]},

        Q29453265: {n : "Dan R. Brouillette", g:"m", c : ["Q27811470"]},

        Q29918896: {n : "David L. Bernhardt", g:"m", c : ["Q27811470"]},

        Q33190271: {n : "Mark T. Esper", g:"m", c : ["Q27811470"]},

        Q38858352: {n : "Kirstjen Nielsen", g:"f", c : ["Q27811470"]},

        Q22686: {n : "Donald Trump", g:"m", c : ["Q27811470"]},

        Q234994: {n : "Jennifer Granholm", g:"f", c : ["Q101250904"]},

        Q263725: {n : "Janet Yellen", g:"f", c : ["Q101250904"]},

        Q461746: {n : "Marcia Fudge", g:"f", c : ["Q101250904"]},

        Q941013: {n : "Lloyd Austin", g:"m", c : ["Q101250904"]},

        Q1855840: {n : "Xavier Becerra", g:"m", c : ["Q101250904"]},

        Q1922011: {n : "Merrick Garland", g:"m", c : ["Q101250904"]},

        Q3702047: {n : "Denis McDonough", g:"m", c : ["Q101250904"]},

        Q4714600: {n : "Alejandro Mayorkas", g:"m", c : ["Q101250904"]},

        Q5562913: {n : "Gina Raimondo", g:"f", c : ["Q101250904"]},

        Q7173106: {n : "Pete Buttigieg", g:"m", c : ["Q101250904"]},

        Q7821917: {n : "Antony Blinken", g:"m", c : ["Q101250904"]},

        Q10853588: {n : "Kamala Harris", g:"f", c : ["Q101250904"]},

        Q15126384: {n : "Marty Walsh", g:"m", c : ["Q101250904"]},

        Q54860790: {n : "Deb Haaland", g:"f", c : ["Q101250904"]},

        Q104244043: {n : "Miguel Cardona", g:"m", c : ["Q101250904"]},

    },
    Admins: ['Q28464532', 'Q4206667', 'Q27811470', 'Q101250904']
}