import React, {useEffect, useState} from "react";
import UseInterval from "../../hooks/useIntervalHook";
import {AutoPlayStates, Entities} from "../../data/constants";
import useMapStore from "../../data/map_store";
import TheyRuleButton, {ButtonStyles, ButtonTypes} from "../UI/TheyRuleButton";
import {strings} from "../../data/strings";
import Icon from "../Icons";
import {Icons} from "../Icons/Icon";
import {isMobile} from "react-device-detect";

function MapAutoPlay() {

    const {addNode, addToAutoPlayHistory, orgClicked, directorClicked, contractUnconnectedDirectors, setZoom, setCameraTarget, resumeAutoPlay, stopAutoPlay, clearAutoPlayHistory, clearMap} = useMapStore((state) => state.actions);
    const [autoPlayInterval, setAutoPlayInterval] = useState(null);
    const [isPaused, setIsPaused] = useState(false);
    const [reachedEndOfPath, setReachedEndOfPath] = useState(false);
    const [msAtEndOfPath, setMsAtEndOfPath ] = useState(0);
    const [endOfPathInterval, setEndOfPathInterval] = useState(null);
    const [shouldMoveCamToDir, setShouldMoveCamToDir] = useState(false);
    const [showEopToast, setShowEopToast] = useState(false);
    const [eopToastDuration, ] = useState(20000);
    const [eopDuration, setEopDuration] = useState(20000);
    const [eopTargetPercent, setEopTargetPercent] = useState(0);
    const [closeEOPToastInterval, setCloseEOPToastInterval] = useState(null);
    const {Orgs, Dirs} = useMapStore((state) => state.dataset);
    const {getTableSize, getDirectorType} = useMapStore((state) => state.actions);
    const [maxExpandedComs, ] = useState(3); // Contract the unconnected directors on companies this many from the front

    // The timer controlling the autoPlay
    UseInterval(() => {
        // console.log("Map Auto Play Beat");
        onAutoPlayBeat();
    }, autoPlayInterval);

    // The timer controlling the toast at the completion of a path
    UseInterval(() => {
        onEndOfPathBeat();
    }, endOfPathInterval);

    // The timer for removing the EOP Toast after clicking explore
    UseInterval(() => {
        onRemoveEOPToast();
    }, closeEOPToastInterval);

    const autoPlayStateChanged = (AutoPlayState) => {
        // console.log("%c AutoPlay State Changed: ", "color:#F00", AutoPlayState);

        switch (AutoPlayState) {
            case AutoPlayStates.PLAYING:
                startAutoPlay();
                setIsPaused(false);
                break;
            case AutoPlayStates.PAUSED:
                setIsPaused(true);
                setAutoPlayInterval(null);
                break;
            case AutoPlayStates.OFF:
            default:
                setIsPaused(false);
                setAutoPlayInterval(null);
                break;
        }

    }

    // Listen for changes in the auto_play_state in the map store
    useEffect(()=>{
        const unsub = useMapStore.subscribe((state) => (state.auto_play_state), autoPlayStateChanged);

        // call this the first time if it has a target
        autoPlayStateChanged(useMapStore.getState().auto_play_state);

        return () => {
            unsub(); // Clean up the subscription
        };
        // ONLY CALL THIS ON MOUNT
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [] )


    const startAutoPlay = () => {
        // console.log("startAutoPlay");
        setAutoPlayInterval(1);
    }

    const onAutoPlayBeat = () => {
        let autoPlayHistory = useMapStore.getState().auto_play_history;
        // console.log("onAutoPlayBeat", autoPlayHistory);
        let len = autoPlayHistory.length;

        // The next commented out lines are for testing the reached end of path toast
        // if(len >= 4) {
        //     onReachedEndOfPath();
        //     return;
        // }

        if(len === 0) {
            addFirstCompany();
            setZoom(50);
            setAutoPlayInterval(1000);
            return;
        }

        // Expand company
        if (len % 2 === 1) {
            if(len === 1){
                setZoom(10); // zoom out on first item
            }
            contractCompany(); // contract company in the history of the path.
            expandCompany();
            return;
        }

        // Expand director
        if (len % 2 === 0) {
            if(shouldMoveCamToDir){
                moveCameraToDirector();
            } else {
                expandDirector();
            }
            return;
        }

    }

    const expandDirector = () => {
        let autoPlayHistory = useMapStore.getState().auto_play_history;
        // console.log ("%c expandDirector, dir to Expand: ", "color:orange", autoPlayHistory.at(-1).id);
        let dir = autoPlayHistory.at(-1);
        let dir_type = getDirectorType(dir.id);
        let dirNode = useMapStore.getState().mutation[dir_type][dir.id];
        let pos = getPos(dir.id);
        directorClicked(dirNode, pos);

        // choose a company to expand next
        // filter the directors comps to ones that have directors that are connected to companies that are not already on the map
        let comsToChooseFrom = dir.dir.c.filter(compId => {
                if(autoPlayHistory.some(item => item.id === compId)){
                    // console.log("%c This is already in the path history: ", "color:red", Coms[compId].n)
                    return false;
                }
                let comsComs = getAllComsOneDegreeAway(compId);
                // console.log("All the companies connected to ", Coms[compId].n, ":", comsComs);
                return comsComs.some(notOnMapYet);
            }
        );
        // console.log("%c comsToChooseFrom: " , "color:blue", comsToChooseFrom);
        if(comsToChooseFrom.length <= 0) {
            // There are no more connected companies to choose from
            // But we still might have some expanded companies from this director that were not in the path so far
            let finalComs = dir.dir.c.filter(compId => {
                    return !autoPlayHistory.some(item => item.id === compId);
                }
            );
            // If so - choose the highest rev one of those and add it as the last company in the chain
            let finalCom = null;
            // console.log("%c finalComs:", "color:magenta", finalComs);
            if(finalComs.length >= 1) {
                finalCom = finalComs.reduce(function(prev, current) {
                    return (Orgs[prev].r > Orgs[current].r) ? prev : current
                })
            }
            if(finalCom) {
                let items_to_add = [{id:finalCom, com:Orgs[finalCom], dir:false}]
                // console.log("%c Add Final Company, ", "color:green", Coms[finalCom].n);
                let pos = useMapStore.getState().node_target_positions[finalCom];
                setCameraTarget([pos.x, 0, pos.z]);
                addToAutoPlayHistory(items_to_add);
            }
            // console.log("%c THIS IS A DEAD END", "color:red");
            onReachedEndOfPath();
            return;
        } else {
            // add a random company to the auto play history stack
            let chosenComId = comsToChooseFrom[Math.floor(Math.random() * comsToChooseFrom.length)];
            let items_to_add = [{id:chosenComId, com:Orgs[chosenComId], dir:false}]
            // console.log("%c Add Company, ", "color:green", Coms[chosenComId].n);
            let pos = useMapStore.getState().node_target_positions[chosenComId];
            setCameraTarget([pos.x, 0, pos.z]);
            addToAutoPlayHistory(items_to_add);
            setAutoPlayInterval(2000);
            return;
        }

    }

    function getAllComsOneDegreeAway (compId) {
        let connectedComs = [];
        let board = Orgs[compId].d
        let len = board.length;
        let dirId, i;
        for(i = 0; i < len; i++){
            dirId = board[i];
            connectedComs = [...new Set([...connectedComs ,...Dirs[dirId].c])];
        }
        return connectedComs;
    }

    const contractCompany = () => {
        let autoPlayHistory = useMapStore.getState().auto_play_history;

        if(autoPlayHistory.length - maxExpandedComs >= 0) {
            // the entity to contract
            let id = autoPlayHistory.at(-maxExpandedComs).id; // return the id
            // need to find the node:
            let table_size = getTableSize(id);
            let node = useMapStore.getState().mutation[table_size][id]
            // companyClicked(node, getPos(id));
            //console.log("contractCompany autoPlayHistory", autoPlayHistory);
            //console.log("contractCompany contractCompany", node);
            contractUnconnectedDirectors(node);
        }
    }

    const expandCompany = () => {

        let autoPlayHistory = useMapStore.getState().auto_play_history;

        // the last entity is a COM - expand it
        let id = autoPlayHistory.at(-1).id; // return last value in the array
        // need to find the node:
        let table_size = getTableSize(id);
        let node = useMapStore.getState().mutation[table_size][id]
        orgClicked(node, getPos(id));

        // choose a director to expand
        let dirIds = autoPlayHistory.at(-1).com.d;
        // console.log(dirIds);
        let dirsThatCouldExpand = [];
        let dcoms, i
        let dlen = dirIds.length;
        for (i = 0; i < dlen; i++){
            let dirId = dirIds[i];
            if(autoPlayHistory.some(item => item.id === dirId)){
                // console.log("%c This director is already in the path history: ", "color:red", Dirs[dirIds[i]].n)
                continue;
            }
            dcoms = Dirs[dirId].c;
            if(dcoms.length < 2) {
                continue;
            }
            // are any of these companies not on the screen?
            if(dcoms.some(notOnMapYet)) {
                dirsThatCouldExpand.push(dirId);
            }
        }

        // console.log("dirs that could expand: ", dirsThatCouldExpand);
        // is there a director that can expand?
        if(dirsThatCouldExpand.length < 1) {
            // there is no director to expand here
            // console.log("%c This is the end of the path.", "color:magenta");
            onReachedEndOfPath();
            return;
        } else {
            // add a random director to the auto play history path
            let chosenDirId = dirsThatCouldExpand[Math.floor(Math.random() * dirsThatCouldExpand.length)];
            let items_to_add = [{id:chosenDirId, com:false, dir:Dirs[chosenDirId]}]
            addToAutoPlayHistory(items_to_add);
            // console.log("%c Add Director, ", "color:green", Dirs[chosenDirId].n);
            //let pos = useMapStore.getState().node_target_positions[chosenDirId];
            //setCameraTarget([pos.x, 0, pos.z]);

            setShouldMoveCamToDir(true);

            setAutoPlayInterval(3000);
            return;
        }
    }

    const moveCameraToDirector = () => {
        let autoPlayHistory = useMapStore.getState().auto_play_history;
        // console.log ("%c moveCameraToDirector, dir to Expand: ", "color:orange", autoPlayHistory.at(-1).id);
        let dir = autoPlayHistory.at(-1);
        let pos = useMapStore.getState().node_target_positions[dir.id];
        setCameraTarget([pos.x, 0, pos.z]);
        setShouldMoveCamToDir(false);
        setAutoPlayInterval(50);
        return;
    }

    const getPos = (id) => {
        return useMapStore.getState().node_positions[id];
    }

    const onMoreThanOneBoard = (dirId) => {
        // console.log("Dirs[dirId].c.length", Dirs[dirId].c.length);
        if(Dirs[dirId].c.length > 1) {
            // console.log("Found a dir with more that one comp")
            return true;
        }
        return false; // continue
    }

    function notOnMapYet (id) {
        if(useMapStore.getState().node_positions[id]){
            return false; // false because it IS on the map
        } else {
            return true;
        }
    }

    const addFirstCompany = () => {
        // console.log("addFirstCompany");

        // Find a random company to start with
        let keys = Object.keys(Orgs);
        let isConnected = false;
        let randComp, randCompId;
        let count = 0;
        while (isConnected === false && count < 15 ) {
            count++; // this is just insurance
            randCompId = keys[ keys.length * Math.random() << 0];
            randComp = Orgs[randCompId];
            // console.log("randCompId: ", randCompId);
            // console.log("randComp: ", randComp);
            // Go through directors of random comp and make sure there is one that is on at least one other board
            isConnected = randComp.d.some(onMoreThanOneBoard);
        }
        // if(!isConnected){
        //     // this company is not connected to any others
        //     // console.log("Very unlikely to get here - it should mean that we have chose 15 unconnected companies in a row");
        //     // Just proceed with starting with the selected unconnected company, it should still play out
        // }
        addNode(Entities.ORG, randCompId);
        useMapStore.setState({auto_play_history:[{id:randCompId, com:randComp, dir:false}] })

    }

    const Paused = (
        <div
            className="paused-wrapper"
            onClick={e => {
                e.stopPropagation()
            }}
            onMouseDown={e => {
                e.stopPropagation()
            }}
        >
            <TheyRuleButton
                label={strings.resumePlay}
                action={() => {
                    resumeAutoPlay();
                    setAutoPlayInterval(500);
                }}
                mode={ButtonStyles.LIGHT}
                icon={<Icon icon={Icons.PLAY_TRIANGLE}/>}
            />
            <TheyRuleButton
                label={strings.explore}
                action={() => {
                    stopAutoPlay();
                }}
                btnType={ButtonTypes.TERTIARY}
                mode={ButtonStyles.LIGHT}
            />
        </div>
    );

    const onReachedEndOfPath = () => {
        // console.log("%c onReachedEndOfPath", "color:red");
        setReachedEndOfPath(true);
        setAutoPlayInterval(null);
        setEndOfPathInterval(100);
        setMsAtEndOfPath(0);
        setShowEopToast(false);
    }


    const onEndOfPathBeat = () => {
        setMsAtEndOfPath(msAtEndOfPath + endOfPathInterval);
        if(msAtEndOfPath > 0 && msAtEndOfPath < (4 * endOfPathInterval)) {
            setShowEopToast(true);
            setEopTargetPercent(0);
            setEopDuration(1);
        }
        if(msAtEndOfPath > (4 * endOfPathInterval)) {
            setShowEopToast(true);
            setEopTargetPercent(100);
            setEopDuration(eopToastDuration * .90);
        }
        if(msAtEndOfPath > 0 && msAtEndOfPath >  (eopToastDuration * .95)) {
            setShowEopToast(false);
        }
        if(msAtEndOfPath >= eopToastDuration) {
            //console.log("%c now close toast and restart", "color:red");
            setReachedEndOfPath(false);
            setAutoPlayInterval(2000);
            setEndOfPathInterval(null);
            setMsAtEndOfPath(0);
            setShowEopToast(false);
            clearAutoPlayHistory();
            clearMap();
            // startAutoPlay();
        }
    }

    function getSummaryString () {
        // returns the number of steps between the first and last company in the Path
        let autoPlayHistory = useMapStore.getState().auto_play_history;
        if(autoPlayHistory.length >= 2) {
            let lastItem = autoPlayHistory.at(-1);
            let lastComIndex = (lastItem.com) ? autoPlayHistory.length - 1 : autoPlayHistory.length - 2;
            let numComs = Math.ceil((lastComIndex + 1)/2);
            let numDirs = Math.floor((lastComIndex + 1)/2);
            // "This path includes *num_comps* companies connected by *num_dirs* *dirs* between *comp1* and *comp2*. To find more connections click explore below."
            let str = strings.summary;
            str = str.replace("*num_comps*", numComs);
            str = str.replace("*num_dirs*", numDirs);
            str = str.replace("*dirs*", (numDirs>1)? strings.directors : strings.director );
            str = str.replace("*comp1*", Orgs[autoPlayHistory[0].id].n );
            str = str.replace("*comp2*", Orgs[autoPlayHistory[lastComIndex].id].n );
            return str;
        }
        return "";
    }

    const onRemoveEOPToast = () => {
        // console.log("Remove EOP Toast");
        setReachedEndOfPath(false);
    };

    const EndOfPath = (
            <div
                className={"end-of-path-wrapper"}
                onClick={e => {
                    e.stopPropagation()
                }}
                onMouseDown={e => {
                    e.stopPropagation()
                }}
            >
                <div className={"eop-toast" + (isMobile ? " mobile" : "") + (showEopToast ? " show" : "")}>
                    <p className={"title"}>PATH COMPLETE</p>
                    <p>{getSummaryString()}</p>
                    <div className={"btn-wrap"}>
                        <TheyRuleButton
                            label={strings.explore}
                            action={() => {
                                stopAutoPlay();
                                setAutoPlayInterval(null);
                                setEndOfPathInterval(null);
                                setCloseEOPToastInterval(300);
                                setMsAtEndOfPath(0);
                                setShowEopToast(false);
                                clearAutoPlayHistory();
                            }}
                            btnType={ButtonTypes.TERTIARY}
                            mode={ButtonStyles.DARK}
                        />
                        <TheyRuleButton
                            label={strings.playAgain}
                            action={() => {
                                setMsAtEndOfPath(eopToastDuration * .95);
                            }}
                            mode={ButtonStyles.DARK}
                            btnType={ButtonTypes.PROGRESS}
                            icon={<Icon icon={Icons.PLAY_TRIANGLE}/>}
                            percent={eopTargetPercent}
                            duration={eopDuration}
                        />
                    </div>
                </div>
            </div>
        )




    return ( <>
        { isPaused?Paused:""}
        { reachedEndOfPath?EndOfPath:""}
    </> );
}

export default MapAutoPlay;