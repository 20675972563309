import React, {useState} from "react";
import "./Menu.scss";
import {isMobile} from "react-device-detect";
import Icon from "../Icons";
import {Icons} from "../Icons/Icon";
import {strings} from "../../data/strings";
import PlayIcon from "../../images/icons/play_button.svg";
import useMapStore from "../../data/map_store";
import useDialogStore from "../../data/dialog_store";
import RadioButton from "../UI/RadioButton";
import {DataSets} from "../../data";
import {dataPeriods, ViewModes} from "../../data/constants";
import MenuDataSetSection from "./MenuDataSection";
import useViewStore from "../../data/view_store";
import * as THREE from "three";
import {useNavigate} from "react-router-dom";
import FindConnectionWrapper from "./FindConnectionWrapper";
import PrintMapWrapper from "./PrintMapWrapper";


function Menu ({onClose}) {

    const {clearDialog, showDialog, setTitle, setBody,  setTertiary, setPrimary, setTertiaryAction, setPrimaryAction, onCloseDialog} = useDialogStore((state) => state.actions);
    const {startAutoPlay, clearMap, setCurrentDataSet} = useMapStore((state) => state.actions);
    const node_positions = useMapStore((state) => state.node_positions);
    const setViewMode = useViewStore((state) => state.actions.setViewMode);
    const {show_menu } = useViewStore((state) => state.view);
    const sortedDataSets = DataSets.sort(function (a, b) {
        return b.year - a.year || b.month - a.month || b.day - a.day || a.version - b.version
    });
    const dsInfo = useMapStore((state) => state.datasetInfo);
    const dataAddOns = useMapStore((state) => state.dataAddOns);
    const [dataSectionExpanded, setDataSectionExpanded] = useState(false);
    const [dataDetailsSectionExpanded, setDataDetailsSectionExpanded] = useState(false);

    const DATA_SET_RADIO_ID = "data set radio";

    const savedMapId = useMapStore((state) => state.loaded_saved_map_id);
    const [savedMapDataSet,  ] = useState(useMapStore.getState().loaded_saved_map_data_set_info[useMapStore.getState().loaded_saved_map_id]);

    const [focusCloseButton, ] = useState(false);
    // const ref = useRef();
    // const em = !!ref.current? parseFloat(getComputedStyle(ref.current).fontSize): 16;
    // const width = isMobile? window.innerWidth : 40 * em;
    // const [{ x }, api] = useSpring(() => ({ x: -width }));
    //
    //
    // /**
    //  MENU OPENED OR CLOSED
    //  Listen for changes in the view
    //  */
    // useEffect(()=>{
    //     const unsubShowMenuPanel = useViewStore.subscribe(
    //         (state) => (state.view.show_menu),
    //         (arg) => {
    //             console.log("Show #menu arg: ", arg);
    //             let showMenu = useViewStore.getState().view.show_menu;
    //             console.log("Show #menuchanged: ", showMenu);
    //             if(showMenu) {
    //                 open(false);
    //             } else {
    //                 //  close(); handled elsewhere
    //             }
    //         }
    //     )
    //     return () => {
    //         // Clean up the subscription
    //         unsubShowMenuPanel();
    //     };
    //     // eslint-disable-next-line react-hooks/exhaustive-deps
    // }, [ ])
    //
    // // Adapted code from: https://codesandbox.io/s/github/pmndrs/use-gesture/tree/main/demo/src/sandboxes/action-sheet?file=/src/styles.module.css to handle the animated opening and swipe to close functionality
    //
    // const open = ({ canceled }) => {
    //     // when cancel is true, it means that the user passed the upwards threshold
    //     // so we change the spring config to create a nice wobbly effect
    //     api.start({ x: 0,
    //         immediate: false,
    //         onStart: ()=>{setFocusCloseButton(false)},
    //         onRest: ()=>{setFocusCloseButton(true)},
    //         config: canceled ? config.wobbly : config.stiff })
    // }
    // const close = (velocity = 0) => {
    //     api.start({ x: -width,
    //         immediate: false,
    //         onRest: ()=>{setViewMode(ViewModes.MAP)},
    //         config: { ...config.stiff, velocity }
    //     })
    // }
    //
    // const bind = useDrag(
    //     (e) => {
    //         // if the user drags up passed a threshold, then we cancel
    //         // the drag so that the sheet resets to its open position
    //
    //         //console.log("#menu e: ", e);
    //         if (e.movement[0] > 70) {
    //             console.log("#menu e.cancel");
    //             e.cancel()
    //         }
    //
    //         // when the user releases the sheet, we check whether it passed
    //         // the threshold for it to close, or if we reset it to its open position
    //         if (e.last) {
    //             console.log("#menu e.last", e);
    //
    //             let canceled = e.canceled;
    //
    //             if( (
    //                     (e.distance[0] * 3) > width * 0.3 || (e.velocity[0] > 0.3)
    //                 )
    //                 && e.direction[0] < 0
    //             )
    //             { onClose(e.velocity[0] > 0.5? e.velocity[0] : 0.65) }
    //             else
    //             { open({canceled} ) };
    //         }
    //             // when the user keeps dragging, we just move the sheet according to
    //         // the cursor position
    //         else {
    //             console.log("#menu e.movement[0]: ", e.movement[0], e);
    //             if(e.dragging) {
    //                 api.start({x: e.movement[0] < 0 ? e.movement[0] * 3 : e.movement[0], immediate: true})
    //             }
    //         }
    //     },
    //     { from: () => [x.get(), 0], filterTaps: true, bounds: { left: 0 }, rubberband: true }
    // )
    //
    // const display = x.to((posX) => (posX < width ? 'block' : 'none'));
    //
    //
    // const onClose = (velocity) => {
    //     close(velocity);
    //     setViewMode(ViewModes.MENU_CLOSING);
    // }


    const onStartAutoPlay = () => {
        //console.log("Autoplay");
        onClearMap();
        startAutoPlay();
        onCloseDialog();
        onClose();
    }
    const navigate = useNavigate();
    const onClearMap = () => {
        clearMap(navigate);
    }

    const closeDialog = () => {
        showDialog(false);
    }

    const openAutoPlayDialog = () => {
        clearDialog();
        setTitle(strings.areYouSure);
        setBody(strings.autoPlayClearsMap);
        setTertiary(strings.cancel);
        setPrimary(strings.yesPlay);
        setPrimaryAction(onStartAutoPlay);
        setTertiaryAction(closeDialog);
        showDialog(true);
    }

    const openClearMapWhenChangingDataSetDialog = (changeDataFunction) => {
        clearDialog();
        setTitle(strings.areYouSure);
        setBody(strings.changingDataClearsMap);
        setTertiary(strings.cancel);
        setPrimary(strings.yesChange);
        setPrimaryAction(()=>{changeDataFunction(); closeDialog()});
        setTertiaryAction(closeDialog);
        showDialog(true);
    }

    const openClearMapDialog = () => {
        clearDialog();
        setTitle(strings.areYouSure);
        setBody(!!savedMapId? strings.areYouSureClearMap + " " + strings.resetToDefaultData : strings.areYouSureClearMap );
        setTertiary(strings.cancel);
        setPrimary(strings.yesClear);
        setPrimaryAction(()=>{onClearMap(); closeDialog(); setViewMode(ViewModes.INTRO);});
        setTertiaryAction(closeDialog);
        showDialog(true);
    }

    const openPrintMapDialog = () => {
        //console.log("#print openPrintMapDialog")
        clearDialog();
        setTitle(strings.TheyRulePdf);
        setBody(<PrintMapWrapper saveMapData={saveMapData}/> );
        //console.log("#print openPrintMapDialog now show dialog")
        showDialog(true);
        //console.log("#print openPrintMapDialog done")
    }

    const openFindConnectionsDialog = () => {
        clearDialog();
        setTitle("Find Connections");
        // setBody(<Suspense fallback={<></>}>"DOG DOG"</Suspense>);
        setBody(<FindConnectionWrapper onClose={()=>{closeDialog(); onClose()}}/> );
        showDialog(true);
    }

    const onToggleCurrentDataSet = (dataset) => {
        //console.log("onToggleCurrentDataSet: ", dataset);
        openClearMapWhenChangingDataSetDialog(()=>{changeCurrentDataSet(dataset)});
    }

    const changeCurrentDataSet = (dataset) => {
        //console.log("Menu changeCurrentDataSet: ", dataset);
        onClearMap();
        //console.log("Menu now setCurrentDataSet ", dataset);
        setCurrentDataSet(dataset);
        setViewMode(ViewModes.INTRO);
    }

    /**
     * Creates Map Data to save to database
     * Saves it via PHP using a fetch
     * Processes the returned fetch data
     * @returns {Promise<any>}
     */
    const saveMapData = () => {
        //console.log("getMapData");
        let today = new Date();
        let created = (today.getMonth() + 1) + "/" + today.getDate() + "/" + today.getFullYear();
        let orbitControls = useMapStore.getState().orbit_controls;
        let raycaster = new THREE.Raycaster();
        let intersects = new THREE.Vector3();
        let camera = useMapStore.getState().camera
        raycaster.setFromCamera(new THREE.Vector2(), camera);
        raycaster.ray.intersectPlane(useMapStore.getState().floor_plane, intersects);
        let camera_target = [intersects.x, 0, intersects.z];
        let mapData = {
            node_positions: useMapStore.getState().node_positions,
            // mutation:useMapStore.getState().mutation,
            camera_target: camera_target,
            camera_zoom_target: camera.zoom,
            camera_rotation: orbitControls.getAzimuthalAngle(),
            dataSetInfo: useMapStore.getState().datasetInfo,
            dataAddOns: useMapStore.getState().dataAddOns,
            date_created: created,
        }
        //console.log("getMapData MapData: ", mapData);

        return (
            fetch(useMapStore.getState().config.THEY_RULE_API_PATH + 'savemap.php',

                {
                    method: 'POST', // *GET, POST, PUT, DELETE, etc.
                    mode: 'cors', // no-cors, *cors, same-origin
                    cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
                    credentials: 'omit', // include, *same-origin, omit
                    headers: {
                        'Content-Type': 'application/json'
                        // 'Content-Type': 'application/x-www-form-urlencoded',
                    },
                    redirect: 'follow', // manual, *follow, error
                    referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
                    body: JSON.stringify({"mapdata": mapData}) // body data type must match "Content-Type" header
                }
            )
                .then(response => response.json())
        )

    }


    const saveMap = ( ) => {
        //console.log("Save Map");

        clearDialog();
        setTitle(strings.linkToThisMap);
        setBody(<>{strings.creatingLink}</>);
        setTertiary(strings.cancel);
        setPrimary(strings.ok);
        setPrimaryAction(closeDialog);
        setTertiaryAction(closeDialog);
        showDialog(true);

        saveMapData().then(data => {
                //console.log("fetched: ", data);
                if(data.saved) {
                    let link = window.location.origin + "/map/" + data.url;
                    let copied = false;
                    if(navigator && navigator.clipboard && navigator.clipboard.writeText) {
                        navigator.clipboard.writeText(link);
                        copied = true;
                    }
                    setBody(<><br/><b>{link}</b><br/><br/>{copied && strings.copiedToClipboard}<br/><br/><p className={"note"}><i>{strings.saveMapAgain}</i></p></>);
                    setTertiary(undefined);
                } else {
                    setTitle(strings.error);
                    setBody(strings.sorryErrorCreatingLink);
                }
            });

    }

    const onToggleDataSection = () => {
        setDataSectionExpanded(!dataSectionExpanded);
    }

    const onToggleDataDetailsSection = () => {
        setDataDetailsSectionExpanded(!dataDetailsSectionExpanded);
    }

    // const onToggleFindConnectionSection = () => {
    //     setFindConnectionSectionExpanded(!findConnectionSectionExpanded);
    // }
    //
    // const onTogglePrintMap = () => {
    //     console.log("onTogglePrintMap");
    //     setPrintSectionExpanded(!printSectionExpanded);
    // }

    const getDataDetails = (dataSet) => {

        return (
            <div className={"data-details-summary"}>

                {dataSet.notes !== "" && <span>{dataSet.notes} &nbsp;</span>}
                {strings.dataSummary
                    .replace("*total_orgs*", dataSet.total_orgs)
                    .replace("*org_type*", dataSet.org_type)
                    .replace("*criteria*", dataSet.criteria)
                    .replace("*year*", dataSet.year)
                    .replace("*source*", strings[dataSet.source])
                    .replace("*retrieved*", dataSet.retrieved)
                }
                <ul>
                    <li>{strings.dataAnalyticsSize.replace("*average_board_size*", dataSet.average_board_size)}</li>
                    <li>{strings.dataAnalyticsConnected.replace("*average_connections*", dataSet.average_connections)}</li>
                    <li>{strings.dataAnalyticsClusters.replace("*percent_in_clusters*", Math.round((dataSet.total_orgs-dataSet.num_isolates)/dataSet.total_orgs * 100))
                        .replace("*num_clusters*", dataSet.num_clusters)
                        .replace("*clusters*", dataSet.num_clusters === 1? strings.cluster : strings.clusters)}</li>
                    <li>{strings.dataAnalyticsIsolates.replace("*percent_isolates*", Math.round(dataSet.num_isolates/dataSet.total_orgs * 100))}</li>
                    <li>{strings.dataAnalyticsWomen.replace("*percent_women*", dataSet.percent_women)}</li>
                </ul>

            </div>
        )


    }

    // console.log("menu render dsInfo: ", dsInfo);
    // console.log("menu render dataAddOns: ", dataAddOns);

    return <>
        {!!show_menu && <div className="flex" style={{overflow: 'hidden'}} aria-modal={"true"}>
        {/*<div className={"close-menu-overlay"}*/}
        {/*     onClick={() => { onClose(.8);}}*/}
        {/*     tabIndex={0}*/}
        {/*     role={"button"}*/}
        {/*     aria-label={"Close Panel"}*/}
        {/*     onKeyPress={(e) => {*/}
        {/*         if (e.key === 'Enter') {*/}
        {/*             onClose(.8);*/}
        {/*         }*/}
        {/*     }}*/}
        {/*/>*/}

        {/*<a.div className={"menu_wrap" + (isMobile ? " mobile" : "") }*/}
        {/*    {...bind()} style={{ display, x }} aria-modal={"true"} >*/}
        {/*    <nav role="navigation" aria-label={strings.mainMenu} className={"main_menu" + (isMobile ? " mobile" : "")}>*/}

            <div className={"menu_wrap" + (isMobile ? " mobile" : "") } >
                <nav role="navigation" aria-label={strings.mainMenu} className={"main_menu" + (isMobile ? " mobile" : "")}>

                 <div className={"stickyTopSection"}>

                <div className={"menu-close-icon"}>
                    <Icon icon={Icons.BACK} onClick={() => onClose(.8)} interactive={true} label={strings.closeMenu} autofocus={focusCloseButton} />
                </div>
                <div className={"menu-title"}>{strings.theyRule}</div>
                 </div>
                    <div className="scrollableSections">
                <div className={"play-button"}
                     onClick={(Object.keys(node_positions).length > 20) ? openAutoPlayDialog : onStartAutoPlay}
                     role={"button"}
                     aria-label={strings.playAutomatically}
                     tabIndex={0}
                     onKeyPress={(e) => {
                         if (e.key === 'Enter') {
                             (Object.keys(node_positions).length > 20) ? openAutoPlayDialog() : onStartAutoPlay();
                         }
                     }
                     }
                >
                    <img className={"play-icon"} src={PlayIcon} alt={"play icon"}/>
                    {strings.playAutomatically}
                </div>


            {/*MAP*/}
                <section className={"menu-section"} aria-label={strings.mapSection}>
                    <div className={"section-title"}>{strings.map}</div>
                    <div className={"menu-link with-icon"}
                         onClick={saveMap}
                         role={"button"}
                         tabIndex={0}
                         aria-label={strings.copyMap}
                         onKeyPress={(e) => {
                             if (e.key === 'Enter') {
                                 saveMap();
                             }
                         }}
                    ><Icon icon={Icons.LINK} onClick={saveMap}/>{strings.copyMap}</div>

                    <div className={"menu-link with-icon"}
                         onClick={openClearMapDialog}
                         role={"button"}
                         tabIndex={0}
                         aria-label={strings.clearMap}
                         onKeyPress={(e) => {
                             if (e.key === 'Enter') {
                                 openClearMapDialog();
                             }
                         }}
                    ><Icon icon={Icons.CLEAR} onClick={openClearMapDialog}/>{strings.clearMap}</div>

                    <div className={"menu-link with-icon"}
                         onClick={openPrintMapDialog}
                         role={"button"}
                         tabIndex={0}
                         aria-label={strings.printMap}
                         onKeyPress={(e) => {
                             if (e.key === 'Enter') {
                                 openPrintMapDialog();
                             }
                         }}
                    ><Icon icon={Icons.PRINT}/>{strings.printMap}</div>

                    <div className={"menu-link with-icon"}
                         onClick={openFindConnectionsDialog}
                         role={"button"}
                         tabIndex={0}
                         aria-label={strings.findConnections}
                         onKeyPress={(e) => {
                             if (e.key === 'Enter') {
                                 openFindConnectionsDialog();
                             }
                         }}
                    ><Icon icon={Icons.FIND_CONNECTIONS}/>{strings.findConnections}</div>
                </section>


                {/*DATA*/}

                <section className={"menu-section data-section"}
                         aria-label={strings.dataSection}>

                    <div className={"section-title"}>{strings.data}</div>

                    {!!savedMapId &&
                    <div className={"saved-map-data-title"}>{strings.savedMapData}</div>
                    }

                    {/*SUMMARY*/}
                    {/* When data section is not expanded */}
                    <div className={"menu-data-summary"}>
                        <ul>
                            {!!dsInfo &&
                            <li>{strings.dataTitleOverview
                                .replace("*total_orgs*", dsInfo.total_orgs)
                                .replace("*region*", dsInfo.region.toUpperCase())
                                .replace("*org_type*", dsInfo.org_type)
                                .replace("*year*", dsInfo.year)
                                .replace("*source*", strings[dsInfo.source])
                            }
                            </li>}
                            {!!dataAddOns &&
                            !!useMapStore.getState().dataAddOns.presidential_admins.include &&
                            <li key={"addOnsSummaryPresidents"}>
                                {strings.addOnDataSummaryAdminsOverview}
                            </li>
                            }
                            {!!dataAddOns &&
                            dsInfo.add_ons.map(
                                (addonDS, index) => {
                                    return (!!useMapStore.getState().dataAddOns[addonDS.id].include &&
                                        <li key={"addOnsSummary" + index}>
                                            {strings.addOnDataSummaryOverview.replace("*total_orgs*", addonDS.total_orgs)
                                                .replace("*region*", addonDS.region.toUpperCase())
                                                .replace("*type*", strings[addonDS.type])
                                                .replace("*criteria*", addonDS.criteria)
                                                .replace("*criteria_year*", addonDS.criteria_year)
                                                .replace("*source*", strings[addonDS.source])
                                            }

                                        </li>
                                    )
                                }
                            )

                            }
                        </ul>
                    </div>

                    <div className={"menu-link with-icon"}
                         onClick={onToggleDataDetailsSection}
                         role={"button"}
                         tabIndex={0}
                         aria-label={strings.viewDetails}
                         aria-pressed={!!dataDetailsSectionExpanded}
                         onKeyPress={(e) => {
                             if (e.key === 'Enter') {
                                 onToggleDataDetailsSection();
                             }
                         }}
                    >
                        <Icon icon={Icons.CARET_SM} isOpen={!!dataDetailsSectionExpanded}/>{strings.viewDetails}
                    </div>

                    {!!dataDetailsSectionExpanded &&
                    <div className={"expanded-section-content expanded-data-details-section"}>
                        {/*Main data set details with analytics*/}
                        {getDataDetails(dsInfo)}

                        {/*Presidential data details*/}
                        {!!dataAddOns && !!useMapStore.getState().dataAddOns.presidential_admins.include &&
                        <p>{strings.administrationsSummary}</p>
                        }

                        {/*Add on data details*/}
                        {!!dataAddOns &&
                        dsInfo.add_ons.map(
                            (addonDS, index) => {
                                return (!!useMapStore.getState().dataAddOns[addonDS.id].include &&
                                    <p key={"addOnsDetailsString" + index}>
                                        {strings.addOnDetails.replace("*year*", addonDS.year)
                                            .replace("*type*", strings[addonDS.type])
                                            .replace("*retrieved*", addonDS.retrieved)}
                                    </p>
                                )
                            }
                        )}

                    </div>
                    }


                    <div className={"menu-link with-icon"}
                         onClick={onToggleDataSection}
                         role={"button"}
                         tabIndex={0}
                         aria-label={strings.useDifferentData}
                         aria-pressed={!!dataDetailsSectionExpanded}
                         onKeyPress={(e) => {
                             if (e.key === 'Enter') {
                                 onToggleDataSection();
                             }
                         }}
                    >
                        <Icon icon={Icons.CARET_SM} isOpen={!!dataSectionExpanded}/>{strings.useDifferentData}
                    </div>


                    {!!dataSectionExpanded &&
                    <div className={"expanded-section-content"}>
                        <div className={"section-desc"}>{strings.dataWarning}</div>

                        {/*{ console.log("savedMapId: ", savedMapId) }*/}
                        {/*{ console.log("savedMapDataSet: ", savedMapDataSet) }*/}
                        {savedMapId !== undefined &&
                        <div className={"saved-map-data"}>
                            Saved map's data set<br/><br/>
                            <div className={"menu-link"}
                                 key={"SAVED_" + savedMapDataSet.dataSetInfo.year + "_"
                                 + savedMapDataSet.dataSetInfo.month + "_"
                                 + savedMapDataSet.dataSetInfo.day + "_"
                                 + savedMapDataSet.dataSetInfo.version + "_"
                                 + savedMapDataSet.dataSetInfo.org_type}>
                                <RadioButton
                                    label={<div className={"radio-button-label-container"}>
                                        {
                                            strings.dataTitle
                                                .replace("*total_orgs*", savedMapDataSet.dataSetInfo.total_orgs)
                                                .replace("*region*", savedMapDataSet.dataSetInfo.region.toUpperCase())
                                                .replace("*org_type*", savedMapDataSet.dataSetInfo.org_type)
                                                .replace("*year*", savedMapDataSet.dataSetInfo.year)
                                        }
                                        <div className={"toggle-source"}>
                                            Source: <span
                                            className={"toggle-source-name"}>{strings[savedMapDataSet.dataSetInfo.source]}</span>. &nbsp;
                                        </div>
                                    </div>}
                                    value={dataPeriods.RECENT}
                                    checked={true}
                                    id={DATA_SET_RADIO_ID}
                                    onChange={() => {
                                        onToggleCurrentDataSet(savedMapDataSet.dataSetInfo)
                                    }}
                                />
                                <DataDetails dataSet={savedMapDataSet.dataSetInfo}/>
                                <MenuDataSetSection dataset={savedMapDataSet.dataSetInfo}/> {/*Add ons*/}

                            </div>
                            <hr style={{width: "50%", float: "left"}}/>
                            <br/>
                            More data sets<br/><br/>
                        </div>
                        }


                        {sortedDataSets.map((ds) => {
                            let isSelectedDataSet = ds === useMapStore.getState().datasetInfo;
                            return (
                                <div className={"menu-link"}
                                     key={ds.year + "_" + ds.month + "_" + ds.day + "_" + ds.version + "_" + ds.org_type}>
                                    <RadioButton
                                        label={<div className={"radio-button-label-container"}>
                                            {
                                                strings.dataTitle
                                                    .replace("*total_orgs*", ds.total_orgs)
                                                    .replace("*region*", ds.region.toUpperCase())
                                                    .replace("*org_type*", ds.org_type)
                                                    .replace("*year*", ds.year)
                                            }
                                            <div className={"toggle-source"}>
                                                Source: <span
                                                className={"toggle-source-name"}>{strings[ds.source]}</span>. &nbsp;
                                            </div>
                                        </div>}
                                        value={dataPeriods.RECENT}
                                        checked={isSelectedDataSet}
                                        id={DATA_SET_RADIO_ID}
                                        onChange={() => {
                                            onToggleCurrentDataSet(ds)
                                        }}
                                    />
                                    <DataDetails dataSet={ds}/>

                                    {isSelectedDataSet && <MenuDataSetSection dataset={ds}/>}


                                </div>
                            )

                        })}

                    </div>}

                </section>

                {/*ABOUT*/}

                <section className={"menu-section"} aria-label={strings.aboutSection}>
                    <div className={"section-title"}>{strings.about}</div>
                    <div className={"menu-link with-icon"} onClick={()=>{window.open("../about", '_blank');}}><Icon icon={Icons.INFO}/>{strings.aboutTheyRule}</div>
                    <div className={"menu-link with-icon"} onClick={()=>{window.open("../credits", '_blank');}}><Icon icon={Icons.THANKS}/>{strings.credits}</div>
                </section>


                {/*CONTROLS*/}
                <section className={"menu-section"} aria-label={strings.controlsSection}>
                    <div className={"section-title"}>{strings.controls}</div>
                    <div className={"menu-item with-icon"}>{isMobile?strings.autoModeMobile:strings.autoMode}</div>
                    <div className={"menu-item with-icon"}><Icon icon={Icons.PAN_ARROWS} strokeWidth={"1"} />{isMobile?strings.panModeMobile:strings.panMode}</div>
                    <div className={"menu-item with-icon"}><Icon icon={Icons.ROTATE_ARROWS} strokeWidth={"1"}/>{isMobile?strings.rotateModeMobile:strings.rotateMode}</div>
                    {isMobile?<div className={"menu-item with-icon"}><Icon icon={Icons.CLOSE}/>{strings.deleteOptMobile}</div>:<div className={"menu-item with-icon"}><Icon icon={Icons.CLOSE}/>{strings.deleteOpt1}<br/>{strings.deleteOpt2}</div>}
                    <div className={"menu-item with-icon"}><Icon icon={Icons.ZOOM}/>{isMobile?strings.zoomInOutMobile:strings.zoomInOut}</div>
                    {!isMobile && <div className={"menu-item with-icon"}><Icon icon={Icons.FIX_CAMERA}/>{strings.fixCamera}</div>}
                </section>



                    {/*Report Map*/}

                    {savedMapId !== undefined &&
                    <section className={"menu-section"} aria-label={strings.reportMap}>
                        <div className={"section-title"}>{strings.reportMap}</div>
                        <div>{strings.reportInappropriate}</div>
                    </section>}

                        </div>
            </nav>
        </div>

        </div>}
    </>
}

function DataDetails ({dataSet, detailsString}) {
    const [showDetails, setShowDetails] = useState(false);

    // console.log("DataDetails dataSet: ", dataSet);

    return (
        <div className={"data-details"}>
            <div className={"details-link" + (showDetails?" open":" closed")} onClick={()=>{setShowDetails(!showDetails)}}>View details <Icon icon={Icons.CARET} strokeWidth={2} /></div>
            {showDetails && dataSet !== undefined &&
            <div className={"data-details-summary"}>
                {strings.dataSummary
                    .replace("*total_orgs*", dataSet.total_orgs)
                    .replace("*org_type*", dataSet.org_type)
                    .replace("*criteria*", dataSet.criteria)
                    .replace("*year*", dataSet.year)
                    .replace("*source*", strings[dataSet.source])
                    .replace("*retrieved*", dataSet.retrieved)
                }<br/>
                {strings.dataAnalytics
                    .replace("*average_board_size*", dataSet.average_board_size)
                    .replace("*average_connections*", dataSet.average_connections)
                    .replace("*percent_in_clusters*", Math.round((dataSet.total_orgs-dataSet.num_isolates)/dataSet.total_orgs * 100))
                    .replace("*num_clusters*", dataSet.num_clusters)
                    .replace("*clusters*", dataSet.num_clusters === 1? strings.cluster : strings.clusters)
                    .replace("*percent_women*", Math.round(dataSet.percent_women))
                }
                {dataSet.notes !== "" && <div>{dataSet.notes} &nbsp;</div>}

            </div>
            }
            {showDetails && detailsString !== undefined &&
            <div className={"data-details-summary"}>{detailsString}</div>
            }
        </div>
    );
}

export default Menu;