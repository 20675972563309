// src/components/Chair/Chair.jsx
import React, { useState, Suspense} from "react";
import { Canvas, useFrame } from '@react-three/fiber';
import ChairModel from '../../models/Chair/ChairModel.jsx'
import "./Chair.scss";
import {isMobile} from "react-device-detect";
import {strings} from "../../data/strings";

let timeDelta = 0;
let time = 0;
let timeStart = new Date().getTime();
let degreesPerSecond = 500;
let yRotation = 9.0;
let friction = .99;

let resetAnimationTime = () => (degreesPerSecond += 100);


function ChairControls (){

    const [chairRotation, setChairRotation] = useState([0, 0, 0]);

    useFrame(() => {
        if (degreesPerSecond > 0) {
            degreesPerSecond = Math.min(1000, Math.max(15, degreesPerSecond * friction));
            const degrees = (degreesPerSecond * (timeDelta/1))%360;
            const radians =  degrees * Math.PI/180
            yRotation = (yRotation + radians) % 6.28;
            setChairRotation([0,yRotation,0]);
            if(degreesPerSecond < 41 && yRotation < .5 && yRotation > .3 ){
                degreesPerSecond = 0;
            }
            const newTime = (new Date().getTime() - timeStart) / 1000;
            timeDelta = newTime - time;
            time = newTime;
        }
    });

    return (
        <mesh
            rotation = {chairRotation}
        >
            <Suspense>
                <ChairModel scale={[.2,.2,.2]} position={[0,-.5,0]} />
            </Suspense>
            <meshStandardMaterial color={0xd11d1d} />

        </mesh>
    )

}


function Chair ({screen, onChairClicked, ...props}) {

    // console.log("chair render, screen: ", screen);

    let chairSize = "reg";
    let zoom = 50;
    switch (screen) {
        case "top-search":
        case "about":
        case "menu":
            if(isMobile){
                chairSize = "sm";
                zoom = 35;
            } else {
                chairSize = "reg";
            }
            break;
        case "placeholder":
            chairSize = "lg";
            zoom = 70;
            break;
        default:
        case "intro":
            chairSize = "reg";
            break;
    }

    let ap = {};
    switch (screen) {
        case "menu":
            ap = {"aria-pressed": true};
            break;
        case "top-search":
            ap = {"aria-pressed": false};
            break;
        default:
            break;
    }

    return(
    <div className={`Chair ` + chairSize + " " + screen  + (isMobile?" mobile":"")}
         onClick={(e) => {resetAnimationTime(); if(onChairClicked){onChairClicked();}}}
         onPointerDown={resetAnimationTime}
         role={screen==="intro"?"image":"button"}
         aria-label={screen==="intro"?strings.spinningChairImage:strings.chairMenuIcon}
        {...ap}
         tabIndex={0}
         onKeyPress={(e) => {resetAnimationTime(); if(onChairClicked){onChairClicked();}}}
    >
        <Canvas  orthographic camera={{ zoom: zoom, position: [0, 10, 100] }} dpr={[1, 2]} >
            <Suspense fallback={null}>
                <ambientLight intensity={.7} />
                <spotLight position={[10, 10, 10]} angle={0.15} penumbra={1} />
                <ChairControls />
            </Suspense>
        </Canvas>
    </div>
    );
}

export default Chair;