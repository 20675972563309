import LocalizedStrings from 'react-localization';

export const strings = new LocalizedStrings({
    en:{
        searchPlaceholder:"Find a company or director",
        introText1:"They sit on the boards of the largest U.S. corporations",
        introText2:"They make decisions that affect our lives",
        theyRule:"They Rule",
        playAutomatically:"Play automatically",
        resumePlay: "Resume Play",
        explore:"Explore",
        playAgain: "Play again",
        summary:"This path includes *num_comps* companies connected by *num_dirs* *dirs* between *comp1* and *comp2*. To find more connections click explore below.",
        director:"director",
        directors:"directors",
        organizations:"organizations",
        topCompanies: "Top *area* companies by *year* revenue",
        mostConnectedDirectors: "Most connected directors",
        us: "US",
        areYouSure: "Are you sure?",
        areYouSureClearMap: "Do you want to clear your map?",
        yesClear: "Yes, clear map",
        autoPlayClearsMap: "Playing automatically will clear the existing map.",
        changingDataClearsMap: "Changing this data will clear the existing map.",
        cancel:"Cancel",
        yesPlay:"Yes, play",
        yesChange:"Yes, change",
        map:"Map",
        copyMap:"Link to map",
        clearMap:"Clear map",
        printMap:"Print map",
        connect:"Connect",
        findConnections:"Find connections",
        findConnectionChallenge:"Find connection challenge",
        data:"Data",
        dataTitle:"Top *total_orgs* *region* *org_type* (*year*)",
        dataTitleOverview:"Top *total_orgs* *region* *org_type* (*year*) — *source*",
        dataSummary:"The top *total_orgs* *org_type* ranked by *criteria* in *year* as retrieved from *source* on *retrieved*.",
        addOnDataSummary:"Include top *total_orgs* *region* *type* by *criteria* in *criteria_year*",
        addOnDataSummarySavedMap:"Includes top *total_orgs* *region* *type* by *criteria* in *criteria_year*",
        addOnDataSummaryOverview:"Top *total_orgs* *region* *type* by *criteria* (*criteria_year*) — *source*",
        addOnDetails:"Showing board members from these *type* in *year* as retrieved from Wikidata on *retrieved*.",
        addOnDataSummaryAdmins: "Include recent US presidential administrations",
        addOnDataSummaryAdminsSavedMap: "Includes recent US presidential administrations",
        addOnDataSummaryAdminsOverview: "Recent US presidential administrations — Wikidata",
        thinktanks:"think-tanks",
        revenue: "revenue",
        savedMapToastTitle: "Map details",
        savedMapDataIntro: "This map was created in *created* using data for the top *total_orgs* *region* *org_type* in *year* by *criteria*",
        savedMapContainsAddOns: ",*year*",
        commaSeparator: ", ",
        andSeparator: ", and ",
        savedMapAddOnBulletPoint: "the top *total_orgs* *region* *type* by *criteria* in *criteria_year*",
        savedMapAdminBulletPoint: "recent US presidential administrations",
        savedMapSources: "Sources: *sources*.",
        savedMapSource: "Source: *sources*.",
        savedMapDate: "Created *date*.",
        searchSuggestionsTopAddOns: "Top *region* *org_type* by *criteria*",
        dataAnalytics:"The average board has *average_board_size* directors and is connected to an average of *average_connections* other boards in the data set. *percent_in_clusters*% of companies are connected in *num_clusters* *clusters*. *percent_women*% of the directors are women.",
        dataAnalyticsSize:"The average board has *average_board_size* directors.",
        dataAnalyticsConnected:"The average board is connected to an average of *average_connections* other boards in the data set.",
        dataAnalyticsWomen:"*percent_women*% of the directors are women.",
        dataAnalyticsClusters:"*percent_in_clusters*% of companies are connected in *num_clusters* *clusters*.",
        dataAnalyticsIsolates:"*percent_isolates*% of companies do not share directors with other companies in the data set.",
        cluster: "cluster",
        clusters: "clusters",
        dataWarning: "Note: changing the data source may clear your current map.",
        useRecentData:"Use most recent data",
        topCompsByRev:"Top *num-orgs* *region* *org-type* in *year* by *criteria*",
        companies:"companies",
        source:"Source: ",
        useHistorical:"Use historical data",
        includeAdmins:"Include recent US presidential administrations",
        includeThinktanks:"Include top 10 influential US think-tanks",
        includeCharities:"Include top 10 US charitable foundations by endowment",
        about:"About",
        aboutTheyRule:"About They Rule",
        support:"Support",
        addWikidata:"Add data to Wikidata",
        buyPoster:"Buy a poster",
        contact:"Contact",
        they_rule: "They Rule", // Used for source from a constant - that is why it is dif casing
        wikidata: "Wikidata",
        tr2001Notes: "This is the data from the original They Rule. It was gathered from websites and annual reports in 2001. There may be some data issues as it was updated contemporaneously with feedback from users, and directors were matched on the basis of their names.",
        administrationsSummary: "The US presidential administrations from 2001 to present.",
        mostConnectedCompanies: "Most connected companies",
        boardsWithMostWomen: "Boards with highest % women",
        boardsWithLeastWomen: "Boards with lowest % women",
        presidentialAdministrations: "US administrations",
        searchResultsTitleNoQuery:"Explore the boards of the top *total_orgs* *region* *org_type* in *year*",
        theyRuleSummary: "A tool for showing boardroom connections between corporations",
        wikipedia: "Wikipedia",
        wikipediaEnglish: "Wikipedia (English)",
        loadingSavedMap: "Loading a saved map",
        linkToThisMap: "Link to this map",
        creatingLink: "Creating a link to this map",
        ok: "OK",
        copiedToClipboard: "Copied to clipboard",
        error: "Error",
        officialWebsite: "Official website",
        sorryErrorCreatingLink: "Sorry, there was an error creating a link to this map.",
        month1: "January",
        month2: "February",
        month3: "March",
        month4: "April",
        month5: "May",
        month6: "June",
        month7: "July",
        month8: "August",
        month9: "September",
        month10: "October",
        month11: "November",
        month12: "December",
        placeOfBirth: "Place of birth",
        education: "Education",
        moreInfo: "More info",
        cspan: "C-SPAN",
        facebook: "Facebook",
        freebase: "Freebase",
        googleNews: "Google News",
        IMDB: "IMDB",
        instagram: "Instagram",
        libraryThing: "Library Thing",
        linkedIn: "LinkedIn",
        littleSis: "LittleSis",
        NYT: "New York Times",
        reddit: "Reddit",
        TED: "TED",
        twitter: "Twitter",
        youTube: "YouTube",
        libraryOfCongress: "Library of Congress",
        searchMore: "Search suggestions",
        usingWikidata: "Using data from Wikidata",
        usingWikidataAndAddInfo: "This page uses data from Wikidata, where you can add or edit information on ",
        motto: "Motto",
        founded: "Founded",
        employees: "Employees",
        ceo: "CEO",
        chair: "Chairperson",
        currentBoard: "Current board",
        since: "since",
        currentBoardNote: "* This is the current board as listed on Wikidata and may vary from the board on the map which is from *date*.",
        totalRevenue: "Total revenue",
        ownersInclude:"Owners include",
        owns: "Owns",
        useDifferentData: "Use different data",
        viewDetails: "View details",
        savedMapData: "Saved map data:",
        resetToDefaultData: "It will reset to the default data set.",
        findConnection: "Find connection",
        from: "From",
        to: "To",
        findConnectionWillClearMap: "Finding a connection will clear your map.",
        yesFindConnection: "Yes, find connection",
        noConnectionFound: "No connection found",
        noConnectionFoundAB: "No connection was found between *a* and *b* in this data set.",
        mapLoadError: "Could not load map",
        mapLoadErrorDetails: "Sorry, the attempt to load this saved map failed.",
        downloadPdf: "Download PDF",
        generatingPdf:"Generating a PDF of your map",
        downloadingPdfTools:"Downloading PDF Tools...",
        TheyRulePdf: "A PDF for printing",
        aPdf: "Download a PDF of your map",
        chooseOrgs: "Choose two organizations to find connections between.",
        introScreen: "Intro screen",
        expand: "Expand",
        infoAbout: "Info about",
        delete: "Delete",
        theyRuleMap: "They Rule map",
        searchSection: "Search section",
        closeSearch: "Close search",
        closeInfoPanel: "Close info panel",
        unconnectedDirector: "*director name* is not on any other boards in this data set",
        spinningChairImage: "An interactive animation of a spinning office chair",
        chairMenuIcon: "Main menu icon",
        mainMenu: "Main Menu",
        closeMenu: "Close menu",
        mapSection: "Map section",
        dataSection: "Data section",
        aboutSection: "About They Rule section",
        togglePanRotate: "Toggle rotate controls",
        unlockPanRotateControls: "Unlock pan and rotate controls",
        PanRotateAutoModeEnabled: "Map controls unlocked - auto mode enabled",
        PanRotateAutoModeDisabled: "Map controls locked to *mode*",
        pan: "pan",
        rotate: "rotate",
        controlsSection: "Controls section",
        controls: "Controls",
        panMode: "Pan: drag map in pan mode (p)",
        panModeMobile: "Pan: drag map in pan mode",
        rotateMode: "Rotate: drag map in rotate mode (r)",
        rotateModeMobile: "Rotate: drag map in rotate mode",
        autoMode: "By default dragging pans the map. When an item is selected dragging rotates the map. (a)",
        autoModeMobile: "By default dragging pans the map. When an item is selected dragging rotates the map.",
        deleteOpt1: "Delete: Right click an item to see delete button,",
        deleteOpt2: "or select an item and press (delete)",
        deleteOptMobile: "Delete: long press an item to show delete button",
        zoomInOut: "Zoom: Mouse wheel or in (+) and out (-)",
        zoomInOutMobile: "Zoom: pinch to zoom",
        fixCamera: "Fix camera after drag, on ([) and off (])",
        reportMap: "Report Map",
        reportInappropriate: "Email josh@theyrule.net to report anything inappropriate - please include the URL! Thanks.",
        screenshotTR2001: "A screenshot of the original They Rule, 2001",
        screenshotTR2004: "A screenshot of a map created in the 2004 version of They Rule",
        screenshotTR2011: "A screenshot of a map created in the 2011 version of They Rule, showing the G.W. Bush Administration's corporate connections since leaving office.",
        screenshotTR2022: "A screenshot of a map created in the 2022 version of They Rule, showing the Obama Administration's corporate connections since leaving office.",
        credits: "Credits",
        saveMapAgain: "Create a new link to save any changes",
        firstNodeToastCopy: "Click on the *node_type* icon to start exploring the interlocking boards of the top *num_comps* companies in the *region*."
    }
});

strings.setLanguage("en");
