import React, {useEffect, useState} from "react";
import "./IntroScreen.scss";
import Chair from "../Chair";
import SearchBar from "../Searchbar";
import UseInterval from '../../hooks/useIntervalHook';
import {strings} from "../../data/strings";
import useViewStore from "../../data/view_store";
import {ViewModes} from "../../data/constants";
import PlayIcon from "../../images/icons/play_button.svg";
import useMapStore from "../../data/map_store";

function IntroScreen () {

    // console.log("render show intro");

    const {setCurrentDataSet, startAutoPlay, getDefaultDataSetInfo} = useMapStore((state) => state.actions)
    const setViewMode = useViewStore((state) => state.actions.setViewMode);

    const introText = [
        {key: 0, txt: strings.introText1},
        {key: 1, txt: strings.introText2},
        {key: 2, txt: strings.theyRule}
    ];

    const [introInterval, setIntroInterval] = useState(2000);
    const [introTextIndex, setIntroTextIndex] = useState(0);
    const maxIndex = introText.length -1;
    const [dataLoaded, setDataLoaded] = useState(false);


    const chairClicked = () => {
        //console.log("chair clicked");
        setIntroTextIndex(0);
        if(introInterval === 2000) {
            // stops it doing a short interval for the first text
            setIntroInterval(2001);
        } else {
            setIntroInterval(2000)
        }
    }

    /*
        LISTEN FOR DATA LOADED
        Listen for changes in data loaded status
    */
    useEffect(()=>{
        const unsub = useMapStore.subscribe(
            (state) => (state.data_loaded),
            setDataLoaded
        )
        // console.log("Intro Screen dataLoaded", useMapStore.getState().data_loaded);
        if(useMapStore.getState().data_loaded){
            setDataLoaded(true);
        }
        return () => {
            unsub();
        };
    }, [setDataLoaded])

    // INIT THE LOADING OF DATA - IT ALL STARTS HERE
    if (!useMapStore.getState().data_loaded && useMapStore.getState().datasetInfo.length === 0){
        // console.log("Intro LOAD DATA");
        setCurrentDataSet(getDefaultDataSetInfo());
    }


    // The timer controlling the introText
    UseInterval(() => {
        // console.log("UseInterval introTextIndex: ", introTextIndex);
        setIntroTextIndex(Math.min(introTextIndex + 1, maxIndex));
        if(introTextIndex === maxIndex){
            setIntroInterval(null);
        };
    }, introInterval);

    const onClose = () => {
        setViewMode(ViewModes.MAP)
    }

    const playAutomatically = () => {
        // console.log("play automatically");
        startAutoPlay();
        onClose();
    }

    const introTextHTML = introText.map(
        item => (
            <h1 key={item.key} className={["introText", introTextIndex === item.key ? "introTextVisible" : "", item.key !== 2? "subhead":"mainhead"].join(" ")}>{item.txt}</h1>
        )
    );

    // console.log("Intro rerender");

    return (
        <section className="IntroScreen" aria-label={strings.introScreen}>
            <Chair screen={"intro"} onChairClicked={chairClicked} />
            <div className={"introTextWrap"}>{introTextHTML}</div>
            <div className={'bodytext'}>
                <SearchBar onClose={onClose} dataLoaded={dataLoaded}/>
                <div className={"play-button" + (dataLoaded?"":" hidden")}
                     alt={strings.playAutomatically}
                     onClick={playAutomatically}
                     role={"button"}
                     tabIndex={0}
                     aria-label={strings.playAutomatically}
                     onKeyPress={(e) => {
                         if (e.key === 'Enter') {
                             playAutomatically();
                         }
                     }
                     }
                >
                    <img className={"play-icon"} src={PlayIcon} alt={"play icon"}/>
                    {strings.playAutomatically}
                </div>
            </div>
        </section>
    );
}

export default IntroScreen;