import React, {useEffect, useState} from "react";
import "./InfoPanel.scss";
import {isMobile} from "react-device-detect";
import {strings} from "../../data/strings";
import sanitizeHtml from 'sanitize-html';
import useMapStore from "../../data/map_store";

function InfoPanelOrg({wikidataID, isStandAlonePage}) {

    const [orgName, setOrgName] = useState(undefined);
    const [imageURL, setImageURL] = useState(undefined);
    const [alias, setAlias] = useState(undefined);
    const [officialName, setOfficialName] = useState(undefined);
    const [itemDesc, setItemDesc] = useState(undefined);
    const [employees, setEmployees] = useState(undefined);
    const [inceptionYear, setInceptionYear] = useState(undefined);
    const [ceo, setCeo] = useState(undefined);
    const [ceoId, setCeoId] = useState(undefined);
    const [chair, setChair] = useState(undefined);
    const [chairId, setChairId] = useState(undefined);
    const [website, setWebsite] = useState(undefined);
    const [motto, setMotto] = useState(undefined);
    const [cspan, setCspan] = useState(undefined);
    const [facebook, setFacebook] = useState(undefined);
    const [freebase, setFreebase] = useState(undefined);
    const [googleNews, setGoogleNews] = useState(undefined);
    const [IMDB, setIMDB] = useState(undefined);
    const [instagram, setInstagram] = useState(undefined);
    const [libraryThing, setLibraryThing] = useState(undefined);
    const [linkedIn, setLinkedIn] = useState(undefined);
    const [littleSis, setLittleSis] = useState(undefined);
    const [NYT, setNYT] = useState(undefined);
    const [reddit, setReddit] = useState(undefined);
    const [TED, setTED] = useState(undefined);
    const [twitter, setTwitter] = useState(undefined);
    const [youTube, setYouTube] = useState(undefined);
    const [wikipedia, setWikipedia] = useState(undefined);
    const [wikipediaSummary, setWikipediaSummary] = useState(undefined);
    const [useWikipediaSummary, setUseWikipediaSummary] = useState(true);
    const [LOC, setLOC] = useState(undefined);
    const [hasInfoSite, setHasInfoSite] = useState(false);
    const [pageLink] = useState(window.location.origin + "/org/" + wikidataID );
    const [board, setBoard] = useState(undefined);
    const [revenue, setRevenue] = useState(undefined);
    const [normalizedRevenue, setNormalizedRevenue] = useState(undefined);
    const [owners, setOwners] = useState(undefined);
    const [owns, setOwns] = useState(undefined);

    const infoSites = {
        P10660: {g: cspan, s: setCspan, str: "cspan"},
        P2013: {g: facebook, s: setFacebook, str: "facebook"},
        P646: {g: freebase, s: setFreebase, str: "freebase"},
        P5337: {g: googleNews, s: setGoogleNews, str: "googleNews"},
        P345: {g: IMDB, s: setIMDB, str: "IMDB"},
        P2003: {g: instagram, s: setInstagram, str: "instagram"},
        P244: {g: LOC, s: setLOC, str: "libraryOfCongress"},
        P7400: {g: libraryThing, s: setLibraryThing, str: "libraryThing"},
        P6634: {g: linkedIn, s: setLinkedIn, str: "linkedIn"},
        P3388: {g: littleSis, s: setLittleSis, str: "littleSis"},
        P3221: {g: NYT, s: setNYT, str: "NYT"},
        P4265: {g: reddit, s: setReddit, str: "reddit"},
        P2611: {g: TED, s: setTED, str: "TED"},
        P2002: {g: twitter, s: setTwitter, str: "twitter"},
        P2397: {g: youTube, s: setYouTube, str: "youTube"},
    }

    const searchableSites = [

        {
            site: "Common Dreams",
            searchURL: 'https://www.commondreams.org/search?keywords="*id*"',
        },
        {
            site: "Democracy Now!",
            searchURL: 'https://www.democracynow.org/search?query="*id*"&commit=Search',
        },
        {
            site: "Eyes on the Ties",
            searchURL: "https://news.littlesis.org/?s='*id*'&submit=Search"
        },
        {
            site: "The Guardian",
            searchURL: 'https://www.google.com/search?q=site%3Ahttps%3A%2F%2Fwww.theguardian.com+"*id*"'
        },
        {
            site: "LittleSis",
            searchURL: 'https://littlesis.org/search?q="*id*"',
        },
        {
            site: "The Nation",
            searchURL: "https://www.thenation.com/?s='*id*'"
        },
        {
            site: "ProPublica",
            searchURL: 'https://www.propublica.org/search?qss="*id*"',
        },
        {
            site: "Reveal",
            searchURL: 'https://revealnews.org/?s="*id*"',
        }

    ];

    class SPARQLQueryDispatcher {
        constructor(endpoint) {
            this.endpoint = endpoint;
        }

        query(sparqlQuery) {
            const fullUrl = this.endpoint + '?query=' + encodeURIComponent(sparqlQuery);
            const headers = {'Accept': 'application/sparql-results+json'};

            return fetch(fullUrl, {headers}).then(body => body.json());
        }
    }

    // Queries in Wikidata Query builder
    // Basic details: t.ly/2GRt
    // More info: https://w.wiki/5VyU
    // get current board: t.ly/R3yc
    // get the revenue: https://w.wiki/5Vz5
    // get the owned by  https://w.wiki/5WCq
    // get the owner of https://w.wiki/5WBd

    const endpointUrl = 'https://query.wikidata.org/sparql';


    const sparqlQueryOrgData = `SELECT ?itemLabel ?officialName ?employees ?ceo ?ceoLabel ?chair ?chairLabel (GROUP_CONCAT(DISTINCT ?altNames; SEPARATOR = ";") AS ?aliases) ?inception ?itemDesc ?motto ?website ?image ?wikipedia WHERE {
  VALUES ?item {
    wd:`+wikidataID+`
  }
  OPTIONAL {
    ?item skos:altLabel ?altNames.
    FILTER((LANG(?altNames)) = "en")
  }
  {
    OPTIONAL { ?item wdt:P571 ?inception. }
    OPTIONAL { ?item wdt:P154 ?image. }
    OPTIONAL { 
      ?item p:P856 _:official_websites.
      _:official_websites ps:P856 ?website;
      pq:P407 wd:Q1860;
      rdf:type wikibase:BestRank
    }
    OPTIONAL { ?item wdt:P1448 ?officialName. }
    OPTIONAL { ?item wdt:P169 ?ceo. }
    OPTIONAL { ?item wdt:P488 ?chair. }
    OPTIONAL { ?item wdt:P1451 ?motto. }
    OPTIONAL { ?item wdt:P1128 ?employees. }
    SERVICE wikibase:label { bd:serviceParam wikibase:language "en". }
  }
  SERVICE wikibase:label {
    bd:serviceParam wikibase:language "en".
    ?item schema:description ?itemDesc.
  }
  OPTIONAL {
    ?wikipedia schema:about ?item;
      schema:inLanguage "en";
      schema:isPartOf <https://en.wikipedia.org/>.
  }
}
GROUP BY ?itemLabel ?itemDesc ?inception ?image ?website ?wikipedia ?officialName ?ceo ?ceoLabel ?chair ?chairLabel ?motto ?employees`;

    const sparqlQueryOrgMoreInfo = `SELECT ?property ?propertyLabel (GROUP_CONCAT(DISTINCT ?value; SEPARATOR = "|||") AS ?values) WHERE {
  ?property wikibase:propertyType wikibase:ExternalId;
    wikibase:directClaim ?propertyclaim.
  OPTIONAL { ?property wdt:P1630 ?formatterURL. }
  wd:`+wikidataID+` ?propertyclaim ?_value
  BIND(IF(BOUND(?formatterURL), IRI(REPLACE(?formatterURL, "\\\\\$1", ?_value)), ?_value) AS ?value)
  SERVICE wikibase:label { bd:serviceParam wikibase:language "en". }
}
GROUP BY ?property ?propertyLabel`;

    const sparqlQueryOrgBoard = `SELECT ?director ?directorLabel ?start
WHERE {
  wd:`+wikidataID+` p:P3320 ?relationship.
  ?relationship ps:P3320 ?director.
  OPTIONAL {?relationship pq:P580 ?start.}
  FILTER NOT EXISTS {?relationship pq:P582 ?end.}
  SERVICE wikibase:label { bd:serviceParam wikibase:language "[AUTO_LANGUAGE],en". }
}
LIMIT 100`;

const sparqlQueryOrgRevenue = `SELECT ?revenue ?date WHERE {
  wd:`+wikidataID+`  p:P2139 ?relationship.
  ?relationship ps:P2139 ?revenue.
  OPTIONAL { ?relationship pq:P585 ?date. }
}
LIMIT 100`;

const sparqlQueryOrgOwnedBy = `SELECT ?owner ?ownerLabel ?proportion ?unitLabel ?date WHERE {
  wd:`+wikidataID+` p:P127 ?relationship.
  ?relationship ps:P127 ?owner;
    pq:P1107 ?proportion;
  OPTIONAL {
    ?relationship pqv:P1107 ?v.
    ?v wikibase:quantityUnit ?unit.
  }
  OPTIONAL { ?relationship pq:P585 ?date. }
  SERVICE wikibase:label { bd:serviceParam wikibase:language "en". }
}
ORDER BY (?date)
LIMIT 100`;

const sparqlQueryOrgOwns = `SELECT ?org ?orgLabel WHERE {
  wd:`+wikidataID+` p:P1830 ?relationship.
  ?relationship ps:P1830 ?org.
  SERVICE wikibase:label { bd:serviceParam wikibase:language "en". }
}
ORDER BY ?orgLabel
LIMIT 200`;





   const getWikipediaSummary =(wikipediaURL) => {
       //console.log("Wikipedia value", wikipediaURL);
       let pageTitle = wikipediaURL.split("/").pop();
       let fullUrl = "https://en.wikipedia.org/w/api.php?format=json&action=query&origin=*&prop=extracts&exintro&redirects=1&titles=" + pageTitle;
       // let headers = {'Accept': 'application/sparql-results+json'};
       return fetch(fullUrl).then(body => body.json());
   }


    const onOrgDataLoaded = (result) => {
        //console.log("sparqlQueryOrgData: ", sparqlQueryOrgData);
        //console.log("result: ", result);
        let data = result.results.bindings[0];
        if(!data) return;
        //console.log("data: ", data);
        setOrgName(data["itemLabel"].value);
        //console.log("data[\"itemLabel\"].value: ", data["itemLabel"].value);

        if(data.image && data.image.value){
            setImageURL(data.image.value);
        }
        if(!!data.aliases && !!data.aliases.value){
            setAlias(data.aliases.value.split(";").reduce(
                function (a, b) {
                    return a.length > b.length ? a : b;
                }));
        }
        if(data.officialName && data.officialName.value){
            setOfficialName(data.officialName.value);
        }


        if(data.employees && data.employees.value){
            setEmployees(data.employees.value);
        }

        if(data.inception && data.inception.value){
            setInceptionYear(new Date(data.inception.value).getFullYear());
        }

        if(data.ceo && data.ceo.value && data.ceoLabel && data.ceoLabel.value){
            setCeo(data.ceoLabel.value);
            setCeoId(data.ceo.value);
        }

        if(data.chair && data.chair.value && data.chairLabel && data.chairLabel.value){
            setChair(data.chairLabel.value);
            setChairId(data.chair.value);
        }

        if(data.motto && data.motto.value){
            setMotto(data.motto.value);
        }


        if(data.itemDesc && data.itemDesc.value){
            setItemDesc(data.itemDesc.value);
        }

        if(data.website && data.website.value){
            setWebsite(data.website.value);
        }

        if(data.wikipedia && data.wikipedia.value){
            getWikipediaSummary(data.wikipedia.value).then(onWikipediaSummaryLoaded)
            setWikipedia(data.wikipedia.value);
        } else {
            setUseWikipediaSummary(false); // will use the wikidata summary
        }


    }

    const onWikipediaSummaryLoaded=(result)=>{
       //console.log("onWikipediaSummaryLoaded: ", result);
       if(result && result.query && result.query.pages){
           let pages = Object.values(result.query.pages);
           if(pages && pages[0] && pages[0].extract){
               setWikipediaSummary(pages[0].extract);
           } else {
               setUseWikipediaSummary(false);
           }
       } else {
           setUseWikipediaSummary(false);
       }
    }




    const onOrgMoreInfoLoaded = (result) => {
        //console.log("mi sparqlQueryOrgMoreInfo: ", sparqlQueryOrgMoreInfo);
        //console.log("mi result: ", result);
        let data = result.results.bindings;
        if (!data) return;
        ///console.log("mi data: ", data);
        let miId, miLink;
        data.forEach((mi)=>{
            miId = mi.property.value.split("http://www.wikidata.org/entity/")[1];
            miLink = mi.values.value.split("|||")[0];
            //console.log("mi " , miId, miLink);
            if(infoSites[miId]){
                //console.log("mi FOUND ", mi);
                infoSites[miId].s(miLink);
                setHasInfoSite(true);
            }
        })
    }

    const onOrgBoardLoaded = (result) => {
        // console.log("board loaded result: ", result);
        let data = result.results.bindings;
        if (!data || data.length < 1) return;
        let board={};
        let dirId;
        data.forEach((dir)=>{
            dirId = dir.director.value.split("/").pop();
            board[dirId] = {name:dir.directorLabel.value, startYear: new Date(dir.start.value).getFullYear()};
        });
        // console.log("board board: ", board);
        setBoard(board);
    }
    
    const onOrgOwnsLoaded = (result) => {
        //console.log("owns loaded result: ", result);
        let data = result.results.bindings;
        if (!data || data.length < 1) return;
        let owns=[];
        let orgId;
        data.forEach((orgInfo)=>{
            orgId = orgInfo.org.value.split("/").pop();
            // if the orgLabel is also a Q number - exclude it
            if(!orgInfo.orgLabel.value.match(/^Q[\d]*$/)) {
                owns.push({name: orgInfo.orgLabel.value, id: orgId});
            }
        });
        //console.log("owns: ", owns);
        setOwns(owns);
    }

    const onOrgRevenueLoaded = (result) => {
        //console.log("revenue loaded result: ", result);
        let data = result.results.bindings;
        if (!data || data.length < 1) return;
        let revenue=[];
        let year;
        let amountStr;
        let amount;
        let currentYear = new Date().getFullYear();
        let numYears = 7;
        let startYear = currentYear - numYears;
        let revObj = {}; // Normalized revenue object
        // Array.from({length: 5}, (v, i) => (currentYear-5) + i).forEach((yr)=>{
        //     revObj[yr] = -1;
        // });

        data.forEach((item)=>{
            //console.log("item, ", item);
            if(!!item.date && !!item.revenue) {
                year = new Date(item.date.value).getFullYear();
                amount = item.revenue.value;
                amountStr = Math.round(item.revenue.value/1000000).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") + " MM";
                // dirId = dir.director.value.split("/").pop();
                // board[dirId] = {name:dir.directorLabel.value, startYear: new Date(dir.start.value).getFullYear()};
                if(year > currentYear - numYears) {
                    revenue.push({year:year, amount:amount, amountStr:amountStr});
                    revObj[year] = amount;
                }
            }
        });

        let ratio = Math.max(...(Object.values(revObj))) / 100;
        //console.log("norm rev: ", Object.values(revObj));
        //console.log("norm ratio: " + ratio);
        let normalized = Object.entries(revObj).map(([yr, amt]) => { return ({index: yr-startYear, year: yr, amt:Math.round(amt / ratio)} )}
        );

        //console.log("normalized: ", normalized);
        setNormalizedRevenue(normalized);


        revenue.sort((a, b) => (a.year > b.year) ? 1 : -1)
        //console.log("revenue: ", revenue);
        setRevenue(revenue);
    }

    const onOrgOwnedByLoaded = (result) => {
        //console.log("owned by result: ", result);
        let data = result.results.bindings;
        if (!data) return;
        let owners={};
        let ownerId;
        let date;
        let ownerLabel;
        let proportion;
        if(data.length > 0) {
            data.forEach((ownerInfo) => {
                ownerId = ownerInfo.owner.value.split("/").pop();
                date = (!!ownerInfo.date ? new Date(ownerInfo.date.value).getFullYear() : undefined);
                ownerLabel = ownerInfo.ownerLabel.value;
                if(!!ownerInfo.unitLabel && ownerInfo.unitLabel.value === "percentage"){
                    // Handle cases when people have entered the proportion as a percentage
                    proportion = ownerInfo.proportion.value;
                } else {
                    proportion = +(Math.round((ownerInfo.proportion.value * 100) + "e+2") + "e-2");
                }
                owners[ownerId] = {ownerId: ownerId, ownerLabel: ownerLabel, proportion: proportion, date: date};
            });
            //console.log("owners: ", owners);
            let ownerArray = Object.values(owners);
            ownerArray.sort((a, b) => (a.proportion < b.proportion) ? 1 : -1)
            setOwners(ownerArray);
        }
    }



    const queryDispatcher = new SPARQLQueryDispatcher(endpointUrl);

    useEffect(() => {
        //console.log("launch queries");
        queryDispatcher.query(sparqlQueryOrgData).then(onOrgDataLoaded);
        queryDispatcher.query(sparqlQueryOrgMoreInfo).then(onOrgMoreInfoLoaded);
        queryDispatcher.query(sparqlQueryOrgBoard).then(onOrgBoardLoaded);
        queryDispatcher.query(sparqlQueryOrgRevenue).then(onOrgRevenueLoaded);
        queryDispatcher.query(sparqlQueryOrgOwnedBy).then(onOrgOwnedByLoaded);
        queryDispatcher.query(sparqlQueryOrgOwns).then(onOrgOwnsLoaded);

        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [wikidataID])


    return <div className={"info-data-org-wrap info-data-content-wrap" + (isMobile ? " mobile" : "")}>
        <div className={"info-header" + (isMobile ? " mobile" : "")}>
            {!!imageURL && <div className={"info-image info-logo"} style={{backgroundImage: "url(" + imageURL +")"}} />}
            <div className={"name-gender-age-box"}>
                {!!orgName && <div className={"info-title"}>{ orgName }</div>}
                {!!officialName && <div className={"info-alias"}>{ officialName }</div>}
                {!!!officialName && !!alias && <div className={"info-alias"}>{ alias }</div>}
            </div>
        </div>

        <div className={"info-content"}>

            {!useWikipediaSummary && !!itemDesc && <div className={"info-section"}>{ itemDesc }</div>}

            {useWikipediaSummary && !!wikipediaSummary && <div className={"info-section"}>



                <div className="wikipedia-summary" dangerouslySetInnerHTML={{__html:sanitizeHtml(wikipediaSummary)}} /> &mdash;  <a href={wikipedia} target={"_blank"} rel={"noreferrer"} >{ strings.wikipedia }</a><br/><br/></div>}

            {(!!wikipedia && !useWikipediaSummary) && <div className={"info-section"}>
                {!useWikipediaSummary && !!wikipedia && <a href={wikipedia} target={"_blank"} rel={"noreferrer"}>{ strings.wikipediaEnglish }</a>}
            <br/><br/></div>}

            {!!website && <div className={"info-section name-value"}>
                <div className={"info-name"}>{strings.officialWebsite}</div>
                <div className={"info-value"}> { <a href={website} target={"_blank"} rel={"noreferrer"}>{ website }</a> }</div>
            </div>}

            {!!inceptionYear && <div className={"info-section name-value"}>
                <div className={"info-name"}>{strings.founded}</div>
                <div className={"info-value"}> { inceptionYear }</div>
            </div>}

            {!!motto && <div className={"info-section name-value"}>
                <div className={"info-name"}>{strings.motto}</div>
                <div className={"info-value"}> { motto }</div>
            </div>}

            {!!employees && <div className={"info-section name-value"}>
                <div className={"info-name"}>{strings.employees}</div>
                <div className={"info-value"}> { employees.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") }</div>
            </div>}

            {!!ceo && !!ceoId && <div className={"info-section name-value"}>
                <div className={"info-name"}>{strings.ceo}</div>
                <div className={"info-value info-link"} >
                    <a href={window.location.origin + "/person/" + ceoId.split("/").pop()} target={isStandAlonePage?"_self":"_blank"}  rel={"noreferrer"}>{ ceo }</a></div>
            </div>}

            {!!chair && !!chairId && <div className={"info-section name-value"}>
                <div className={"info-name"}>{strings.chair}</div>
                <div className={"info-value info-link"} >
                    <a href={window.location.origin + "/person/" + chairId.split("/").pop()} target={isStandAlonePage?"_self":"_blank"} rel={"noreferrer"}>{ chair }</a></div>
            </div>}


            {!!revenue &&<div className={"info-section name-value"}>
                <div className={"info-name"}>{strings.totalRevenue}</div>
                <div className={"info-value"}>
                    {normalizedRevenue.length > 2 && <div className={"info-rev-graph" + (isMobile ? " mobile" : "")}> <svg width="100%" height="100%" viewBox="-3 -10 510 110"  preserveAspectRatio="xMinYMax" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d={normalizedRevenue.map((r, i)=>{
                            return ((i===0?"M":"L") + " " + ((r.index - 1) * 100) + " " + (100 - r.amt))
                        })} stroke={"#999"} strokeWidth={5} strokeLinecap="round" />

                        {normalizedRevenue.map((r, i)=>{
                        return (<circle key={"rev_c"+i} cx={ ((r.index - 1) * 100)} cy={(100 - r.amt)} r="4" fill="#333" />)
                        })}

                    </svg></div>
                    }
                    {
                        revenue.map((item, i) => {
                            return (<div key={"rev" + i} className={"more-info-link"}>
                                    {item.year}&nbsp;&nbsp;&nbsp;&nbsp;${item.amountStr}
                                </div>
                            )
                        })
                    }
                </div>
            </div>
            }


            {!!board &&<div className={"info-section name-value"}>
                <div className={"info-name"}>{strings.currentBoard}{!isStandAlonePage && "*"}</div>
                <div className={"info-value"}>
                    {
                        Object.entries(board).map(([id, dir], i) => {
                            return (<div key={"dir" + i} className={"more-info-link"}>
                                    <a href={window.location.origin + "/person/" + id} target={isStandAlonePage?"_self":"_blank"} rel={"noreferrer"}>{dir.name}</a>, {strings.since} {dir.startYear}
                            </div>
                            )
                        })
                    }
                    {!isStandAlonePage && useMapStore.getState().datasetInfo && <div className={"more-info-link fine-print"}>{strings.currentBoardNote.replace("*date*", useMapStore.getState().datasetInfo.year)}</div>}
                </div>
            </div>
            }

            {!!owners &&<div className={"info-section name-value"}>
                <div className={"info-name"}>{strings.ownersInclude}</div>
                <div className={"info-value"}>
                    {
                        owners.map((info, i) => {
                            return (<div key={"owner" + i} className={"more-info-link"}>
                                    {info.ownerLabel} {info.proportion}% {!!info.date && <span>({info.date})</span>}
                                </div>
                            )
                        })
                    }
                </div>
            </div>
            }

            {!!owns &&<div className={"info-section name-value"}>
                <div className={"info-name"}>{strings.owns}</div>
                <div className={"info-value"}>
                    {
                        owns.map((info, i) => {
                            return (<div key={"owns" + i} className={"more-info-link"}>
                                <a href={window.location.origin + "/org/" + info.id} target={isStandAlonePage?"_self":"_blank"} rel={"noreferrer"}>
                                    {info.name}
                                </a>
                                </div>
                            )
                        })
                    }
                </div>
            </div>
            }




            {!!hasInfoSite &&<div className={"info-section name-value"}>
                <div className={"info-name"}>{strings.moreInfo}</div>
                <div className={"info-value"}>
            {
                Object.values(infoSites).map((info,i) => {
                    return (<div key={"mi" + i} >
                        {!!info.g && <div className={"more-info-link"}><a href={info.g} target={"_blank"} rel={"noreferrer"}>{strings[info.str]}</a></div>}</div>
                    )
                })
            }
                </div>
            </div>
            }

            {!!orgName && <div className={"info-section"}>
                <div className={"info-section name-value"}>
                    <div className={"info-name"}>{strings.searchMore}</div>
                    <div className={"info-value"}>
                        {searchableSites.map((s, i) => {
                            let id = orgName.replace(" ", "+");
                            return (<div key={"s" + i} className={"more-info-link"}><a href={s.searchURL.replace("*id*", id)} target={"_blank"} rel={"noreferrer"}>{s.site}</a></div>)
                        })}
                    </div>
                </div>
            </div>}

            <br/>
            <br/>

            <div className={"info-section name-value"}>
                <div className={"info-name wikidata-attribution"}>
                    <a className={"wikidata-attribution"} href={"https://www.wikidata.org/wiki/" + wikidataID}
                       target={"_blank"} rel={"noreferrer"}>
                        <div className={"wikidata-image"} alt="Wikidata-logo-en"/>
                    </a>
                </div>
                <div className={"info-value"}>{strings.usingWikidataAndAddInfo}<a href={"https://www.wikidata.org/wiki/" + wikidataID } target={"_blank"} rel={"noreferrer"}>{ orgName }</a>.</div>
            </div>

            <div className={"info-section fine-print info-link"} >
                Link to this page: <a href={pageLink} target={"_blank"} rel={"noreferrer"}>{pageLink}</a>
            </div>

        </div>
    </div>
}

export default InfoPanelOrg;