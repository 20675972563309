import create from 'zustand';
import { subscribeWithSelector } from "zustand/middleware";
import { immer } from "zustand/middleware/immer";

const dialogStore = (set, get) => ({

    show_dialog: false,
    title: "default title",
    body: "Default body",
    tertiary: null,
    tertiary_action: null,
    secondary: null,
    secondary_action: null,
    primary: null,
    primary_action: null,
    actions: {
        clearDialog(){
            set(state => {
                    state.title = null;
                    state.body = null;
                    state.tertiary = null;
                    state.tertiary_action = null;
                    state.secondary = null;
                    state.secondary_action = null;
                    state.primary = null;
                    state.primary_action = null;
                }
            );
        },
        showDialog(bool){
            set(state => {state.show_dialog = bool});
        },
        onCloseDialog() {
            set(state => {state.show_dialog = false});
        },
        setTitle(str){
            set(state => {state.title = str});
        },
        setBody(str){
            set(state => {state.body = str});
        },
        setTertiary(str){
            set(state => {state.tertiary = str});
        },
        setSecondary(str){
            set(state => {state.secondary = str});
        },
        setPrimary(str){
            set(state => {state.primary = str});
        },
        setTertiaryAction(action){
            //console.log("SET TERTIARY ACTION", action)
            set(state => {state.tertiary_action = action});
        },
        setSecondaryAction(action){
            set(state => {state.secondary_action = action});
        },
        setPrimaryAction(action){
            set(state => {state.primary_action = action});
        }
    }
});


const useDialogStore = create(
    subscribeWithSelector(
        immer(dialogStore),
    )
)

export default useDialogStore;