import './App.scss';
import {
    BrowserRouter as Router,
    Route, Routes
} from "react-router-dom";
import React from "react";

import Home from './pages/Home';
import Placeholder from './pages/Placeholder';
import useMapStore from "./data/map_store";

const Presentations = React.lazy(() => import('./pages/Presentations'));
const SoWhat = React.lazy(() => import('./pages/About/SoWhat'));
const Resources = React.lazy(() => import('./pages/About/Resources'));
const Admin = React.lazy(() => import('./pages/Admin'));
const AdminAnalysis = React.lazy(() => import('./pages/Admin/AdminAnalysis'));
const AdminPresidents = React.lazy(() => import('./pages/Admin/AdminPresidents'));
const AdminThinktanks = React.lazy(() => import('./pages/Admin/AdminThinktanks'));
const AdminCharities = React.lazy(() => import('./pages/Admin/AdminCharities'));
const About = React.lazy(() => import('./pages/About/About'));
const Credits = React.lazy(() => import('./pages/About/Credits'));
const Handout = React.lazy(() => import('./pages/About/Handout'));

function App(props) {
    useMapStore.setState({config: props._config});
    return (
        <div className="App">
            <Router>
                <Routes>
                    <Route exact path="/" element={<Home/>}/>
                    {/*<Route exact path="/" element={<Placeholder/>}/>*/}
                    <Route exact path="/beta" element={<Home/>}/>
                    <Route path="/map/:id" element={<Home/>}/>
                    <Route path="/person/:personInfoId" element={<Home/>}/>
                    <Route path="/org/:orgInfoId" element={<Home/>}/>
                    <Route exact path="/admin" element={<Admin/>}/>
                    <Route exact path="/admin/analysis" element={<AdminAnalysis/>}/>
                    <Route exact path="/admin/presidents" element={<AdminPresidents/>}/>
                    <Route exact path="/admin/thinktanks" element={<AdminThinktanks/>}/>
                    <Route exact path="/admin/charities" element={<AdminCharities/>}/>
                    <Route exact path="/about" element={<About/>}/>
                    <Route exact path="/credits" element={<Credits/>}/>
                    <Route exact path="/handout" element={<Handout/>}/>
                    <Route exact path="/resources" element={<Resources/>}/>
                    <Route exact path="/so_what" element={<SoWhat/>}/>
                    <Route exact path="/presentations/:presentationId/" element={<Presentations/>}/>
                    <Route exact path="/presentations/:presentationId/:slideId" element={<Presentations/>}/>
                </Routes>
            </Router>
        </div>
    );
}

// SHIM to make .at work
function at(n) {
    // ToInteger() abstract op
    n = Math.trunc(n) || 0;
    // Allow negative indexing from the end
    if (n < 0) n += this.length;
    // OOB access is guaranteed to return undefined
    if (n < 0 || n >= this.length) return undefined;
    // Otherwise, this is just normal property access
    return this[n];
}

const TypedArray = Reflect.getPrototypeOf(Int8Array);
for (const C of [Array, String, TypedArray]) {
    Object.defineProperty(C.prototype, "at",
        { value: at,
            writable: true,
            enumerable: false,
            configurable: true });
}
// End of SHIM


export default App;
