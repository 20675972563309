import React, {useEffect, useRef, useState} from "react";
import "./UI.scss";
import {isMobile} from "react-device-detect";
import { useSpring, animated } from "@react-spring/web";

export const ButtonStyles = {
    DARK:"dark",
    LIGHT:"light"
}

export const ButtonTypes = {
    PRIMARY:"primary",
    SECONDARY:"secondary", // not implemented
    TERTIARY:"tertiary",
    PROGRESS:"primary progress" // has a progress bar behind it
}

function TheyRuleButton ({label, action, mode=ButtonStyles.LIGHT, icon, btnType=ButtonTypes.PRIMARY, percent = 0, duration = 200, disabled=false, tabIndex= 0, focusOnLoad=false}) {

    const [progressComplete, setProgressComplete] = useState("");

    const styles = useSpring({
        to: {width: percent + "%", value: percent },
        from: { width: "0%", value: 0 },
        config: { duration: duration },
        onChange: (props) => { setProgressComplete(props.value.value > 95 ? " complete" : "") }
    });

    const progressBar = <animated.div className={"progress-bar" + progressComplete } style={{...styles}} />;

    const ref = useRef();

    /**
     * Focus on load
     */
    useEffect(()=>{
        if(focusOnLoad && !!ref && ref.current){
            ref.current.focus();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])



    return (
            <div className={"btn " + mode + " " + btnType + (isMobile? " mobile":"") + (disabled? " disabled":"") }
                 onClick={action}
                 role={"button"}
                 tabIndex={tabIndex}
                 aria-label={label}
                 onKeyPress={(e) => { if (e.key === 'Enter' && !disabled) { action();}}}
                 ref={ref}
            >
                { (btnType === ButtonTypes.PROGRESS)? progressBar : "" }
                {icon?icon:""}
                <span>{ label }</span>
            </div>
    )
}

export default TheyRuleButton