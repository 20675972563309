import React, {useEffect, useRef, useState} from "react";
import "./Icon.scss";

export const Icons = {
    SEARCH: "search",
    CLOSE: "close",
    BACK: "back",
    BACK_RIGHT: "back_right",
    PLAY_TRIANGLE: "play_triangle",
    CARET: "caret",
    CARET_SM: "caret_sm",
    LINK: "link",
    LINK_OUT: "link_out",
    PRINT: "print",
    CLEAR: "clear",
    INFO: "info",
    FIND_CONNECTIONS: "find_connections",
    DELETE: "delete",
    INFO_SOLID: "info_solid",
    PAN_ARROWS: "pan_arrows",
    ROTATE_ARROWS: "rotate_arrows",
    LOCK: "lock",
    ZOOM: "zoom",
    THANKS: "thanks",
    FIX_CAMERA: "fix_camera"
}


function Icon ({onClick, interactive, strokeCol, isOpen, label, isInline=false, autofocus=false, ...props}) {

    let iconElement = "";
    const ref = useRef();
    const [strokeColor, setStrokeColor] = useState(strokeCol?strokeCol:"#888");
    let strokeWidth = "1.5";

    if (props.strokeWidth){
        strokeWidth = props.strokeWidth;
    }

    if(props.rollover){
        setStrokeColor("#555");
    }

    useEffect(()=>{
        if(strokeCol) {
            setStrokeColor(strokeCol);
        } else {
            setStrokeColor("#888");
        }
    }, [strokeCol]
    );

    useEffect(()=>{
        if(autofocus && !!ref) {
            //console.log("icon autofocus ref.current: ", ref.current, " af: ", autofocus);
            if (autofocus && ref.current) {
                //console.log("icon autofocus!", ref.current);
                ref.current.focus();
                //console.log("icon autofocus! activeEl:", document.activeElement);
            }
        }},
        // eslint-disable-next-line react-hooks/exhaustive-deps
        []
    )

    let p1, p2 = 0;

    switch (props.icon) {
        case Icons.SEARCH:
            iconElement = (
                <svg width="100%" height="100%" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <circle cx="10.2138" cy="9.13401" r="4.75" transform="rotate(-66.9611 10.2138 9.13401)" stroke={strokeColor} strokeWidth={strokeWidth}/>
                    <path d="M14 13L18.5 17.5" stroke={strokeColor} strokeWidth={strokeWidth} strokeLinecap="round"/>
                </svg>
            );
            break;
        case Icons.CLOSE:
            p1 = 6;
            p2 = 18;
            iconElement = (
                <svg width="100%" height="100%" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d={"M"+p1+" "+p1+"L"+p2+" "+p2} stroke={strokeColor} strokeWidth={strokeWidth} strokeLinecap="round"/>
                    <path d={"M"+p1+" "+p2+"L"+p2+" "+p1} stroke={strokeColor} strokeWidth={strokeWidth} strokeLinecap="round"/>
                </svg>
            );
            break;
        case Icons.BACK:
            iconElement = (
                <svg width="100%" height="100%" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M10 6L4 12L10 18M4 12L20 12" stroke={strokeColor} strokeWidth={strokeWidth} strokeLinecap="round"/>
                </svg>
            );
            break;
        case Icons.BACK_RIGHT:
            iconElement = (
                <svg width="100%" height="100%" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M14 6L20 12L14 18M4 12L20 12" stroke={strokeColor} strokeWidth={strokeWidth} strokeLinecap="round"/>
                </svg>
            );
            break;
        case Icons.PLAY_TRIANGLE:
            iconElement = (
                <svg width="100%" height="100%" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M17.0881 11.2342C17.564 11.6338 17.564 12.3662 17.0881 12.7658L8.29934 20.1464C7.64872 20.6928 6.65625 20.2303 6.65625 19.3807L6.65625 4.61935C6.65625 3.76973 7.64872 3.30718 8.29935 3.85356L17.0881 11.2342Z" fill={strokeColor}/>
                </svg>
            );
            break;
        case Icons.CARET:
            iconElement = (
                <svg width="100%" height="100%" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M4 8L12 16L20 8" stroke={strokeColor} strokeWidth={strokeWidth} strokeLinecap="round"/>
                </svg>
            );
            break;
        case Icons.CARET_SM:
            iconElement = (
                <svg width="100%" height="100%" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M17.6309 11L12.1309 15.8683L6.63086 11" stroke={strokeColor} strokeWidth={strokeWidth} strokeLinecap="round"/>
                </svg>
        );
            break;
        case Icons.LINK:
            iconElement = (
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M16.0204 8.24264L8.24219 16.0208" stroke={strokeColor} strokeWidth={strokeWidth} strokeLinecap="round"/>
                    <path d="M12.7504 15.7556L11.2478 17.2582L10.364 18.1421C9.19239 19.3137 7.29289 19.3137 6.12132 18.1421V18.1421C4.94975 16.9706 4.94975 15.0711 6.12132 13.8995L7.0052 13.0156L8.50781 11.513M11.513 8.5078L13.0156 7.0052L13.8995 6.12132C15.0711 4.94975 16.9706 4.94975 18.1421 6.12132V6.12132C19.3137 7.29289 19.3137 9.19239 18.1421 10.364L17.2583 11.2478L15.7557 12.7504" stroke={strokeColor} strokeWidth={strokeWidth} strokeLinecap="round"/>
                </svg>
            )
            break;
        case Icons.CLEAR:
            iconElement = (
                <svg width="100%" height="100%" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M15.7782 8L8 15.7782" stroke={strokeColor} strokeWidth={strokeWidth} strokeLinecap="round"/>
                    <path d="M15.7782 15.7782L8 8.00001" stroke={strokeColor} strokeWidth={strokeWidth} strokeLinecap="round"/>
                </svg>

            )
            break;

        case Icons.LINK_OUT:
            iconElement =(
                <svg width="100%" height="100%" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M15.3804 8.11964L7.5 16" stroke={strokeColor} strokeWidth={strokeWidth} strokeLinecap="round" strokeLinejoin="round"/>
                    <path d="M9.99958 7.57603L15.6738 7.92176L16.0195 13.596" stroke={strokeColor} strokeWidth={strokeWidth} strokeLinecap="round" strokeLinejoin="round"/>
                </svg>
            )
            break;
        case Icons.INFO:
            iconElement = (
                <svg width="100%" height="100%" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <circle cx="12" cy="12" r="8" stroke={strokeColor} strokeWidth={strokeWidth} strokeLinejoin="round"/>
                    <circle cx="12" cy="8.75" r="0.75" fill={strokeColor}/>
                    <path d="M12 11L12 16" stroke={strokeColor} strokeWidth={strokeWidth} strokeLinecap="round"/>
                </svg>
        )
            break;
        case Icons.PRINT:
            iconElement = (
                <svg width="100%" height="100%" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <rect x="6.75" y="3.75" width="10.5" height="9.5" rx="1.25" fill="white" stroke={strokeColor} strokeWidth={strokeWidth} strokeLinejoin="round"/>
                    <rect x="2.75" y="10.75" width="18.5" height="9.5" rx="1.25" fill="white" stroke={strokeColor} strokeWidth={strokeWidth} strokeLinejoin="round"/>
                    <path d="M5 13H9" stroke={strokeColor} strokeWidth={strokeWidth} strokeLinecap="round"/>
                </svg>
        )
            break;

        case Icons.FIND_CONNECTIONS:
            iconElement = (
                <svg width="100%" height="100%" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M15 9.5L9.5 15" stroke={strokeColor}  strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                <circle cx="17.5" cy="7" r="1.5" stroke={strokeColor} />
                <circle cx="7" cy="17.5" r="1.5" stroke={strokeColor} />
            </svg>
            )
            break;

        case Icons.DELETE:
            p1 = 9;
            p2 = 15;
            iconElement = (
                <svg width="100%" height="100%" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <circle cx="12" cy="12" r="8" fill={"#666"} />
                    <path d={"M"+p1+" "+p1+"L"+p2+" "+p2} stroke={"#FFF"} strokeWidth={strokeWidth} strokeLinecap="round"/>
                    <path d={"M"+p1+" "+p2+"L"+p2+" "+p1} stroke={"#FFF"} strokeWidth={strokeWidth} strokeLinecap="round"/>
                </svg>
            );
            break;

        case Icons.INFO_SOLID:
            iconElement = (
                <svg width="100%" height="100%" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <circle cx="12" cy="12" r="8" fill={"#666"} />
                    <circle cx="12" cy="8.75" r="0.75" fill={"#FFF"}/>
                    <path d="M12 11L12 16" stroke={"#FFF"} strokeWidth={strokeWidth} strokeLinecap="round"/>
                </svg>
            )
            break;

        case Icons.PAN_ARROWS:
            iconElement = (
                <svg width="100%" height="100%" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M8 8L2 8M2 8L4 6M2 8L4 10M8 8L2 8" stroke={strokeColor} strokeWidth={strokeWidth} strokeLinecap="round"/>
                    <path d="M8 8H14M14 8L12 10M14 8L12 6M8 8H14" stroke={strokeColor} strokeWidth={strokeWidth} strokeLinecap="round"/>
                    <path d="M8 8L8 2M8 2L10 4M8 2L6 4M8 8L8 2" stroke={strokeColor} strokeWidth={strokeWidth} strokeLinecap="round"/>
                    <path d="M8 8L8 14M8 14L6 12M8 14L10 12M8 8L8 14" stroke={strokeColor} strokeWidth={strokeWidth} strokeLinecap="round"/>
                </svg>
            )
            break;

        case Icons.ROTATE_ARROWS:
            iconElement = (
                <svg width="100%" height="100%" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M13.002 7C13.002 7 10.828 10 8.00195 10C5.17587 10 3.00195 7 3.00195 7" stroke={strokeColor} strokeWidth={strokeWidth} strokeLinecap="round"/>
                    <path d="M13.8301 9.24268V6.41425H11.0017" stroke={strokeColor} strokeWidth={strokeWidth} strokeLinecap="round"/>
                    <path d="M5.24219 6.41431H2.41376V9.24273" stroke={strokeColor} strokeWidth={strokeWidth} strokeLinecap="round"/>
                </svg>
            )
            break;

        case Icons.FIX_CAMERA:
            iconElement = (
                <svg width="100%" height="100%" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M5 3H2V13H5"  stroke={strokeColor} strokeWidth={strokeWidth*.8} strokeLinecap="round"/>
                    <path d="M11 13L14 13L14 3L11 3"  stroke={strokeColor} strokeWidth={strokeWidth*.8} strokeLinecap="round"/>
                </svg>
            )
            break;

        case Icons.LOCK:
            iconElement = (
                <svg width="100%" height="100%" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <rect x="4" y="8" width="8" height="5" rx="0.5" fill={strokeColor} />
                    <path d="M10.5 10V6.5C10.5 5.11929 9.38071 4 8 4V4C6.61929 4 5.5 5.11929 5.5 6.5V10" stroke={strokeColor} strokeWidth={strokeWidth} strokeLinecap="round" />
                </svg>
            )
            break;

        case Icons.ZOOM:
            iconElement = (
                <svg width="100%" height="100%" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M19.25 6C19.25 5.58579 18.9142 5.25 18.5 5.25L11.75 5.25C11.3358 5.25 11 5.58579 11 6C11 6.41421 11.3358 6.75 11.75 6.75L17.75 6.75L17.75 12.75C17.75 13.1642 18.0858 13.5 18.5 13.5C18.9142 13.5 19.25 13.1642 19.25 12.75L19.25 6ZM5.25 18.5C5.25 18.9142 5.58579 19.25 6 19.25L12.75 19.25C13.1642 19.25 13.5 18.9142 13.5 18.5C13.5 18.0858 13.1642 17.75 12.75 17.75L6.75 17.75L6.75 11.75C6.75 11.3358 6.41421 11 6 11C5.58579 11 5.25 11.3358 5.25 11.75L5.25 18.5ZM6.53033 19.0303L19.0303 6.53033L17.9697 5.46967L5.46967 17.9697L6.53033 19.0303Z" fill={strokeColor}/>
                </svg>            )
            break;

        case Icons.THANKS:
            iconElement = (
                <svg width="100%" height="100%" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M4 18.6798C4.88301 17.8556 5.67027 16.9412 6.32448 16.1476M7.89759 20.4647C8.27547 19.8836 8.71845 19.0253 9.30928 18.4722M6.32448 16.1476C7.05416 15.2626 7.61532 14.4969 7.89759 13.5354C8.43294 11.7118 8.23417 12.2872 8.56626 11.1664C8.75712 10.5223 9.01477 9.62516 9.30928 8.15423C9.60379 6.6833 9.47741 3.53528 10.8689 3.53528C11.4079 3.53528 11.9701 3.88861 11.9701 5.10591C11.9701 5.60331 11.638 14.3212 11.638 15.8571C11.638 16.8347 10.3438 17.5038 9.30928 18.4722M6.32448 16.1476C7.44526 18.3062 9.30928 18.4722 9.30928 18.4722" stroke={strokeColor} strokeWidth={strokeWidth} strokeLinecap="round" strokeLinejoin="round"/>
                    <path d="M20 18.6798C19.117 17.8556 18.3297 16.9412 17.6755 16.1476M16.1024 20.4647C15.7245 19.8836 15.2815 19.0253 14.6907 18.4722M17.6755 16.1476C16.9458 15.2626 16.3847 14.4969 16.1024 13.5354C15.5671 11.7118 15.7658 12.2872 15.4337 11.1664C15.2429 10.5223 14.9852 9.62516 14.6907 8.15423C14.3962 6.6833 14.5151 3.53528 13.1311 3.53528C12.5921 3.53528 12.0299 3.88861 12.0299 5.10591C12.0299 5.60331 12.362 14.3212 12.362 15.8571C12.362 16.8347 13.6562 17.5038 14.6907 18.4722M17.6755 16.1476C16.5547 18.3062 14.6907 18.4722 14.6907 18.4722" stroke={strokeColor} strokeWidth={strokeWidth} strokeLinecap="round" strokeLinejoin="round"/>
                </svg>
            )
            break;



        default:
            break;
    }

    return (
        <div className={["icon",
            props.icon,
            interactive?"interactive":"",
            props.icon === Icons.CARET || props.icon === Icons.CARET_SM? "caret" + (isOpen? " open":" closed" ): "",
            isInline?"inline":"",
        ].join(" ")}
             onClick={onClick}
             {...(interactive ? {role: "button", "aria-label":label? label : props.icon} : {})}
             {...(interactive ? {tabIndex: 0} : {})}
             {...(interactive ? {onKeyPress: (e)=>{if(e.code === "Enter" || e.charCode===13){onClick();} }}  : {})}
             {...(!!props.iconElRef ? {ref:props.iconElRef} :{} )}
             ref={ref}
        >
            {iconElement}
        </div>
    );
};

export default Icon;