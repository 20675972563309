import React, {useEffect, useRef, useState} from "react";
import "./SearchItem.scss";
import {Entities} from "../../../data/constants";

import dms from "../../../images/icons/directors/DMS.svg";
import dmm from "../../../images/icons/directors/DMM.svg";
import dml from "../../../images/icons/directors/DML.svg";
import dmxl from "../../../images/icons/directors/DMXL.svg";
import dfs from "../../../images/icons/directors/DFS.svg";
import dfm from "../../../images/icons/directors/DFM.svg";
import dfl from "../../../images/icons/directors/DFL.svg";
import dfxl from "../../../images/icons/directors/DFXL.svg";

import tbs from "../../../images/icons/tables/TS.svg";
import tbm from "../../../images/icons/tables/TM.svg";
import tbl from "../../../images/icons/tables/TL.svg";

import useMapStore from "../../../data/map_store";

function SearchItem ({type, id, onClose, extraInfo, selected}) {

    const [hover, setHover] = useState(false);

    const actions = useMapStore((state) => state.actions);
    const {Orgs, Dirs} = useMapStore((state) => state.dataset);
    const ref = useRef();
    let name = <span></span>;
    let gender = "";
    let numBoards = 0;

    let itemIcon;

    let icons = {
        DMS:dms,
        DMM:dmm,
        DML:dml,
        DMXL:dmxl,
        DFS:dfs,
        DFM:dfm,
        DFL:dfl,
        DFXL:dfxl,
        TS:tbs,
        TM:tbm,
        TL:tbl
    }

    // {selected && console.log("search item selected: ", selected);}

    useEffect(() => {
        if (selected) {
            //console.log("setting focus!");
            ref.current.focus();
        }
    }, [selected]);


    switch (type) {
        case Entities.ORG:
            // console.log("SearchItem id", id);
            name = Orgs[id].n;
            let revenue = Orgs[id].r;
            let tableSize = "S";
            if(revenue > 100000 ){
                tableSize = "L";
            } else if(revenue > 50000 ){
                tableSize = "M";
            }
            // itemIcon = <Table id={Coms[id]}/>
            itemIcon = <img className={`table-icon ${hover?"hover":""}`} src={icons["T" + tableSize]} alt={"Table icon"}/>
            break;
        case Entities.DIR:
            name = Dirs[id].n;
            gender = Dirs[id].g;
            numBoards = Dirs[id].c.length;
            let size = "S";
            if (numBoards > 3) {
                size = "XL";
            } else if (numBoards > 2) {
                size = "L";
            } else if (numBoards > 1) {
                size = "M";
            }
            //itemIcon = <Director anim={hover? DirectorAnims.TURN_HEAD_LEFT : DirectorAnims.STANCE} name={name} gender={gender} numBoards={numBoards} id={Dirs[id]} />
            //itemIcon = <Icon icon={"search"}/>
            itemIcon = <img className={`director-icon ${hover?"hover":""}`}
                            src={icons["D" + gender.toUpperCase() + size]}
                            alt={"Director icon"}/>
            break;
        default:
            break;
    }

    const onMouseDown = (e) => {
        // stops the search field blurring
        e.preventDefault();
    }

    const onMouseOver = (e) => {
        setHover(true);
    }

    const onMouseOut = (e) => {
        setHover(false);
    }

    const onHandleClick = (type, id) => {
        //console.log("Search Suggestion onHandleClick: ", type, id);
        actions.addNode(type, id);
        // actions.setSelectedNode(id); // ths was a confusing first experience for Graham Plumb
        onClose();
    }

    return (
        <div className="search-item"
             onClick={(e) => onHandleClick(type, id)}
             onMouseDown={onMouseDown}
             onMouseOver={onMouseOver}
             onMouseOut={onMouseOut}
             onMouseLeave={onMouseOut}
             tabIndex={0}
             ref={ref}
             role="button"
             // aria-pressed={!!selected}
             aria-label={"Add " + type + " " + name + (extraInfo? " " + extraInfo: "") }
             onKeyPress={(e) => {
                     if (e.key === 'Enter') {
                         onHandleClick(type, id);

                     }
                }
             }
        >
            <div className={"search-suggestion-icon"}>{itemIcon}</div>
            {name} {extraInfo}
        </div>
    );
};

export default SearchItem;