import React, {useLayoutEffect, useState} from "react";
import useMapStore from "../../data/map_store";
import useDialogStore from "../../data/dialog_store";
import {strings} from "../../data/strings";
import Toggle from "../UI/Toggle";
import {DataSetSource} from "../../data/constants";
import Icon from "../Icons";
import {Icons} from "../Icons/Icon";


function DataDetails({dataSet, detailsString, isForAddOn}) {
    const [showDetails, setShowDetails] = useState(false);
    return (
        <div className={"data-details"}>
            <div className={"details-link" + (showDetails ? " open" : " closed") + (isForAddOn ? " add-on" : "")}
                 onClick={() => {
                     setShowDetails(!showDetails)
                 }}>{strings.viewDetails} <Icon icon={Icons.CARET} strokeWidth={2}/></div>
            {showDetails && dataSet !== undefined &&
            <div className={"data-details-summary"}>
                {dataSet.notes !== "" && <span>{dataSet.notes} &nbsp;</span>}
                {strings.dataSummary
                    .replace("*total_orgs*", dataSet.total_orgs)
                    .replace("*org_type*", dataSet.org_type)
                    .replace("*criteria*", dataSet.criteria)
                    .replace("*year*", dataSet.year)
                    .replace("*source*", strings[dataSet.source])
                    .replace("*retrieved*", dataSet.retrieved)
                }&nbsp;
                {strings.dataAnalytics
                    .replace("*average_board_size*", dataSet.average_board_size)
                    .replace("*average_connections*", dataSet.average_connections)
                    .replace("*percent_in_clusters*", Math.round((dataSet.total_orgs-dataSet.num_isolates)/dataSet.total_orgs * 100))
                    .replace("*num_clusters*", dataSet.num_clusters)
                    .replace("*clusters*", dataSet.num_clusters === 1? strings.cluster : strings.clusters)
                    .replace("*percent_women*", Math.round(dataSet.percent_women))
                }
            </div>
            }
            {showDetails && detailsString !== undefined &&
            <div className={"data-details-summary" + (isForAddOn ? " add-on" : "")}>{detailsString}</div>
            }
        </div>
    );
}

export default function MenuDataSetSection({dataset}) {

    const savedMap = useMapStore.getState().loaded_saved_map_id !== undefined;

    const [showPresidentialAdmins, setShowPresidentialAdmins] = React.useState(false);
    const [disablePresidentialAdmins, setDisablePresidentialAdmins] = React.useState(false);

    // create an object of add_on ids as keys with the values set to false
    // e.g {"us_thinktanks_revenue_2020_12_31_1": false, "us_thinktanks_influence_2020_12_31_1": false }
    const [visibleAddOns, setVisibleAddOns] = React.useState(
        dataset.add_ons.reduce((acc, obj) => {
            acc[obj["id"]] = false;
            return acc
        }, {})
    );
    const [disabledAddOns, setDisabledAddOns] = React.useState(
        dataset.add_ons.reduce((acc, obj) => {
            acc[obj["id"]] = false;
            return acc
        }, {})
    );

    //console.log("MenuDataSetSection dataset.add_ons: ", dataset);
    //console.log("MenuDataSetSection useMapStore.getState().dataAddOns:  ", useMapStore.getState().dataAddOns);

    const {includePresidentialAdmins, includeAddOnData, clearMap} = useMapStore((state) => state.actions);
    const {
        clearDialog,
        showDialog,
        setTitle,
        setBody,
        setTertiary,
        setPrimary,
        setTertiaryAction,
        setPrimaryAction
    } = useDialogStore((state) => state.actions);

    const closeDialog = () => {
        showDialog(false);
    }

    const openClearMapWhenChangingDataDialog = (changeDataFunction, args) => {
        clearDialog();
        setTitle(strings.areYouSure);
        setBody(strings.changingDataClearsMap);
        setTertiary(strings.cancel);
        setPrimary(strings.yesChange);
        if (args) {
            setPrimaryAction(() => {
                //console.log("changeDataFunction args", ...args);
                changeDataFunction(...args);
                closeDialog()
            });
        } else {
            setPrimaryAction(() => {
                changeDataFunction();
                closeDialog()
            });
        }
        setTertiaryAction(closeDialog);
        showDialog(true);
    }

    // Listen for select and unselect changes from the mapStore
    useLayoutEffect(() => {
        const unsub_presidential_admins = useMapStore.subscribe((state) => (state.dataAddOns.presidential_admins), (s) => {
            updatePresidentialAdminsToggleView();
        })
        // call this the first time if it is selected
        updatePresidentialAdminsToggleView();

        const subs = [];
        dataset.add_ons.forEach(
            (addOnDS) => {
                subs.push(useMapStore.subscribe((state) => (
                    state.dataAddOns[addOnDS.id]),
                    (s) => {
                    updateAddOnToggleView(addOnDS.id);
                }));
                updateAddOnToggleView(addOnDS.id);
            }
        );

        return () => {
            // Clean up the subscription
            unsub_presidential_admins();
            subs.forEach(
                (s) => {
                    s()
                }
            );
        };
    })

    const updatePresidentialAdminsToggleView = () => {
        if (useMapStore.getState().dataAddOns.presidential_admins.include) {
            setShowPresidentialAdmins(true);
            if (useMapStore.getState().dataAddOns.presidential_admins.loaded) {
                setDisablePresidentialAdmins(false);
            } else {
                setDisablePresidentialAdmins(true);
            }

        } else {
            setShowPresidentialAdmins(false);
            setDisablePresidentialAdmins(false);
        }
    }

    const updateAddOnToggleView = (id) => {

        let tempViewAddOns = {...visibleAddOns}; // by expanding them into new arrays it will force updates
        let tempDisabledAddOns = {...disabledAddOns};

        if (useMapStore.getState().dataAddOns[id].include) {

            //console.log("updateAddOnToggleView: ", id, "tempViewAddOns: ", tempViewAddOns);
            tempViewAddOns[id] = true;

            if (useMapStore.getState().dataAddOns[id].loaded) {
                tempDisabledAddOns[id] = false;
            } else {
                tempDisabledAddOns[id] = true;
            }

        } else {
            tempViewAddOns[id] = false;
            tempDisabledAddOns[id] = false;
        }

        //console.log("tempViewAddOns[id] != visibleAddOns[id]", tempViewAddOns[id], visibleAddOns[id]);
        if (tempViewAddOns[id] !== visibleAddOns[id]) {
            setVisibleAddOns(tempViewAddOns);
        }

        if (tempDisabledAddOns[id] !== disabledAddOns[id]) {
            setDisabledAddOns(tempDisabledAddOns);
        }

    }

    const showPresidentialAdminsToggleChanged = () => {
        let show = !showPresidentialAdmins;
        if (!show && Object.keys(useMapStore.getState().node_positions).length > 0) {
            openClearMapWhenChangingDataDialog(removePresidentialAdmins);
            return;
        }
        includePresidentialAdmins(show);
    }

    const showAddOnToggleChanged = (addOnData) => {

        //console.log("showAddOnToggleChanged: ", addOnData);

        let show = !visibleAddOns[addOnData.id];
        if (!show && Object.keys(useMapStore.getState().node_positions).length > 0) {
            openClearMapWhenChangingDataDialog(removeAddOnData, [addOnData.id]);
            return;
        }
        includeAddOnData(show, addOnData.id);
    }


    /*
        Called from confirming clearing map in the change data dialog
     */
    const removePresidentialAdmins = () => {
        clearMap();
        includePresidentialAdmins(false);
    }

    const removeAddOnData = (id) => {
        clearMap();
        includeAddOnData(false, id);
    }

    return (
        <div
            key={dataset.year + "_" + dataset.month + "_" + dataset.day + "_" + dataset.version + "_" + dataset.org_type}>
            {dataset.source === DataSetSource.WIKIDATA &&
            <div className={"menu-sub-section"}>
                {(!savedMap || (savedMap && useMapStore.getState().dataAddOns.presidential_admins.include)) &&
                <div className={"add-on-toggle" + (savedMap?" saved-map":"")}>
                    <Toggle
                        id="admins"
                        small
                        disabled={savedMap || disablePresidentialAdmins}
                        checked={showPresidentialAdmins}
                        onChange={showPresidentialAdminsToggleChanged}
                        labelText={<>
                        <span className={"toggle-label-text"}>
                            {(savedMap?strings.addOnDataSummaryAdminsSavedMap: strings.addOnDataSummaryAdmins)}
                        </span>
                            <div className={"toggle-source"}>
                                Source: <span className={"toggle-source-name"}>Wikidata</span>.
                            </div>
                        </>}
                    />
                    <DataDetails detailsString={strings.administrationsSummary} isForAddOn={true}/>
                </div>
                }
                {dataset.add_ons.map(
                    (addonDS, index) => {
                        return (
                            <div key={"menudatasetsection" + index}>
                                {(!savedMap || (savedMap && useMapStore.getState().dataAddOns[addonDS.id].include)) &&
                                <div className={"add-on-toggle" + (savedMap?" saved-map":"")} key={addonDS.id}>
                                    <Toggle
                                        id={addonDS.id}
                                        small
                                        disabled={savedMap || disabledAddOns[addonDS.id]}
                                        checked={visibleAddOns[addonDS.id]}
                                        onChange={() => {
                                            showAddOnToggleChanged(addonDS)
                                        }}
                                        labelText={<>
                                        <span className={"toggle-label-text"}>
                                            {(savedMap?strings.addOnDataSummarySavedMap: strings.addOnDataSummary).replace("*total_orgs*", addonDS.total_orgs)
                                                .replace("*region*", addonDS.region.toUpperCase())
                                                .replace("*type*", strings[addonDS.type])
                                                .replace("*criteria*", addonDS.criteria)
                                                .replace("*criteria_year*", addonDS.criteria_year)
                                            }
                                        </span>
                                            <div className={"toggle-source"}>
                                                Source: <span className={"toggle-source-name"}>{addonDS.source}</span>.
                                            </div>
                                        </>}
                                    />
                                    <DataDetails detailsString={strings.addOnDetails.replace("*year*", addonDS.year)
                                        .replace("*type*", strings[addonDS.type])
                                        .replace("*retrieved*", addonDS.retrieved)} isForAddOn={true}/>
                                </div>
                                }</div>
                        )
                    }
                )}
            </div>
            }

        </div>
    )
}