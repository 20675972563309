import {Criteria, DataSetSource, DataSetStatus, DataSetType, Regions, AddOns} from "./constants";
import {strings} from "./strings";

// const us_companies_revenue_2020_12_31_1 = React.lazy(() => import('./us/companies/revenue/2020/12/31/1/'));

export const DataSets = [
    {
        notes: strings.tr2001Notes,
        region: Regions.US,
        year: 2001,
        month: 12,
        day: 31,
        version: 1,
        org_type: DataSetType.COMPANIES,
        criteria: Criteria.REVENUE,
        total_orgs: 100,
        max_rev: 210392,
        min_rev: 20087,
        percent_women: 13.47,
        percent_women_in_top_ten: 11.94,
        average_connections: 4.89,
        average_connections_of_top_ten: 48.90,
        percent_connected: 0.91,
        num_clusters: 1,
        num_isolates: 9,
        average_board_size: 12.76,
        source: DataSetSource.THEY_RULE,
        retrieved: "manually in 2022",
        add_ons: [],
        status: DataSetStatus.ACTIVE
    },

    // {
    //     notes: "",
    //     region: Regions.US,
    //     year: 2020,
    //     month: 12,
    //     day: 31,
    //     version: 1,
    //     org_type: DataSetType.COMPANIES,
    //     criteria: Criteria.REVENUE,
    //     total_orgs: 50,
    //     max_rev: 523964,
    //     min_rev: 41908,
    //     percent_women: 33.77,
    //     percent_women_in_top_ten: 31.63,
    //     average_connections: 2,
    //     average_connections_of_top_ten: 1.8,
    //     average_board_size: 11.58,
    //     source: DataSetSource.WIKIDATA,
    //     retrieved: "5/18/2022",
    //     add_ons: [
    //         {
    //             id:"us_thinktanks_revenue_2020_12_31_1",
    //             type: AddOns.THINKTANKS,
    //             region: Regions.US,
    //             criteria: Criteria.REVENUE,
    //             total_orgs: 5,
    //             criteria_year: 2020,
    //             source: [DataSetSource.WIKIDATA],
    //             retrieved: "05/20/2022",
    //             year: 2020,
    //             month: 12,
    //             day: 31,
    //             version: 1
    //         },
    //     ],
    //     status: DataSetStatus.ARCHIVED
    // },
    {
        notes: "",
        region: Regions.US,
        year: 2021,
        month: 12,
        day: 31,
        version: 1,
        org_type: DataSetType.COMPANIES,
        criteria: Criteria.REVENUE,
        total_orgs: 100,
        max_rev: 559151,
        min_rev: 34816,
        percent_women: 31.52,
        percent_women_in_top_ten: 34.69,
        average_connections: 2.88,
        average_connections_of_top_ten: 28.80,
        percent_connected: 0.87,
        num_clusters: 1,
        num_isolates: 13,
        average_board_size: 11.98,
        source: DataSetSource.WIKIDATA,
        retrieved: "11/4/2022",
        add_ons: [
            {
                id: "us_thinktanks_revenue_2020_12_31_1",
                type: AddOns.THINKTANKS,
                region: Regions.US,
                criteria: Criteria.REVENUE,
                total_orgs: 5,
                criteria_year: 2020,
                source: [DataSetSource.WIKIDATA,],
                retrieved: "05/20/2022",
                year: 2020,
                month: 12,
                day: 31,
                version: 1
            },
        ],
        status: DataSetStatus.DEFAULT
    },
]

