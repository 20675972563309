import * as THREE from "three";
import React, {useRef, useEffect} from "react";
import { Instance } from "@react-three/drei";
import useMapStore from "../data/map_store";

export default function ConnectingLine({source, target, i}) {

        const ref = useRef();

        useEffect(()=>{
            const unsub = useMapStore.subscribe(
                (state) => ({ source_pos: state.node_positions[source], target_pos: state.node_positions[target] }),
                (args)=>{update(args)}
            )
            return () => {
                // Clean up the subscription
                unsub();
            };
        })

        const update = ({source_pos, target_pos}) => {

            if (ref.current === undefined || source_pos === undefined || target_pos === undefined) {
                return;
            }
            // console.log("ConnectLine i: ", i );
            const point1 = new THREE.Vector3(
                source_pos[0],
                0 - i * 0.00005,
                source_pos[2]
            );
            const point2 = new THREE.Vector3(
                target_pos[0],
                -.2 + i * 0.00005,
                target_pos[2]
            );
            const vector12 = new THREE.Vector3().copy(point2).sub(point1);
            const point3 = new THREE.Vector3()
                .copy(vector12)
                .multiplyScalar(0.5)
                .add(point1);

            ref.current.position.x = point3.x;
            ref.current.position.y = point3.y;
            ref.current.position.z = point3.z;
            ref.current.scale.x = vector12.length();
            ref.current.scale.y = 0.75;
            ref.current.scale.z = 0.0001;
            ref.current.rotation.x = -Math.PI / 2;
            ref.current.rotation.y = 0;
            ref.current.rotation.z = -Math.atan2(vector12.z, vector12.x);

        };

        /* Ensure that it renders when first added because the very first render doesn't have a ref */
        useEffect(() => {
            update({
                source_pos: useMapStore.getState().node_positions[source],
                target_pos: useMapStore.getState().node_positions[target]
            })
        })

        return (
            <group>
                <Instance ref={ref} ></Instance>
            </group>
        );
    }