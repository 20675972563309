import create from 'zustand';
import { subscribeWithSelector } from "zustand/middleware";
import { immer } from "zustand/middleware/immer";
import {
    AutoPlayStates,
    DataSetStatus,
    DataSetType,
    Entities,
    ControlModes
} from "./constants";
import {clamp} from "three/src/math/MathUtils";
import * as THREE from "three";
import {DataSets} from "./index";
import {isMobile} from "react-device-detect";

const mapStore = (set, get) => ({
    config: {},
    betaPassword: "4now",
    passwordEntered: false,
    datasetInfo: [],
    dataset: {
        Orgs: {},
        TopComs: [],
        Dirs:{},
        TopDirs:[],
        TopConnectedOrgs:[],
        OrgsWomenMost:[],
        OrgsWomenLeast:[],
        Admins:[]
    },
    data_loaded: false,
    dataAddOns:{
        presidential_admins: {
            include: false,
            loaded: false,
        },
    },
    loaded_saved_map_id: undefined,
    loaded_saved_map_data_set_info: {}, // Used to store dataset loaded from URL of a saved map
    saved_map_loaded: false,
    saved_map_camera_rotation: undefined,
    is_fetching_saved_map: false,
    is_dragging: false,
    is_pinching: false,
    camera_target: [0, 0, 0], // the position the camera is looking at
    camera_zoom_target: 30,
    selected_node: null,
    control_mode: ControlModes.PAN,
    is_auto_control_mode: true,
    moveCameraAfterScroll: true,
    node_selected_states: {}, // this is just so we can individually update node states and not rerender everything
    node_positions: {},
    node_target_positions: {},
    node_rotate_positions: {},
    incoming_angles: {}, // captures the incoming angles and ids of companies to ensure the directors expand back to the same place
    floor_plane: new THREE.Plane(new THREE.Vector3(0, 1, 0), 0),
    camera: undefined,
    orbit_controls: undefined,
    forceCameraJumpTrigger: 0,
    auto_play_state: AutoPlayStates.OFF,
    auto_play_history: [],
    num_search_suggestions: 0,
    unconnected_director: "", // for toast when trying to expand an unconnected dir
    first_node_added: "", // for first node toast
    first_node_toast_seen: false,
    repeat_click_on_node: false,
    mutation: {
        comps_s: {},
        comps_m: {},
        comps_l: {},
        dirs_ms: {},
        dirs_mm: {},
        dirs_ml: {},
        dirs_mxl: {},
        dirs_fs: {},
        dirs_fm: {},
        dirs_fl: {},
        dirs_fxl: {},
        links: {}
        // if adding to this - also update clearMap below, and to the MapLoading.jsx
    },
    actions: {
        isExpandable(instance_key){
            let canExpand = true;
          switch(instance_key) {
              case "dirs_ms":
              case "dirs_fs":
                  canExpand = false;
                  break;
              default:
                  break;
          }
          return canExpand;
        },
        clearMap (navigate){

            //console.log("Mapstore clearMap");

            set(state => {
                state.node_positions = {};
                state.node_target_positions = {};
                state.incoming_angles = {};
                state.camera_target = [0, 0, 0];
                state.camera_zoom_target = 30;
                state.selected_node = null;
                state.auto_play_history = [];
                state.mutation = {
                    comps_s: {},
                    comps_m: {},
                    comps_l: {},
                    dirs_ms: {},
                    dirs_mm: {},
                    dirs_ml: {},
                    dirs_mxl: {},
                    dirs_fs: {},
                    dirs_fm: {},
                    dirs_fl: {},
                    dirs_fxl: {},
                    links: {}
                }
            });
            get().actions.forceCameraToJumpToTarget();

            //console.log("clearMap get().loaded_saved_map_id: ", get().loaded_saved_map_id);

            if( get().loaded_saved_map_id !== undefined ){
                // when clearing the map on a saved map we always clear the data set too.
                set(state => {
                    state.data_loaded = false;
                    state.saved_map_loaded = false;
                    state.loaded_saved_map_id = undefined;
                    state.loaded_saved_map_data_set_info = {};
                    state.saved_map_camera_rotation =  undefined;
                    state.is_fetching_saved_map = false;
                });
                get().actions.setCurrentDataSet(get().actions.getDefaultDataSetInfo());
                navigate("../", { replace: false });
            }
        },
        setIsDragging(bool) {
            set(state => {
                state.is_dragging = bool
            });
        },
        setCameraTarget(pos) {
            // console.log("setCameraTarget", pos);
            set(state => {
                state.camera_target = pos
            });
        },
        setCamera(cam) {
            // console.log("setCamera", cam);
            set(state => {
                state.camera = cam;
            });
        },
        forceCameraToJumpToTarget() {
            set(state => {
                state.forceCameraJumpTrigger++;
            });
        },
        setSelectedNode(nodeId) {
            //console.log("set selected node from ", get().selected_node, " to: ", nodeId)
            if(get().selected_node === nodeId){
                // console.log("Trying to reselect selected node");
                return;
            } else if (get().selected_node !== null) {
                // deselect the old selected node
                set(state => {
                    state.node_selected_states[get().selected_node] = false
                });
            }
            // set the selected node
            set(state => {
                state.selected_node = nodeId
            });
            if(nodeId != null) {
                set(state => {
                    state.node_selected_states[nodeId] = true;
                });

                // center on this object
                let tar = get().node_target_positions[nodeId];
                get().actions.setCameraTarget([tar.x, 0, tar.z]);

            }
            if(get().is_auto_control_mode){
                get().actions.setControlMode(nodeId === null? ControlModes.PAN:ControlModes.ROTATE);
            }

        },
        setControlMode (mode) {
            set(state => {
                state.control_mode = mode
            });
        },
        setAutoControlMode (bool) {
            set(state => {
                state.is_auto_control_mode = bool;
            });
            if(bool) {
                if(get().selected_node !== null && get().selected_node !== undefined){
                    get().actions.setControlMode(ControlModes.ROTATE);
                } else {
                    get().actions.setControlMode(ControlModes.PAN);
                }
            }
        },
        setMoveCameraAfterDragMode (bool) {
            //console.log("setMoveCameraAfterDragMode: ", bool);
            set(state => {
                state.moveCameraAfterScroll = bool;
            });
        },
        getBounds () {
          let nodes = Object.values(get().node_positions);
          return ( get().actions.getBoundsForNodePositions(nodes) )
        },
        getBoundsForNodePositions(nodes) {
            let len = nodes.length;
            let i;
            let bounds = {xMin:nodes[0][0], zMin:nodes[0][2], xMax:nodes[0][0], zMax:nodes[0][2] }
            // console.log("bounds nodes: ", nodes, "len:", len);
            for (i = 0; i < len; i++) {
                // console.log("bounds nodes[i]: ", nodes[i] );
                if(nodes[i][0] < bounds.xMin) bounds.xMin = nodes[i][0];
                if(nodes[i][0] > bounds.xMax) bounds.xMax = nodes[i][0];
                if(nodes[i][2] < bounds.zMin) bounds.zMin = nodes[i][2];
                if(nodes[i][2] > bounds.zMax) bounds.zMax = nodes[i][2];
            }
            // console.log("bounds: ", bounds);
            return(bounds);
        },
        getBoundsCenter(bounds) {
            return({x:(bounds.xMin + bounds.xMax)/2, z:(bounds.zMin + bounds.zMax)/2});
        },
        clamp (n,lower,upper) {
            return Math.max(lower, Math.min(upper, n));
        },
        getNearestPointOnPerimeter (l,t,r,b,x,y) {
            // https://gist.github.com/dwtkns/d5b9b60285b8b0067c53

            x = get().actions.clamp(x,l,r);
            y = get().actions.clamp(y,t,b);

            let dl = Math.abs(x-l),
                dr = Math.abs(x-r),
                dt = Math.abs(y-t),
                db = Math.abs(y-b);

            let m = Math.min(dl,dr,dt,db);

            return  (m===dt) ? [x,t] :
                    (m===db) ? [x,b] :
                    (m===dl) ? [l,y] : [r,y];
        },
        isPointInBounds(b,p){
            let x = p[0];
            let z = p[2];
            if( x > b.xMin
                && x < b.xMax
                && z > b.zMin
                && z < b.zMax ) {
                return true;
            }
            return false;
        },
        addNode(nType, id, optionalMargin) {
            //console.log("AddNode: ", nType, id, optionalMargin);
            let isFirstNode = false;
            set(state => {
                state.first_node_added = ""; // clears first node toast (for now)
            })
            if (get().node_positions[id]) {
                // console.log(id + " is already in the map, move camera to center on it");
                get().actions.setSelectedNode(id);
                return;
            } else {

                // Place directors and companies in blank space when added from search
                // Find center of screen
                // Get the bounds of the objects on the map
                // If connected to another object on the map
                //   Find and place on closest point on bounds to that object
                // Else
                //   Find and place on closest point on bounds

                let centerPoint;

                if(get().camera === undefined){
                    centerPoint = [0,0,0];
                    // console.log("placement: camera is undefined");
                } else {
                    // console.log("placement: get().camera", get().camera);
                    // find center of visible map
                    let raycaster = new THREE.Raycaster();
                    let intersects = new THREE.Vector3();
                    raycaster.setFromCamera(new THREE.Vector2(), get().camera);
                    raycaster.ray.intersectPlane(get().floor_plane, intersects);
                    centerPoint = [Math.round(intersects.x), 0, Math.round(intersects.z)];
                }

                // console.log("centerPoint: ", centerPoint);
                let placementPoint = centerPoint;
                if(Object.keys(get().node_positions).length === 0){
                    // This is the first node being added
                    centerPoint = [0,0,0];
                    // console.log("this is the first node being added.");
                    placementPoint = centerPoint;

                    isFirstNode = true;

                } else {

                    let bounds = get().actions.getBounds();

                    // widen the bounds for placement not too close to other nodes
                    let margin = !!optionalMargin? optionalMargin: 35;
                    bounds = {
                        xMin: bounds.xMin - margin,
                        zMin: bounds.zMin - margin,
                        xMax: bounds.xMax + margin,
                        zMax: bounds.zMax + margin
                    }

                    // If this new node is connected to any node on the screen
                    // - find one of those nodes use it as the placementPoint ref.
                    let connectedNodes = [];
                    switch (nType) {
                        case Entities.DIR:
                            connectedNodes = get().dataset.Dirs[id].c;
                            break;
                        case Entities.ORG:
                            connectedNodes = get().dataset.Orgs[id].d;
                            break;
                        default:
                            break;
                    }
                    let i, connectedNodePos;
                    for (i = 0; i < connectedNodes.length; i++) {
                        connectedNodePos = get().node_positions[connectedNodes[i]];
                        if (connectedNodePos) {
                            placementPoint = connectedNodePos;
                            // console.log("Placement: Found a connected node", placementPoint)
                            break;
                        }
                    }

                    let inBounds = get().actions.isPointInBounds(bounds, placementPoint);
                    // console.log("inBounds: ", inBounds);

                    if (inBounds) {

                        let closestPoint = get().actions.getNearestPointOnPerimeter(
                            bounds.xMin,
                            bounds.zMin,
                            bounds.xMax,
                            bounds.zMax,
                            placementPoint[0],
                            placementPoint[2]
                        )

                        // console.log("closestPoint: ", closestPoint);

                        placementPoint = [Math.round(closestPoint[0]), 0, Math.round(closestPoint[1])];
                    } else {
                        // console.log("the center of the screen is outside of the bounds of the existing nodes - so just place it there");
                        // adjust the placement point to trigger the animation
                        placementPoint = [placementPoint[0] + 1, 0, placementPoint[2] + 1];
                    }
                }

                set(state => {
                    state.node_positions[id] = placementPoint;
                    state.node_target_positions[id] = {x:placementPoint[0], z:placementPoint[2], remove_on_rest:false}
                });
                set(state => {
                    state.node_selected_states[id] = false
                });

                switch (nType) {
                    case Entities.DIR:
                        let dir_type = get().actions.getDirectorType(id);
                        set(state => {
                            state.mutation[dir_type][id] = newNode(id, centerPoint[0], centerPoint[2],  nType, undefined);
                        })
                        break;
                    case Entities.ORG:
                        let table_size = get().actions.getTableSize(id);

                        set(state => {
                            state.mutation[table_size][id] = newNode(id, centerPoint[0] , centerPoint[2] , nType,  undefined);
                        })
                        break;
                    default:
                        break;
                }

                get().actions.addLinks(nType, id);
                get().actions.setCameraTarget(placementPoint);
                get().actions.setSelectedNode(null);

                if(isFirstNode){

                    get().actions.forceCameraToJumpToTarget();
                    if(!get().first_node_toast_seen && get().auto_play_state === AutoPlayStates.OFF) {
                        set(state => {
                            state.first_node_added = nType; // OPens the first node toast
                            state.first_node_toast_seen = true;
                        })
                    }
                }

            }
        },
        setZoom(amt){
            //console.log("#expand setZoom amt: ", amt);
            set(state => {
                state.camera_zoom_target = amt;
            })
        },
        nodeClicked(node, pos , isRightClick = false) {
            //console.log("MapStore nodeClicked", node.qid, node.nType, pos);
            // console.log("Object.keys(get().node_positions).length", Object.keys(get().node_positions).length, Object.keys(get().node_positions));

            // If this was the only node on the screen - zoom out
            if(Object.keys(get().node_positions).length === 1){
                // console.log("this was the only node on the screen zoom out")
                get().actions.setZoom(10);
            }

            if(get().auto_play_state === AutoPlayStates.OFF) {
                set(state => {
                    state.repeat_click_on_node = state.selected_node === node.qid;
                })
                get().actions.setSelectedNode(node.qid);
            }

            if(!isRightClick) {
                switch (node.nType) {
                    case Entities.DIR:
                        get().actions.directorClicked(node, pos);
                        break;
                    case Entities.ORG:
                        get().actions.orgClicked(node, pos);
                        break;
                    default:
                        break;
                }
            }

        },
        directorClicked(node, pos) {

            // console.log("expandDirector node n and node c:", Dirs[node.qid].n, Dirs[node.qid].c);
            // console.log("expandDirector pos:", pos);

            set(state => {
                state.first_node_added = ""; // clears first node toast
            })

            if(!get().node_positions[node.qid]) {
                // console.log("Caught a BUG: expandDirector get().node_positions[node.qid]", get().node_positions[node.qid]);
                // BUG: sometimes this dir doesn't exist!!!
                // To repro repeated click very quickly on a company -
                // the directors will come to the center then expand out
                // sometimes you will accidentally tap a director
                // but the will not have a node position - why not? Maybe, by the time the click is processed - they have been deleted
                return;
            }

            if(get().node_target_positions[node.qid] && get().node_target_positions[node.qid].remove_on_rest) {
                // this director is about to be removed
                // console.log("ignore this click on this director - they are about to be removed");
                return;
            }

            let new_orgs = {}; // an object of table sizes, and the new orgs within them
            let new_orgs_arr = []; // a flat list of org ids
            let on_screen_orgs = [];
            let count = 0
            let table_size;
            get().dataset.Dirs[node.qid].c.forEach((id) => {
                //console.log("comp id: ", id, get().dataset.Orgs[id].n);
                //console.log("get().node_positions["+id+"]: ", get().node_positions[id]);

                if (!get().node_positions[id]) {
                    // this comp is not on the screen yet
                    //console.log("this comp is not on the screen yet: ", id, get().dataset.Orgs[id].n);
                    table_size = get().actions.getTableSize(id);

                    if(!new_orgs[table_size]){
                        new_orgs[table_size] = {};
                    }

                    new_orgs[table_size][id] = newNode(id, pos[0], pos[2], Entities.ORG, undefined);
                    // console.log("Added comp new_orgs[" + table_size + "][" + id + "]: ", new_orgs[table_size][id]);

                    set(state => {
                        state.node_positions[id] = [0, 0, 0]
                        state.node_selected_states[id] = false
                    });

                    new_orgs_arr.push(id);

                } else {
                    on_screen_orgs.push({id:id, pos:get().node_positions[id]});
                }
                //  Keep Count  of the total number of orgs connected to this dir
                count++;

            });

            let adjust_angle = 0;
            // if the director is only connected to one org on the screen splay the remaining orgs
            //console.log("on_screen_orgs: ", on_screen_orgs);
            // out opposite it in a semi circle
            if (on_screen_orgs.length >= 1) {
                let first_org = on_screen_orgs[0];

                // BUG: it is possible to get here and have get().node_positions[node.qid] === undefined




                //console.log("get().node_positions[node.qid]", get().node_positions[node.qid], "first_org: ", first_org);

                let o_w = get().node_positions[node.qid][0] - first_org.pos[0];
                let o_h = get().node_positions[node.qid][2] - first_org.pos[2];

                adjust_angle = (Math.atan2(o_h, o_w));
            }

            let angle;

            let auto_mode = true;

            let base_dist, max_dist, dist;

            let i = -1;

            //console.log ("After clicking dir there is a count of: ", count );

            Object.entries(new_orgs).forEach(([comp_size, comp_obj]) => {  // This goes through each of the table sizes and looks at the new orgs for each
                Object.entries(comp_obj).forEach(([id]) => { // this goes through all the orgs in a table size grouping
                    i++;
                    base_dist = auto_mode ? 30 : 15;
                    max_dist = auto_mode ? 37 : 25;
                    dist = base_dist * count;
                    dist = clamp(dist, base_dist, max_dist);

                    let tarX, tarZ;

                    if (on_screen_orgs.length !== 0) {
                        angle = (count > 3)? 0.75 : 1.2;
                        // adjust_angle = 0;
                        let centerAdjust = (count%2===1)?.5:0; // If the number of new orgs is even don't use the center position in the splay
                        let offset = (((i%2)===0?1 :-1)*Math.ceil(i/2)) + centerAdjust;
                        //console.log(get().dataset.Orgs[id].n, "centerAdjust: " , centerAdjust, "count: ", count, " angle: ", angle, "adjust_angle: ", adjust_angle, "i", i,  "offset", offset,  "dist", dist);
                        tarX = Math.floor(dist * Math.cos(angle * offset + adjust_angle) + pos[0]);
                        tarZ = Math.floor((dist * Math.sin(angle * offset + adjust_angle)) + pos[2]);
                    } else {
                        let angle =   (360/count) * (Math.PI / 180); // multiply degrees by Pi over 180 to get radians
                        // console.log("angle, ", angle, " i", i, "Object.entries(new_orgs).length", Object.entries(new_orgs).length, "count", count, get().dataset.Coms[id].n);
                        tarX = Math.floor(dist * Math.cos(angle * i) + pos[0]);
                        tarZ = Math.floor((dist * Math.sin(angle * i)) + pos[2]);
                    }

                    set(state => {
                        state.node_target_positions[id] = {x:tarX, z:tarZ, remove_on_rest:false };
                    });

                    get().actions.addLinks(Entities.ORG, id);

                });

                set(state => {
                    // console.log("comp_obj", comp_obj);
                    state.mutation[comp_size] = {...state.mutation[comp_size], ...comp_obj}
                })
                //console.log("added this comp arr: ", comp_size, comp_obj);

            });

            get().actions.addLinks(Entities.DIR, node.qid);

            //console.log("#expand new_orgs_arr: ", new_orgs_arr);
            if(new_orgs_arr.length > 1 ){
                //console.log("#expand new_orgs_arr: ", new_orgs_arr.length);
                // find the center point of these orgs and have the camera aim there
                let node_positions_to_center_on = [];
                new_orgs_arr.forEach((id)=>{node_positions_to_center_on.push(
                    [get().node_target_positions[id].x, 0, get().node_target_positions[id].z]
                )})
                // also include the dir
                node_positions_to_center_on.push(get().node_positions[node.qid]);
                //console.log("#expand node_positions_to_center_on", node_positions_to_center_on);
                let bounds = get().actions.getBoundsForNodePositions(node_positions_to_center_on);
                //console.log("#expand bounds: ", bounds);
                let tar = get().actions.getBoundsCenter(bounds);
                get().actions.setCameraTarget([tar.x, 0, tar.z]);
                get().actions.setSelectedNode(null); // deselect the expanded director in this case

                // do we need to zoom out? to fit the bounds? Important for the mobile experience
                let requiredZoom = Math.min(
                     window.innerWidth / (bounds.xMax - bounds.xMin + 15),
                    window.innerHeight / (bounds.zMax - bounds.zMin + 15)
                );
                if(requiredZoom < get().camera_zoom_target) {
                    get().actions.setZoom(requiredZoom);
                }
            } else if (new_orgs_arr.length === 1) {
                // pan to it
                //get().actions.setSelectedNode(new_orgs_arr[0]);
                let tar = get().node_target_positions[new_orgs_arr[0]];
                get().actions.setCameraTarget([tar.x, 0, tar.z]);
                get().actions.setSelectedNode(null);
            } else {
                // there is nothing to expand on this director
                // there may be more boards that are already on screen
                // show a toast saying so
                // for mobile only bring this up if it is already selected
                //"*director name* is not on any other boards in this data set
                //console.log("#expand unconnected: ", node.qid);
                if(!isMobile || get().repeat_click_on_node)
                {
                    set(state => {
                        state.unconnected_director = get().dataset.Dirs[node.qid].n;
                    });
                }

            }

        },
        orgClicked(node, pos) {
            //console.log("companyClicked", node);

            set(state => {
                state.first_node_added = ""; // clears first node toast
            })

            // first check if all the board members are showing
            let isExpanded = true;
            let incoming_angle = 0; // the angle of the connection to an existing director
            let incoming_id = -1;
            for (let i = 0; i < get().dataset.Orgs[node.qid].d.length; i++) {
                let on_screen_dir = get().node_positions[get().dataset.Orgs[node.qid].d[i]];
                if (!on_screen_dir) {
                    isExpanded = false;
                } else {

                    // if a director is contracting then ignore this click altogether
                    if(get().node_target_positions[get().dataset.Orgs[node.qid].d[i]].remove_on_rest){
                        // this company is contracting don't do anything right now
                        // console.log("The company clicked on is contracting - ignore this click");
                        return;
                    }

                    //INCOMING ANGLES - this helps orient the rotational positionings of the directors.
                    // We record it so that when they re-expand thay will be placed at the same spot.

                    if(get().incoming_angles[node.qid]) {
                        //console.log("Already have incoming info:", get().incoming_angles[node.qid]);
                        incoming_angle = get().incoming_angles[node.qid].incoming_angle;
                        incoming_id = get().incoming_angles[node.qid].incoming_id;
                    } else {
                        // if the onscreen director is also connected to another company on the screen then
                        // record the incoming angle from that company
                        let dirs_coms = get().dataset.Dirs[get().dataset.Orgs[node.qid].d[i]].c;
                        for (let c = 0; c < dirs_coms.length; c++) {
                            if (dirs_coms[c] !== node.qid // is not the company we are trying to expand
                                && get().node_positions[dirs_coms[c]]  // and company is on screen
                            ) {
                                let d_w = get().node_positions[node.qid][0] - on_screen_dir[0];
                                let d_h = get().node_positions[node.qid][2] - on_screen_dir[2];
                                incoming_angle = (Math.atan2(d_h, d_w));
                                incoming_id = get().dataset.Orgs[node.qid].d[i];
                            }
                        }
                    }
                }
            }

            set(state => {
                state.incoming_angles[node.qid] = {
                    incoming_angle:incoming_angle,
                    incoming_id:incoming_id
                };
            });

            // console.log("incoming_angle", incoming_angle);

            // if not then expand the remaining ones
            let board = {};
            let numDirectors = get().dataset.Orgs[node.qid].d.length;

            if(!isExpanded) {

                // first order the directors by how many boards they are on
                let dirs = get().dataset.Orgs[node.qid].d.reduce(function(result, d) {
                    if (d !== incoming_id) {
                        result.push({id:d, num_boards:get().dataset.Dirs[d].c.length});
                    }
                    return result;
                }, []);

                let sorted_dirs = [];

                if(incoming_id !== -1) {
                    // put the directors with the most boards in the middle of the array
                    dirs.sort(function(a,b){
                        return a.num_boards - b.num_boards;
                    });
                    sorted_dirs.push(dirs.pop());
                    while (dirs.length) {
                        sorted_dirs[dirs.length % 2 === 0 ? 'push' : 'unshift'](dirs.pop());
                    }
                } else {
                    // don't sort the dirs on an unconnected company
                    sorted_dirs = dirs;
                }

                // console.log("sorted_dirs: ", sorted_dirs);

                let angle = (360 / numDirectors) * Math.PI / 180;

                sorted_dirs.forEach((dir, i) => {
                        let d = dir.id;
                        if (!get().node_positions[d]) {  // this means that we will have to remove them from node positions when deleted.

                            let dist = 1.4 * numDirectors; // set here because it needs to be reset each time

                            // console.log("DD, numDirectors: " , numDirectors);
                            // console.log("DD,(i % 2) === 0: " , (i % 2) === 0);

                            if (numDirectors > 55 ) {
                                if ((i % 4) === 0) {
                                    dist = .35 * numDirectors;
                                } else if ((i % 4) === 1) {
                                    dist = .5 * numDirectors;
                                } else if ((i % 4) === 2){
                                    dist = .65 * numDirectors;
                                } else {
                                    dist = .8 * numDirectors;
                                }
                            } else if (numDirectors > 45 ) {
                                if ((i % 4) === 0) {
                                    dist = .6 * numDirectors;
                                } else if ((i % 4) === 1) {
                                    dist = .8 * numDirectors;
                                } else if ((i % 4) === 2){
                                    dist = 1 * numDirectors;
                                } else {
                                    dist = 1.2 * numDirectors;
                                }
                            }
                            else if (numDirectors > 35 ) {
                                if ((i % 3) === 0) {
                                    dist = .6 * numDirectors;
                                } else if ((i % 3) === 1){
                                    dist = .8 * numDirectors;
                                } else {
                                    dist = 1 * numDirectors;
                                }
                            } else if (numDirectors > 25 ) {
                                if ((i % 2) === 0) {
                                    //console.log("DD, push this one out");
                                    dist = 1 * numDirectors;
                                } else {
                                    dist = 1.4 * numDirectors;
                                }
                            } else if (numDirectors < 10) {
                                dist += 2;
                            }
                            let tarX = Math.floor((dist * Math.cos((angle * (i + 1)) + incoming_angle - Math.PI)) + pos[0]);
                            let tarZ = Math.floor((dist * Math.sin((angle * (i + 1)) + incoming_angle - Math.PI)) + pos[2]);

                            let dirType = get().actions.getDirectorType(d);
                            if (!board[dirType]) {
                                board[dirType] = {};
                            }

                            board[dirType] = {
                                ...board[dirType],
                                [d]: newNode(d, pos[0], pos[2], Entities.DIR, node.qid)
                            }

                            set(state => {
                                state.node_positions[d] = [0, 0, 0];
                                state.node_target_positions[d] = {x:tarX, z:tarZ, remove_on_rest:false };
                                state.node_selected_states[d] = false
                            });

                            get().actions.addLinks(Entities.DIR, d);

                            // console.log("add ", Entities.DIR, d, pos[0], pos[2], tarX, tarZ);

                        }
                    }
                )


                // node.expanded = true; - this is modifying original - is that OK - given I am updating anyway?
                // return ({...state, mapNodes: {...state.mapNodes, ...board}, mapLinks: {...state.mapLinks, ...links}});
                Object.entries(board).forEach(([dir_type, dirs]) => {
                    set(state => {
                        state.mutation[dir_type] = {...state.mutation[dir_type], ...dirs}
                    })
                })

                get().actions.addLinks(Entities.ORG, node.qid);


            }
            else if (get().selected_node === node.qid)  // else if this is already selected, contract the ones that are not on any other boards
            {
                get().actions.contractUnconnectedDirectors(node);
            }
        },
        contractUnconnectedDirectors(node) {

            // console.log ("contract the directors who are only on this board");
            let numBoards = 0;
            let orgPos = get().node_positions[node.qid];
            let dirPos, tarPos, xDist, zDist, dist, fractionOfTotal;
            let tarDist = 3;
            // console.log("orgPos", orgPos);
            get().dataset.Orgs[node.qid].d.forEach((d) => {
                dirPos = get().node_positions[d];

                if (dirPos && get().node_target_positions[d] && !get().node_target_positions[d].remove_on_rest ) {
                    // this director is on the screen check if they are connected to another company
                    // in the data - not necessarily connected on the screen yet
                    numBoards = get().dataset.Dirs[d].c.length;
                    // console.log (Dirs[d].n + " numBoards: ", numBoards);
                    if(numBoards <= 1){
                        // remove this director
                        // set it to contract

                        xDist = dirPos[0] - orgPos[0];
                        zDist = dirPos[2] - orgPos[2];
                        dist = Math.sqrt(xDist * xDist + zDist * zDist);
                        fractionOfTotal = tarDist / dist;
                        tarPos = {
                            x: orgPos[0] + xDist * fractionOfTotal,
                            z: orgPos[2] + zDist * fractionOfTotal
                        }

                        set(state => {
                            state.node_target_positions[d] = {x:tarPos.x, z:tarPos.z, remove_on_rest:true };
                        })
                    }
                }
            });


        },
        addLinks(type, id) {
            // Set Links
            // Source - always the company or org
            // Target always a person
            let links = {};
            let link = {};
            let name = "";
            switch (type) {
                case Entities.DIR:

                    get().dataset.Dirs[id].c.forEach((c) => {
                            name = c + "_" + id
                            if (get().node_positions[c] && !get().mutation.links[name]) {
                                link = {id: c + id, source: c, target: id}
                                links = {...links, [name]: link}
                            }
                        }
                    )

                    break;
                default:
                case Entities.ORG:
                    get().dataset.Orgs[id].d.forEach((d) => {
                            name = id + "_" + d
                            if (get().node_positions[d] && !get().mutation.links[name]) {
                                link = {id: id + d, source: id, target: d}
                                links = {...links, [name]: link}
                            }
                        }
                    )

                    break;
            }

            if((Object.keys(links).length > 0)) {
                set(state => {
                    state.mutation.links = {...state.mutation.links, ...links}
                })
            }
        },
        updateNodePosition(node, pos) {
            if(!get().node_positions[node.id]) {
                // console.log("%c Trying to update a deleted node: ", "color:red", node.id);
                return;
            }
            set(state => {
                state.node_positions[node.id] = pos;
            });
        },
        updateNodeTargetPosition(node, pos) {
            set(state => {
                state.node_target_positions[node.id] = {x:pos[0], z:pos[2], remove_on_rest:false}
            });
        },
        onRestRemoveIfRequired (id, node_type, instance_key){
            if (get().node_target_positions[id] && get().node_target_positions[id].remove_on_rest ){
                get().actions.deleteNodeFromMap(id, node_type, instance_key);
            }
        },
        getNodeType(id) {
            if(!!get().dataset.Orgs[id]) {
                return Entities.ORG
            }  else {
                return Entities.DIR
            }
        },
        deleteSelectedNode () {
          get().actions.deleteNode(get().selected_node);
          get().actions.setSelectedNode(null);
        },
        deleteNode (id) {
            //console.log("delete node: ", id);
            if(id === undefined || id === null){
                return;
            }
            let nodeType = get().actions.getNodeType(id);
            let instanceKey = nodeType === Entities.DIR? get().actions.getDirectorType(id) : get().actions.getTableSize(id);
            get().actions.deleteNodeFromMap(id, nodeType, instanceKey);
        },
        deleteNodeFromMap (id, node_type, instance_key){
            // console.log("deleteNodeFromMap: ", id, node_type, instance_key);

            // remove any connecting lines
            if(node_type === Entities.DIR) {
                get().dataset.Dirs[id].c.forEach((c) => {
                        set(state => {
                            delete(state.mutation.links[c + "_" + id])
                        })
                    }
                )
            } else if (node_type === Entities.ORG) {
                get().dataset.Orgs[id].d.forEach((d) => {
                        set(state => {
                            delete(state.mutation.links[id + "_" + d]);
                        })
                    }
                )
            }

            // remove this item
            set(state => {
                delete (state.node_positions[id] );
                delete (state.mutation[instance_key][id] );
                delete (state.node_target_positions[id] );
            });


            // if it was selected - deselect
            if(get().selected_node === id) {
                get().actions.setSelectedNode(null);
            }
            //console.log("deletedNodeFromMap: ", id, "get().node_positions[id]: ", get().node_positions[id]);
        },
        /*
        After dragging a director don't have it automatically be dragged by the company it is attached to
         */
        clearDragParent(node, instance_key){
            //console.log("clearDragParent", node, instance_key);
            if(get().mutation[instance_key][node.qid]) {
                set(state => {
                    state.mutation[instance_key][node.qid].dragParent = undefined;
                })
            }
        },
        startAutoPlay(){
            //console.log("Mapstore startAutoPlay");
            // assuming the map is empty
            let mapIsEmpty = Object.keys(get().node_positions).length === 0;
            if (mapIsEmpty) {
                set(state => {
                    state.auto_play_history = [];
                    state.auto_play_state = AutoPlayStates.PLAYING;
                });
            }
        },
        clearAutoPlayHistory(){
            //console.log("Mapstore clear AutoPlay");
            set(state => {
                state.auto_play_history = [];
            });
        },
        addToAutoPlayHistory(items){
            set(state => {
                state.auto_play_history = [...state.auto_play_history, ...items];
            })
        },
        pauseAutoPlay() {
            //console.log("PauseAutoPlay");
            set(state => {
                state.auto_play_state = AutoPlayStates.PAUSED;
            });
        },
        resumeAutoPlay() {
            set(state => {
                state.auto_play_state = AutoPlayStates.PLAYING;
            });
        },
        stopAutoPlay() {
            set(state => {
                state.auto_play_state = AutoPlayStates.OFF;
            });
        },
        getTableSize(id) {
            let table_size = "comps_s";
            let revThird = (get().datasetInfo.max_rev - get().datasetInfo.min_rev)/3;
            if (get().dataset.Orgs[id].r > (get().datasetInfo.max_rev - revThird)) {
                table_size = "comps_l";
            } else if (get().dataset.Orgs[id].r > (get().datasetInfo.min_rev + revThird)) {
                table_size = "comps_m";
            }
            // console.log("Add company with rev: ", get().dataset.Coms[id].r, table_size) //386064
            return table_size;
        },
        getDirectorType(id) {
            // console.log("getDirectorType", id, Dirs[id]);
            let g = get().dataset.Dirs[id].g.toLowerCase();
            let numBoards = get().dataset.Dirs[id].c.length;
            let size;
            if (numBoards === 1) {
                size = "s";
            } else if (numBoards > 3) {
                size = "xl";
            } else if (numBoards > 2) {
                size = "l";
            } else {
                size = "m";
            }
            return ("dirs_" + g + size);
        },
        getDefaultDataSetInfo () {
            //console.trace("Default data set: ", DataSets.find(({status}) => status === DataSetStatus.DEFAULT));
            return DataSets.find(({status}) => status === DataSetStatus.DEFAULT);
        },
        setCurrentDataSet (dataSet) {

            set(state => {
                state.data_loaded = false;
            });

            // us_companies_revenue_2020_12_31_1
            let srcString = [dataSet.region, dataSet.org_type, dataSet.criteria, dataSet.year, dataSet.month, dataSet.day, dataSet.version].join("_");
            let srcPath = [dataSet.region, dataSet.org_type, dataSet.criteria, dataSet.year, dataSet.month, dataSet.day, dataSet.version].join("/");
            // console.log("srcString string", srcString);
            // console.log("srcPath: ", srcPath);

            import("./" + srcPath).then(src => {
                let dataSrc = src[srcString];
                //console.log("dataSrc", dataSrc);
                set(state => {
                    state.dataset.Orgs = dataSrc.Orgs;
                    state.dataset.TopComs = dataSrc.TopComs;
                    state.dataset.Dirs = dataSrc.Dirs;
                    state.dataset.TopDirs = dataSrc.TopDirs;
                    if(dataSrc.TopConnectedOrgs){
                        state.dataset.TopConnectedOrgs = dataSrc.TopConnectedOrgs;
                    }
                    if(dataSrc.OrgsWomenMost){
                        state.dataset.OrgsWomenMost = dataSrc.OrgsWomenMost;
                    }
                    if(dataSrc.OrgsWomenLeast){
                        state.dataset.OrgsWomenLeast = dataSrc.OrgsWomenLeast;
                    }
                    state.dataset.Admins = [];
                    state.dataset.AddOns = {}; // used for search suggestions
                    state.dataAddOns = { // used for menu toggles and to keep track of whether they are loaded
                        presidential_admins: {
                            include: false,
                            loaded: false,
                        },
                    };

                    dataSet.add_ons.forEach ( (ds) => {
                            state.dataAddOns[ds.id] = {
                                include:false,
                                loaded: false
                            }
                        }
                    )

                    state.datasetInfo = dataSet;
                    //console.log("JJ set data_loaded true");
                    state.data_loaded = true;
                });
            });

        },
        addWikidataSetProcessData(src, wdsInfo) {
            let dataObject = src[wdsInfo.srcDataObject];
            //console.log("Add these orgs and members to the current data set:", dataObject);
            let tempDirs =  {...get().dataset.Dirs}; // shallow copy
            Object.entries(dataObject.Dirs).forEach( (e) => {
                if(tempDirs[e[0]]) {
                    let tempDir =  {...tempDirs[e[0]]}; // shallow copy
                    tempDir.c =  [...new Set([...tempDir.c,...e[1].c])];
                    tempDirs[e[0]] = tempDir;
                } else {
                    tempDirs[e[0]] = e[1];
                }
            })

            set(state => {
                state.dataset.Orgs = Object.assign({}, state.dataset.Orgs, dataObject.Orgs);
                state.dataset.Dirs = tempDirs;
            })
        },
        removeWikidataSetProcessData(src, wdsInfo) {
            let dataObject = src[wdsInfo.srcDataObject];
            //console.log("Remove these orgs and members from the current data set:", dataObject);
            let tempDirs =  {...get().dataset.Dirs}; // shallow copy
            Object.entries(dataObject.Dirs).forEach( (e) => {
                let tempDir =  {...tempDirs[e[0]]}; // shallow copy
                if (tempDir.c.length > e[1].c.length) {
                    // this director has other connections beyond the data set we are removing - just delete the orgs from this data set
                    tempDir.c = tempDir.c.filter(function (el) {
                        return !e[1].c.includes(el);
                    });
                    tempDirs[e[0]] = tempDir;
                } else {
                 // this dir doesn't have any connections beyond this dataset - just remove them
                 delete tempDirs[e[0]];
                }
            })
            let tempOrgs =  {...get().dataset.Orgs}; // shallow copy
            Object.keys(dataObject.Orgs).forEach( (key) => {
                if(tempOrgs[key]){
                    delete tempOrgs[key];
                }
            })
            set(state => {
                state.dataset.Orgs = tempOrgs;
                state.dataset.Dirs = tempDirs;
            })
        },
        includeWikidataSet(dataSetType, include, addOnId){
          // used for adding a data set such as an administration, charities or think tank
            //console.log("addWikidataSet", dataSetType, include, addOnId);
            let wdsInfo = {};
            switch (dataSetType) {

                case DataSetType.ADD_ON:
                    wdsInfo.srcDataObject = addOnId;
                    set(state => { state.dataAddOns[addOnId].loaded = false; });
                    // addOnId example: us_thinktanks_revenue_2020_12_31_1
                    let srcPath = addOnId.split("_").join("/") + "/index";
                    import(/* webpackMode: "eager" */ "./" + srcPath).then(src => {
                        if(include) {
                            get().actions.addWikidataSetProcessData(src, wdsInfo);
                        } else {
                            get().actions.removeWikidataSetProcessData(src, wdsInfo);
                        }
                        set(state => {
                            state.dataAddOns[addOnId].loaded = true;
                            if(include){
                                state.dataAddOns[addOnId].include = true; // so that this shows in saved maps
                                state.dataset.AddOns[addOnId] = src[wdsInfo.srcDataObject].TopOrgs;
                            } else {
                                delete state.dataset.AddOns[addOnId];
                            }

                        });
                    });
                    break;

                case DataSetType.US_ADMINS:
                default:
                    wdsInfo.srcDataObject = "administrations";
                    set(state => { state.dataAddOns.presidential_admins.loaded = false; });
                    import("./us/administrations/wikidata/index").then(src => {
                        if(include) {
                            get().actions.addWikidataSetProcessData(src, wdsInfo);
                        } else {
                            get().actions.removeWikidataSetProcessData(src, wdsInfo);
                        }
                        set(state => {
                            state.dataAddOns.presidential_admins.loaded = true;
                            state.dataset.Admins = include? src[wdsInfo.srcDataObject].Admins : [];
                            if(include){
                                state.dataAddOns.presidential_admins.include = true; // so that this shows in saved maps
                            }
                        });
                    });
                    break;
            }
        },
        includePresidentialAdmins(include){
            //console.log("includePresidentialAdmins", include);
            set(state => {
                state.dataAddOns.presidential_admins.loaded = false;
                state.dataset.Admins = [];
            })
            get().actions.includeWikidataSet(DataSetType.US_ADMINS, include);
            set(state => {
                state.dataAddOns.presidential_admins.include = include;
            })
        },
        includeAddOnData(include, addOnId) {
            //console.log("includeAddOnData", include, addOnId );
            set(state => {
                state.dataAddOns[addOnId].loaded = false;
                delete state.dataset.AddOns[addOnId];
            })
            get().actions.includeWikidataSet(DataSetType.ADD_ON, include, addOnId);
            set(state => {
                state.dataAddOns[addOnId].include = include;
            })
        },
        storeLoadedSavedMapDataSet (url, data){
            set(state => {
                state.loaded_saved_map_id = url;
                state.loaded_saved_map_data_set_info[url] = data;
            })
        }
    }
});

const useMapStore = create (
        subscribeWithSelector (
            immer(mapStore),
        )
)

const newNode = (id, x, z, type, drag_parent) => {
    return {
        id: id,
        qid: id,
        startX: x,
        startZ: z,
        nType: type,
        dragParent: drag_parent
    }
}

export default useMapStore;